import { Dispatch, ReactElement, SetStateAction } from 'react';

type ModalContainerProps = {
    children: ReactElement;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function ModalContainer({ children, isOpen, setIsOpen }: ModalContainerProps) {
    return (
        <>
            {isOpen && (
                <div className="modal-container">
                    {children}
                    <div className="modal-black-overlay" onClick={() => setIsOpen(false)} />
                </div>
            )}
        </>
    );
}
