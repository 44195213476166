import React, { ChangeEvent, useState } from "react";

interface IDiagnosticModalComponent {
  onSend: (description: string) => void
  closeModal: any
  setAlert: any
  companyAgrees: boolean
}

const DiagnosticModalComponent: React.FC<IDiagnosticModalComponent> = ({ onSend, closeModal, setAlert, companyAgrees }) => {
  const [description, setDescription] = useState("");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }

  return (
    <>
      <div className="diagnostic-modal-container">
        <div className="diagnostic-modal">
          <div className="avaliation-modal-close-container">
            <span className="close-button" onClick={() => closeModal()}>
              <img src={`/assets/icons/close-modal.png`} alt="close-modal" />
            </span>
          </div>
          <div className="diagnostic-modal-header">
            <span>{companyAgrees ? 'Gostaria de deixar alguma sugestão?' : "Comente sobre seu desacordo com o diagnóstico apresentado"}</span>
          </div>
          <div className="diagnostic-modal-body">
            <textarea defaultValue={description} onChange={(e) => handleChange(e)} maxLength={750}></textarea>
          </div>
          <div className="diagnostic-modal-footer">
            <button type="button" onClick={() => onSend(description)}>Enviar</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiagnosticModalComponent;