import React, { ReactNode } from "react";

interface ICollapsibleMenuItemProps {
    name: string;
    action: () => void;
    visible: boolean;
    children?: ReactNode;
}

const CollapsibleMenuItemComponent = ({action, name, visible, children}: ICollapsibleMenuItemProps) => {
    return (
        <>
            <div className={visible ? "collapsible-item-container" : "collapsible-menu-item-none"} onClick={ action }>
                <span>{name}</span>
                {children}
            </div>
        </>
    )
}

export default CollapsibleMenuItemComponent;