import evaluationService from "../../../services/evaluation";
import React, { Dispatch, MutableRefObject, RefObject, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import techProductsService from "../../../services/techProducts";
import AlertMessage from "../../utils/Alert";

interface ICreateEvaluationModal {
    close: () => void
    reload: () => void
    action: string
    type: string
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>
    }
    name?: string
    id?: string
    parentId?: string
    evaluationTechProducts?: ITechProduct[]
    journeyStep?: string
    currentPlatform?: string
}

interface ITechProduct {
    id: string
    name: string
    journeyStep: string
}

const CreateEvaluationModal: React.FC<ICreateEvaluationModal> = ({ close, journeyStep, reload, action, type, alert, name, id, evaluationTechProducts, parentId, currentPlatform }) => {


    const [inputValue, setInputValue] = useState({
        name: name || "",
        tech_products: evaluationTechProducts || [{ id: "" }],
    })
    const [techProducts, setTechProducts] = useState([])

    const typeTranslate = type === "EVALUATION" ? "Avaliação" : type === "HUB" ? "Eixo" : type === "SESSION" ? "Seção" : "Critério"

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue({
            ...inputValue,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeJourneyStep = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (inputValue.tech_products.length === 0 || inputValue.tech_products[0].id == "") {
            setInputValue({
                ...inputValue,
                "tech_products": [
                    { id: event.target.value }
                ]
            })
        } else {
            if (inputValue.tech_products.find((techProduct) => techProduct.id === event.target.value) === undefined && event.target.value !== "")
                setInputValue({
                    ...inputValue,
                    "tech_products": [
                        ...inputValue.tech_products,
                        { id: event.target.value }
                    ]

                })
        }

    }

    const register = () => {
        if (inputValue.name === "" || (inputValue.tech_products.length < 1 && type === "EVALUATION")) {
            alert.setMessage("Preencha todos os campos necessários para realizar o cadastro!")
            alert.setShow(true)
            setTimeout(() => {
                alert.setShow(false)
            }, 3175)
        } else {
            switch (type) {
                case "EVALUATION":
                    if (action == "EDIT")
                        editEvaluation();
                    else
                        addEvaluation()
                    break;
                case "HUB":
                    addHub()
                    break;
                case "SESSION":
                    addHub()
                    break;
                case "CRITERIA":
                    addCriteria()
                    break;
            }
        }

    }

    const edit = () => {
        if (inputValue.name === "") {
            alert.setMessage("Preencha todos os campos necessários para editar a avaliação!")
            alert.setShow(true)
            setTimeout(() => {
                alert.setShow(false)
            }, 3175)
        } else {
            switch (type) {
                case "EVALUATION":
                    editEvaluation();
                    break;
                case "HUB":
                    editHub()
                    break;
                case "SESSION":
                    editHub()
                    break;
                case "CRITERIA":
                    editCriterion()
                    break;
            }
        }
    }


    const addEvaluation = () => {
        console.log('params', journeyStep)
        const evaluation = {
            name: inputValue.name,
            journey_step: journeyStep,
            tech_products: inputValue.tech_products[0].id === "" ? [] : inputValue.tech_products,
            platform: currentPlatform
        }
        evaluationService.createEvaluation(evaluation).then((res) => {
            try {
                if (res.statusCode === 200) {
                    alert.setMessage("Avaliação cadastrada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                } else {
                    alert.setMessage("Ocorreu um erro ao cadastrar a avaliação!")
                    alert.setShow(true)
                    setTimeout(() => {
                        alert.setShow(false)
                    }, 3175)
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao cadastrar a avaliação!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }

        })
    }

    const editEvaluation = () => {
        const evaluation = {
            name: inputValue.name,
            journey_step: journeyStep,
            tech_products: inputValue.tech_products.length === 0 || inputValue.tech_products[0].id === "" ? [] : inputValue.tech_products
        }

        evaluationService.editEvaluation(id, evaluation).then((res) => {
            try {
                if (res.statusCode === 200) {
                    alert.setMessage("Avaliação editada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao editar a avaliação!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }

    const addHub = () => {
        const hub = {
            name: inputValue.name,
            evaluation: {
                id: id
            }
        }
        evaluationService.createHub(hub).then((res) => {
            try {
                if (res.statusCode === 200) {
                    alert.setMessage(` ${type == "HUB" ? "Eixo cadastrado" : "Seção cadastrada"}  com sucesso!`)
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                }
            } catch (error) {
                alert.setMessage(`Ocorreu um erro ao cadastrar ${type == "HUB" ? "o eixo" : "a seção"}`)
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }

    const editHub = () => {
        const hub = {
            name: inputValue.name,
        }

        evaluationService.editHub(id, hub).then((res: any) => {
            try {
                if (res.data.statusCode === 200) {
                    alert.setMessage(`${type == "HUB" ? "Eixo editado" : "Seção editada"}  com sucesso!`)
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                }
            } catch (error) {
                alert.setMessage(`Ocorreu um erro ao editar o ${type == "HUB" ? "o eixo" : "a seção"}`)
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }

    const addCriteria = () => {

        const criteria = {
            name: inputValue.name,
            hub: {
                id: id
            }
        }
        evaluationService.createCriterion(criteria).then((res) => {
            try {
                if (res.statusCode === 200) {
                    alert.setMessage("Critério cadastrado com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao cadastrar o critério!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }

    const editCriterion = () => {
        const criterion = {
            name: inputValue.name,
        }

        evaluationService.editCriterion(id, criterion).then((res: any) => {
            try {
                if (res.data.statusCode === 200) {
                    alert.setMessage("Critério editado com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao editar o critério!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }


    useEffect(() => {
        techProductsService.list(journeyStep).then((res) => {
            setTechProducts(res.sort((a: any, b: any) => a.name > b.name ? 1 : -1))
        })
    }, [])

    return (
        <>
            <div className="create-evaluation-modal-container" >
                <div className="create-evaluation-modal" >
                    <div className="create-evaluation-header">
                        <h2>{action === "REGISTER" ? "Cadastro" : "Edição"} de {typeTranslate}</h2>
                        <span className="close-button" onClick={close}>
                            <img src={`/assets/icons/close-modal.png`} alt="close-modal" />
                        </span>
                    </div>
                    <div className="create-evaluation-body">
                        <div className="create-evaluation-input">
                            <p>Nome: </p>
                            <input name="name" onChange={(event) => handleChange(event)} value={inputValue.name} type="text" />
                        </div>
                        {
                            type === "EVALUATION" ? (
                                <>
                                    <div className="create-evaluation-input">
                                        <p>Produtos Tecnológicos: </p>
                                        <select onChange={(event) => handleChangeJourneyStep(event)}>
                                            <option value="">Selecione o(s) produto(s) tecnológico(s)</option>
                                            {
                                                techProducts &&
                                                techProducts.map((techProduct: ITechProduct) => {
                                                    return <option value={techProduct.id} >{techProduct.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="create-evaluation-tech-products-selected-container">
                                        {

                                            inputValue.tech_products?.map((techProduct) => {
                                                let techProductToRender: any = techProducts.find((techProductFind: ITechProduct) => techProductFind.id == techProduct.id)
                                                if (techProduct?.id.length !== 0) {
                                                    return (
                                                        <div className="create-evaluation-tech-products-selected-card">
                                                            <p>{techProductToRender?.name}</p>
                                                            <div onClick={() => {
                                                                setInputValue({
                                                                    ...inputValue,
                                                                    tech_products: inputValue.tech_products.filter((techProductFilter: any) => techProductFilter.id !== techProduct.id)
                                                                })
                                                            }}>x</div>
                                                        </div>
                                                    )
                                                }

                                            })
                                        }
                                    </div>
                                </>
                            ) : null
                        }



                    </div>
                    <div className="create-evaluation-footer">
                        <button disabled={inputValue.name ? false : true} className="button-base-style" onClick={() => action === "REGISTER" ? register() : edit()}>{action === "REGISTER" ? "Salvar" : "Editar"}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateEvaluationModal