import { Spinner, Squares } from "react-activity"
import "react-activity/dist/library.css";
import DataLine from "./DataLine";

import { Company, SortType, SortIcons } from "..";

export interface DataTableVisualizationProps {
    totalCompanies: number,
    selectedSortType: SortType,
    handleOrderSelector: Function,
    loading: Boolean,
    setLoading: Function,
    companies: Array<Company> | undefined
}

const DataTableVisualization = ({ selectedSortType,
    handleOrderSelector, setLoading, loading, companies, totalCompanies }: DataTableVisualizationProps) => {

    return (
        <div className='data-view-table-container'>
            <div className="data-view-table-container" >
                <table style={{marginBottom : "8px"}}>
                    <tr>
                        <th onClick={() => handleOrderSelector("NAME")}>
                            <div className="table-header-content">
                                Nome
                                <div className="order-selector-button" >
                                    <img src={SortIcons[selectedSortType.NAME].icon} alt="sort icon" />
                                </div>
                            </div>
                        </th>
                        <th onClick={() => handleOrderSelector('CNPJ')}>
                            <div className="table-header-content">
                                CNPJ
                                <div className="order-selector-button" >
                                    <img src={SortIcons[selectedSortType.CNPJ].icon} alt="sort icon" />
                                </div>
                            </div>
                        </th>
                        <th onClick={() => handleOrderSelector('CITY')}>
                            <div className="table-header-content">
                                Cidade
                                <div className="order-selector-button" >
                                    <img src={SortIcons[selectedSortType.CITY].icon} alt="sort icon" />
                                </div>
                            </div>
                        </th>
                        <th onClick={() => handleOrderSelector('UNITY')}>
                            <div className="table-header-content">
                                Unidade
                                <div className="order-selector-button">
                                    <img src={SortIcons[selectedSortType.UNITY].icon} alt="sort icon" />
                                </div>
                            </div>
                        </th>
                        <th >
                            <div className="table-header-content">
                                Consultor
                            </div>
                        </th>
                        <th onClick={() => handleOrderSelector('PROPOSAL')}>
                            <div className="table-header-content">
                                Número da Proposta
                                <div className="order-selector-button" >
                                    <img src={SortIcons[selectedSortType.PROPOSAL].icon} alt="sort icon" />
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="table-header-content">
                                Produto Tecnológico
                            </div>
                        </th>
                        <th>
                            <div className="table-header-content">
                                Status
                            </div>
                        </th>

                    </tr>
                    <tbody>
                        {loading ?
                            <Squares /> :
                            companies?.map((company: Company, index: number) => (
                                <DataLine setLoading={setLoading} company={company} index={index} />
                            ))}
                    </tbody>
                </table>
                <footer>
                    <span>
                        {loading ? <Squares />  : `No total existem ${totalCompanies} propostas com produtos tecnológicos`}
                    </span>
                </footer>

            </div>
        </div>
    )
}

export default DataTableVisualization;