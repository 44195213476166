type SemaphoreProps = {
  efficiency: any;
};

export default function Semaphore({ efficiency }: SemaphoreProps) {
  const efficiencyGrade = Object.keys(efficiency).map((key) => {
    return efficiency[key];
  });

  const efficiencyGradeTotal = efficiencyGrade.reduce((a, b) => a + b, 0);

  const color =
    efficiencyGradeTotal >= 0
      ? "green"
      : efficiencyGradeTotal >= -10
      ? "yellow"
      : "red";

  return (
    <div className="efficiency-level">
      <div className="content">
        <div className="traffic-light-container">
          <img
            className="image-semaphore"
            src={`/assets/images/semaphore-${color}.svg`}
            alt="semaphore"
          />
          <h2 className='grade'>
            Nota: {efficiencyGradeTotal}
          </h2>
        </div>
      </div>
    </div>
  );
}
