import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { getAllEfficiency } from '../../services/GradeService';
import { ExportToCsv } from 'export-to-csv';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const inicialColumns: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'diagnostic_id',
        header: 'DiagnosticId',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false,

    },
    {
        accessorKey: 'company_id',
        header: 'CompanyId',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'consultant_id',
        header: 'ConsultantId',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false,

    },
    {
        accessorKey: 'company_name',
        header: 'Nome da Empresa',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false,

    },
    {
        accessorKey: 'cnpj',
        header: 'CNPJ da Empresa',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false
    },
    {
        accessorKey: 'proposal_number',
        header: 'Número da Proposta',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: false
    },
    {
        accessorKey: 'unity',
        header: 'Unidade',
        filterVariant: 'text', // default
        size: 10,
        enableHiding: true,
    },
    {
        accessorKey: 'consultant',
        header: 'Consultor',
        filterVariant: 'text', // default
        size: 10,
        enableHiding: true,
    },
    {
        accessorKey: 'fornos',
        header: 'Fornos',
        filterVariant: 'text', // default
        size: 170,
        enableHiding: true,
    },
    {
        accessorKey: 'motores',
        header: 'Motores',
        filterVariant: 'text', // default
        size: 100,
        enableHiding: true,
    },
    {
        accessorKey: 'iluminacao',
        header: 'Iluminação',
        filterVariant: 'text', // default
        size: 220,
        enableHiding: true,
    },
    {
        accessorKey: 'ar_condicionado',
        header: 'Ar-condicionado',
        filterVariant: 'text', // default
        size: 210,
        enableHiding: true,
    },
    {
        accessorKey: 'cargas_de_utilidades',
        header: 'Cargas de utilidades',
        filterVariant: 'text', // default
        size: 250,
        enableHiding: true,
    },
    {
        accessorKey: 'energia_e_instalacao',
        header: 'Energia e instalação',
        filterVariant: 'text', // default
        size: 250,
        enableHiding: true,
    },
    {
        accessorKey: 'soma_eficiencia',
        header: 'Nota Eficiência',
        filterVariant: 'text', // default
        size: 120,
        enableHiding: true,
    }

];

const csvOptions = {
    title: 'efficiency_results',
    fieldSeparator: ';',
    quoteStrings: '',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: inicialColumns?.map((c: any) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const EfficiencyDiagnosticsGradePage = () => {
    const [efficiency, setEfficiency] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleExportData = () => {
        csvExporter.generateCsv(efficiency);
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => inicialColumns, [],
    );


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllEfficiency();
                setEfficiency(res.data);
                setIsLoading(false)

            } catch (error) {
                console.error('Erro ao obter os dados:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="main-container">
                <div className="company-container" style={{ maxHeight: "79vh", marginTop: '11vh' }}>
                    <div style={{ overflowX: "auto", overflowY: "auto" }}>
                        <MaterialReactTable
                            filterFromLeafRows={true}
                            columns={columns}
                            data={efficiency}
                            enableStickyHeader
                            enableDensityToggle={false}
                            state={{ isLoading: isLoading }}
                            initialState={{
                                showColumnFilters: true,
                                columnVisibility: { unity: false, consultant: false, diagnostic_id: false, company_id: false, consultant_id: false },
                                density: "compact",
                                pagination: { pageSize: 15, pageIndex: 0 }
                            }}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [15, 30, 50, 100, efficiency?.length],
                                labelRowsPerPage: "Linhas por página",
                                showFirstButton: false,
                                showLastButton: false,
                            }}
                            positionToolbarAlertBanner="bottom"
                            renderTopToolbarCustomActions={() => (
                                <Box
                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={handleExportData}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        CSV
                                    </Button>
                                </Box>
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EfficiencyDiagnosticsGradePage;
