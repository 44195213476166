import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ModalContact from './Modals/Contacts/ModalContact';
import DigitalTechnologiesChart from './Charts/DigitalTechnologiesChart';
import RadarChartComponent from '../utils/RadarChart';
import TrafficLight from './TrafficLight/TrafficLight';
import { applyCnpjMask } from '../../utils/masks'; 
import { useAppSelector } from "../../store";
import { storeDiagnostic } from '../../store/slices/diagnostic'; 
import { getAllTechDiagnostics, getDiagnosticsByProposalId } from '../../utils/utils';

type Contact = {
    id: string;
    name: string;
    email: string;
    phone: string;
}

const DiagnosticComponent = ({proposalId}:{proposalId:string}) => {
    const [efficiencyTotal, setEfficiencyTotal] = useState<number|null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [alertShow, setAlertShow] = useState<boolean>(false);
    const dispatch = useDispatch();  
    
    // const { user } = useAppSelector(state => state.user);
    const { company } = useAppSelector(state => state.company);
    const companyContacts: Contact[] = company.companyContext?.contacts;

    const allDiagnostics = getAllTechDiagnostics(company.companyContext);
    const diagnostic = getDiagnosticsByProposalId(allDiagnostics,proposalId );

    let filteredDiagnosticResult:any = null
    let filteredGrade:any = null
    try {
        filteredDiagnosticResult = diagnostic?.grade?.find((items:any)=> typeof items?.diagnosticResult?.totalAxisMedia[0]?.['Planejamento Logístico e de Produção'] == typeof 0) ;
    
        filteredGrade = diagnostic?.grade?.find((items:any)=>(
             typeof items?.efficiency_grade?.MOTORES == 'number' &&
             (items?.efficiency_grade?.MOTORES !== 0 || items?.efficiency_grade?.["AR-CONDICIONADO"] !== 0 ||
                items?.efficiency_grade?.["CARGAS DE UTILIDADES"] !== 0 || items?.efficiency_grade?.["ENERGIA E INSTALAÇÃO"] !== 0 || items?.efficiency_grade?.FORNOS !== 0  || 
                items?.efficiency_grade?.ILUMINAÇÃO !== 0)
             ));
    } catch (error) {
        filteredDiagnosticResult = null
        filteredGrade = null
    }


    const productiveProcessEvaluation = Number(filteredDiagnosticResult?.diagnosticResult?.axisOneMedia?.toFixed(2)) || null;
    const digitalizationEvaluation = Number(filteredDiagnosticResult?.diagnosticResult?.axisTwoMedia?.toFixed(2)) || null;



    const defaultResults={
        noValues:0,
        value2:0,
        value3:0,
        value4:0,
        value5:0,
    }
    const productiveProcessAndSupplyChainColumns = [
        'Indiferente',
        'Entendimento',
        'Em Processo de Otimização',
        'Processo Preparado para Transformação Digital'
    ]

    const digitalizationAndIndustrialConnectivity = [
        'Empresa Analógica',
        'Empresa Emergente',
        'Empresa Intermediária',
        'Empresa Digital'
    ]
    const [currentContact, setCurrentContact] = useState<any>({
        contact: {
            id: "",
            name: "",
            email: "",
            phone: ""
        }
    });
    const [grade, setGrade] = useState<any>(null)

    const showAlert = () => {
        setAlertShow(true)
        setTimeout(() => {
            setAlertShow(false)
        }, 3175)
    }

    const updatedAt = ()=>{
        const [year,month,day] =(diagnostic?.updatedAt)?.substr(0,10)?.split('-')
        if(year) return day + "/" + month + "/" + year

        return null
    }
    const getContactPosition = (contact: Contact) => companyContacts.findIndex((item) => item?.id === contact?.id) + 1;

    const handlePreviousContact = () => {
        const previousContactIndex = getContactPosition(currentContact) - 2;
        previousContactIndex >= 0 && setCurrentContact(companyContacts[previousContactIndex]);
    }

    const handleNextContact = () => {
        const nextContactIndex = getContactPosition(currentContact);
        const contactsQuantity = companyContacts.length - 1;
        if (nextContactIndex > contactsQuantity) return;
        setCurrentContact(companyContacts[nextContactIndex]);
    }

    const sumEfficiencyGrade = () => {
        if (grade !== null) {
            const efficiencyGradeArray: number[] = Object.values(grade?.efficiency_grade);
            setEfficiencyTotal(efficiencyGradeArray.reduce((accumulator, currentValue) => currentValue + accumulator))
        }
    }

    useEffect(() => {
        dispatch(storeDiagnostic(diagnostic));
        company.companyContext?.contacts[0] &&
            setCurrentContact(company.companyContext?.contacts[0]);

        if(!filteredDiagnosticResult?.diagnosticResult?.totalAxisMedia) showAlert()

    }, []);
    
    useEffect(() => {
        sumEfficiencyGrade()
    }, [grade]);

    useEffect(() => {
        setGrade(filteredGrade || null)
    }, [diagnostic]);
    
    return (
        <>
           
           <div id="process-container">
                <div className="container-charts">
                    <div className="container-chart-title">
                        <h4>Processo produtivo e cadeia de suprimentos</h4>
                    </div>
                    <div className="container-chart-content">
                        <div className="radar-chart-container">
                            <div style={{ height: "92%" }}>
                                <RadarChartComponent
                                    evaluation='0'
                                    data={filteredDiagnosticResult?.diagnosticResult?.totalAxisMedia[0] ?? defaultResults}
                                ></RadarChartComponent>
                            </div>
                            <div className="evaluation-result-container">
                                <div className="chart-evaluation">
                                    <span>Avaliação: {productiveProcessEvaluation || 0} / 3</span>
                                </div>
                            </div>
                        </div>
                        <div className="bar-chart-container">
                            <div className="digital-technologies-title">
                                <h3>Nível de maturidade</h3>
                            </div>
                            <div className="bar-chart-wrapper">
                                <DigitalTechnologiesChart evaluationResult={productiveProcessEvaluation} columns={productiveProcessAndSupplyChainColumns} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-charts">
                    <div className="container-chart-title">
                        <h4>Digitalização e conectividade industrial</h4>
                    </div>
                        <div className="container-chart-content">
                            <div className="radar-chart-container">
                                <div style={{ height: "92%" }}>
                                    <RadarChartComponent
                                        evaluation='0'
                                        data={filteredDiagnosticResult?.diagnosticResult?.totalAxisMedia[1] ?? defaultResults}
                                    ></RadarChartComponent>
                                </div>
                                <div className="evaluation-result-container">
                                    <div className="chart-evaluation">
                                        <span>Avaliação: {digitalizationEvaluation || 0} / 3</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bar-chart-container">
                                <div className="digital-technologies-title">
                                    <h3>Nível de maturidade</h3>
                                </div>
                                <div className="bar-chart-wrapper">
                                    <DigitalTechnologiesChart
                                        evaluationResult={digitalizationEvaluation}
                                        columns={digitalizationAndIndustrialConnectivity}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <aside id="side-data-container">
                <div id="energetic-efficiency-container">
                    <div className="company-info-title">
                        <h2>Pré-diagnóstico de eficiência energética</h2>
                    </div>
                    {efficiencyTotal !== null ?
                        <TrafficLight efficiency={{
                            energy: efficiencyTotal,
                            organization: 3,
                        }} />
                        :
                        <div style={{marginTop:'1.5rem'}}>Dados não foram coletados</div>
                    }
                    
                </div>
                <div id="item-divisor">
                    <div id="divisor-line"></div>
                </div>
                <div id="company-info-container">
                    <div className="company-info-item">
                        <div className="company-info-title">
                            <h3>Dados da empresa</h3>
                        </div>
                        <div id="company-details-container">
                            <p>CNPJ: {applyCnpjMask(company.companyContext?.CNPJ)}</p>
                            <p>Cidade: {company.companyContext?.city}</p>
                            <p>Área: {company.companyContext?.area}</p>
                        </div>
                    </div>
                    <div className="company-info-item">
                        <div className="company-info-title">
                            <h3>Contatos</h3>
                        </div>
                        {
                        company.companyContext?.contacts.length === 0 ? (
                            <span>Nenhum contato encontrado.</span>
                        ) : (
                            <>
                                <div id="company-data-container">
                                    <div id="contact-edition-container" >
                                        <span>{getContactPosition(currentContact)}º Contato</span>
                                    </div>

                                    <p>Contato: {currentContact?.name}</p>
                                    <p>E-mail: {currentContact?.email}</p>
                                    <p>Telefone: {currentContact?.phone}</p>
                                </div>
                                <div id="contact-pagination-container">
                                    <div id="pagination-button-container">
                                        <img
                                            src="/assets/icons/left-arrow-black.png"
                                            alt="Contato anterior"
                                            onClick={() => handlePreviousContact()}
                                        />
                                        <span> {getContactPosition(currentContact)} de {companyContacts?.length} </span>
                                        <img
                                            src="/assets/icons/right-arrow-black.png"
                                            alt="Próximo contato"
                                            onClick={() => handleNextContact()}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            </aside>
 
            <ModalContact
                contact={currentContact}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                contactPosition={getContactPosition(currentContact)}
            />
        </>
    );
};

export default DiagnosticComponent;