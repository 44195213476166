import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { applyCnpjMask } from '../../utils/masks';
import { getLogoJtdRed, getLogoSenai } from '../Proposals/ImagesStepsJorneyPDF';
import { getImageSemaphore } from './ImagesPDF';

const systemScale = window.devicePixelRatio;

const scaleCalcImage = (scale) => {
    if (scale < 1) return 0.9
    else if (scale === 1) return 0.7
    else if (scale >= 1 || scale <= 1.5) return 0.5
    else if (scale > 1.5) return 0.2

    return 1
}


export async function EnergyEfficiencyGeneratePDF({ isReviewed, hubs, companyContext, diagnosticContext, quizData, efficiency }) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const efficiencyGrade = Object.keys(efficiency).map((key) => {
        return efficiency[key];
    });

    const efficiencyGradeTotal = efficiencyGrade.reduce((a, b) => a + b, 0);

    const color =
        efficiencyGradeTotal >= 0
            ? "green"
            : efficiencyGradeTotal >= -10
                ? "yellow"
                : "red";

    const [logoJtdRed, logoSenai, imageSemaphore] = await Promise.all(
        [getLogoJtdRed(), getLogoSenai(), getImageSemaphore(color)]
    );

    const selectedDateTime = companyContext.diagnostics[0].updatedAt || "-";

    function toMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('pt-BR', {
            month: 'long',
        });
    }

    var splitarray = new Array();
    splitarray = selectedDateTime.split("-");

    const stateLocation = companyContext.uf

    const setProposalDateAndUf = (stateLocation, splitarray) => {
        return stateLocation + ", " + splitarray[2]?.substring(0, 2) + " de " + toMonthName(splitarray[1]) + " de " + splitarray[0]
    }

    const content = [
        {
            image: logoJtdRed.image,
            width: 150,
            alignment: 'center',
            marginBottom: 50
        },
        {
            text: setProposalDateAndUf(stateLocation, splitarray),
            fontSize: 12,
            color: 'black',
            alignment: 'center',
            marginBottom: 60
        },
        {
            image: logoSenai.image,
            width: 120,
            marginBottom: 60,
            alignment: 'center'
        },
        {
            text: 'PRÉ-DIAGNÓSTICO DE EFICIÊNCIA ENERGÉTICA',
            style: ['header', 'marginDefault'],
            marginBottom: 20
        },
        {
            text: companyContext?.name,
            style: ['textAlignedCenter'],
        },
        {
            text: `CNPJ: ${applyCnpjMask(companyContext?.CNPJ)}`,
            style: ['textAlignedCenter'],
            marginBottom: 20
        },
        {
            text: 'Nível de maturidade de eficiência energética que a \nempresa se encontra neste momento:',
            style: ['textAlignedCenter'],
            marginBottom: 5
        },
        {
            text: `Nota: ${efficiencyGradeTotal}`,
            style: ['header', 'marginDefault'],
            bold: true,
            marginBottom: 20
        },
        {
            image: imageSemaphore.image,
            marginTop: 24,
            alignment: 'center',
            width: 280 * scaleCalcImage(systemScale),
            height: 220 * scaleCalcImage(systemScale)
        },
        {
            text: '',
            pageBreak: 'after'
        },
        hubs?.map((hub) => hub.criterion.map((criteria) => {
            return quizData.find((quiz) => quiz.criteriaId == criteria.id) && ([{
                text: criteria.name,
                fontSize: 12,
                bold: true,
                marginBottom: 8,
                marginLeft: 10
            },
            criteria.questions.map((question, questionIndex) => {
                return quizData.find((quiz) => quiz.data.questionId == question.id) && ([
                    {
                        text: `${questionIndex + 1}. ${question?.description}`,
                        margin: [20, 5, 0, 0]
                    },
                    {
                        text: question.answers.map((answer) => {
                            return quizData && quizData.find((quiz) => quiz.data.answerId == answer.id) ? (
                                answer.description
                            ) : null
                        }),
                        margin: [25, 4, 0, 4],
                        color: '#9A1915'
                    }]
                )
            }), {
                text: '',
                marginBottom: 8
            }]
            )

        }))
    ]

    const docDefinitions = {
        pageSize: 'A4',
        content: [content],
        footer(currentPage, pageCount) {
            if (currentPage > 1) {
                return {
                    layout: 'noBorders',
                    margin: [14, 0, 14, 22],
                    table: {
                        widths: ['auto'],
                        body: [
                            [
                                {
                                    text:
                                        '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                                    alignment: 'center',
                                    fontSize: 5,
                                },
                            ],
                            [
                                [
                                    {
                                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                                        fontSize: 7,
                                        alignment: 'right',
                                        margin: [3, 0],
                                    },
                                    {
                                        text: '© Jornada de Transformação Digital',
                                        fontSize: 7,
                                        alignment: 'center',
                                    },
                                ],
                            ],
                        ],
                    },
                };
            }
        },
        styles: {
            header: {
                bold: true,
                alignment: 'center',
                fontSize: 16,
            },
            textAlignedCenter: {
                alignment: 'center',
                fontSize: 12,
            },
            spacingDefault: {
                marginTop: 15,
            },
            text: {
                fontSize: 12,
            },
            marginDefault: {
                margin: 10
            }
        }
    }

    pdfMake.createPdf(docDefinitions).download(`EE-${diagnosticContext?.proposal_number}-${companyContext?.name.replace(/ +/g, "_")}`);
}