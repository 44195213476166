import React, { ReactNode } from 'react';

interface IPopUpProps {
    children? : ReactNode
}

const NameIndicatorComponent:React.FC<IPopUpProps> = ({children}) => {

    return (
        <>
            <div className="nameItem">
                <span>
                    {children}
                </span>
            </div>
        </>
    )
}

export default NameIndicatorComponent