import { ChangeEvent, useEffect, useState } from 'react'
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { ExportToCsv } from 'export-to-csv'
import { Box, Button, Select, MenuItem } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { getAllByFilter, getFilters } from '../../services/RegistryReport'

interface Answer {
    result: string
}

interface CompanyEvaluation {
    CNPJ: string
    consultantId: string
    diagnosticId: string
    consultant: string
    company_id: string
    name: string
    proposal_number: string
    registry_result: Array<
        Array<{
            id: string
            name: string
            type: string
            number: number
            answers: Answer[]
        }>
    >
    unity: string
}

const initialColumns: MRT_ColumnDef<any>[] = [
    {
        accessorKey: 'diagnosticId',
        header: 'DiagnosticId',
        filterVariant: 'text',
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'company_id',
        header: 'CompanyId',
        filterVariant: 'text',
        size: 100,
        enableHiding: true,
    },
    {
        accessorKey: 'consultantId',
        header: 'ConsultantId',
        filterVariant: 'text',
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'name',
        header: 'Nome da Empresa',
        filterVariant: 'text',
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'CNPJ',
        header: 'CNPJ da Empresa',
        filterVariant: 'text',
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'proposal_number',
        header: 'Número da Proposta',
        filterVariant: 'text',
        size: 100,
        enableHiding: false,
    },
    {
        accessorKey: 'unity',
        header: 'Unidade',
        filterVariant: 'text',
        size: 10,
        enableHiding: false,
    },
    {
        accessorKey: 'consultant',
        header: 'Consultor',
        filterVariant: 'text',
        size: 10,
        enableHiding: false,
    },
]

const RegistryReportPage = () => {
    const [selectedEvaluation, setSelectedEvaluation] = useState<string>(
        'Consultoria em Diagnóstico Tecnológico'
    )
    const [alternatives, setAlternatives] = useState<{ name: string }[]>([
        { name: 'Consultoria em Diagnóstico Tecnológico' },
    ])
    const [tableColumns, setTableColumns] =
        useState<MRT_ColumnDef<any>[]>(initialColumns)
    const [tableRows, setTableRows] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const csvOptions = {
        title: 'registry_report',
        fieldSeparator: ';',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: tableColumns?.map((c: any) => c.header),
    }

    const csvExporter = new ExportToCsv(csvOptions)

    const handleExportData = () => {
        csvExporter.generateCsv(tableRows)
    }

    const handleOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedEvaluation(selectedValue);
    }

    useEffect(() => {
        setIsLoading(true)

        const setUniqueStatementsFromResponse = async (
            companiesEvaluation: CompanyEvaluation[]
        ) => {
            const uniqueStatementsSet = new Set<string>()

            companiesEvaluation.forEach((companyEvaluation) => {
                companyEvaluation.registry_result.forEach((resultArray) => {
                    resultArray.forEach((item) => {
                        uniqueStatementsSet.add(item.name.replace('.', '●'))
                    })
                })
            })

            const uniqueStatements = Array.from(uniqueStatementsSet)
            const statementsInColumn: MRT_ColumnDef<any>[] = uniqueStatements.map(
                (statement) => ({
                    accessorKey: statement,
                    header: statement,
                    filterVariant: 'text',
                    size: statement.length * 5,
                    enableHiding: true,
                })
            )

            setTableColumns([...statementsInColumn])
            formatRowsToRenderTable(uniqueStatements, companiesEvaluation)
        }

        const formatRowsToRenderTable = async (
            uniqueStatements: string[],
            companiesEvaluation: CompanyEvaluation[]
        ) => {
            const formattedData: { [key: string]: string }[] = []

            companiesEvaluation.forEach((evaluation) => {
                const rowData: { [key: string]: string } = {}

                rowData['diagnosticId'] = evaluation.diagnosticId
                rowData['companyId'] = evaluation.company_id
                rowData['consultantId'] = evaluation.consultantId
                rowData['name'] = evaluation.name
                rowData['CNPJ'] = evaluation.CNPJ
                rowData['proposalNumber'] = evaluation.proposal_number
                rowData['unity'] = evaluation.unity
                rowData['consultant'] = evaluation.consultant

                evaluation.registry_result.forEach((resultArray) => {
                    resultArray.forEach((item) => {
                        const statement = item.name.replace('.', '●')
                        const answers = item.answers.map((answer) => answer.result).join(', ')

                        rowData[statement] = answers
                    })
                })

                uniqueStatements.forEach((statement) => {
                    if (rowData[statement]) {
                        rowData[statement] = rowData[statement] !== '' ? rowData[statement] : 'Em branco'
                    } else {
                        rowData[statement] = 'Não respondida'
                    }
                })

                formattedData.push(rowData)
            })

            setTableRows(formattedData)
        }

        const fetchData = async () => {
            try {
                const filters = (await getFilters()).data
                setAlternatives(filters)

                const response = await getAllByFilter(selectedEvaluation)

                setUniqueStatementsFromResponse(response.data)
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [selectedEvaluation])

    return (
        <div className="main-container">
            <div
                className="company-container"
                style={{ maxHeight: '79vh', marginTop: '11vh' }}
            >
                <div style={{ overflowX: 'auto', overflowY: 'auto', width: '100%' }}>
                    <MaterialReactTable
                        localization={{
                            noRecordsToDisplay:
                                'Não foi encontrado nenhum registro correspondente',
                        }}
                        filterFromLeafRows={true}
                        columns={tableColumns}
                        data={tableRows}
                        enableStickyHeader
                        enableDensityToggle={false}
                        state={{ isLoading: isLoading }}
                        initialState={{
                            showColumnFilters: true,
                            columnVisibility: {
                                unity: false,
                                consultant: false,
                                diagnosticId: false,
                                company_id: false,
                                consultantId: false,
                            },
                            density: 'compact',
                            pagination: { pageSize: 15, pageIndex: 0 },
                        }}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [15, 30, 50, 100, tableRows?.length],
                            labelRowsPerPage: 'Linhas por página',
                            showFirstButton: false,
                            showLastButton: false,
                        }}
                        positionToolbarAlertBanner="bottom"
                        renderTopToolbarCustomActions={() => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    p: '0.5rem',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Select
                                    value={selectedEvaluation}
                                    name="journey-step"
                                    className="jorney-step-select"
                                    onChange={(event) => handleOptionChange(event as any)}
                                >
                                    {alternatives.map((alternative) => (
                                        <MenuItem key={alternative.name} value={alternative.name}>
                                            {alternative.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    color="primary"
                                    onClick={handleExportData}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    CSV
                                </Button>
                            </Box>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default RegistryReportPage
