import React from "react";

const FilterSearch: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
    return (
        <>
            <div className="filter-item-input">
                <input {...props} />
                <img src="/assets/icons/search-icon.png" alt="img"/>
            </div>
        </>
    )
}

export default FilterSearch



