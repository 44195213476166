import { useState } from 'react';
import { useEffect } from 'react'; 
import { useAppSelector } from "../../store"; 
import { getAllTechDiagnostics, getDiagnosticsByProposalId } from '../../utils/utils';
import HeaderHelmetComponent from '../Layout/HeaderHelmet';
import DiagnosticComponent from './DiagnosticComponent';
import ConstructionComponent from './ConstructionComponent';
 
const CompanyDetails = () => {
    const [notConstruction, setNotConstruction] = useState<boolean>(true); 
    const [proposalId, setProposalId] = useState<any>(null);
    const [diagnosticOwner, setDiagnosticOwner] = useState<any>(false);

    const { company } = useAppSelector(state => state.company); 

    const allDiagnostics = getAllTechDiagnostics(company.companyContext);

    const [diagnostic, setDiagnostic] = useState<any>(getDiagnosticsByProposalId(allDiagnostics, allDiagnostics[0]?.proposal_id))
    
    useEffect(()=>{
        setProposalId(allDiagnostics[0]?.proposal_id)
    },[])

    useEffect(()=>{
        if(diagnostic?.grade?.constructionResult !== undefined) {
            setNotConstruction(false)
        }else{ 
            setNotConstruction(true)
        }

        setDiagnosticOwner(diagnostic?.user);

    },[proposalId,diagnostic])

    useEffect(()=>{
        setDiagnostic(getDiagnosticsByProposalId(allDiagnostics, proposalId || allDiagnostics[0]?.proposal_id))
    },[proposalId])

    const updatedAt = ()=>{
        const [year,month,day] =(diagnostic?.updatedAt)?.substr(0,10)?.split('-')
        if(year) return day + "/" + month + "/" + year

        return null
    }

    const filteredAllDiagnostics = [...new Map(allDiagnostics.map((item:any)=> [item['proposal_id'],item])).values()]

    return( 
    <>  
        <HeaderHelmetComponent nestedPage={company.companyContext?.name ?? `Detalhes da empresa`} />
        <div className="profile-details-container">
                {company.companyContext && (
                    <div className="profile-flex-container">
                        <div className="row-container-top">
                            <div className="company-information">
                                <div className="main-informations">
                                    <h2>{company.companyContext?.name}</h2>
                                    <div className="divider"></div>
                                    <div style={{display:'flex',justifyContent:'space-between'}}>
                                        <div style={{display:'flex',gap:'5px',height:'10px'}}>
                                            <h4>{filteredAllDiagnostics.length > 1? 'Selecione uma proposta:':filteredAllDiagnostics.length === 1?'Proposta:':''}</h4>
                                            {filteredAllDiagnostics?.map((diagnostic:any,index:number) =>
                                                <p style={{
                                                    borderRadius:'6px',
                                                    maxHeight:'19.0909px',
                                                    fontWeight:`${allDiagnostics[index].proposal_id === proposalId?'bold':''}`,
                                                    color:`${allDiagnostics[index].proposal_id === proposalId?'blue':''}`,cursor:'pointer'
                                                }}
                                                    onClick={()=>setProposalId(allDiagnostics[index].proposal_id)} 
                                                >
                                                    {diagnostic.proposal_number} {filteredAllDiagnostics.length > index + 1 ? 
                                                    <span style={{color:'black',fontWeight:'normal',cursor:'auto'}}>|</span> : ''}
                                                </p>
                                            )}
                                        </div>
                                        <div>
                                            <div id='consultant-data-content'>
                                                <span>{diagnosticOwner?.name && `Consultor da proposta: ${diagnosticOwner?.name.toUpperCase()}`}</span>
                                                <span>{diagnostic?.updatedAt && `Última atualização: ${updatedAt()}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-bottom">
                        {notConstruction ? <DiagnosticComponent proposalId={proposalId}/>:<ConstructionComponent proposalId={proposalId}/>} 
                        </div>
                    </div>
                )}
            </div> 
    </>
    );
};

export default CompanyDetails;