import { type } from "@testing-library/user-event/dist/type"
import { useEffect, useState } from "react"
import { Squares } from "react-activity"
import "react-activity/dist/library.css";
import techProductService from "../../services/techProduct"
import HeaderHelmetComponent from "../Layout/HeaderHelmet"
import { SortTypes } from "../Users/DataVisualization/DataTable"
import ConfirmTechProductDeleteModal from "./modals/ConfirmTechProductDeleteModal"
import TechProductModal from "./modals/TechProductModal"

interface ISortData {
    key: 'journeyStep' | 'name' | 'createdAt'
    type: string
}

const stepOptions = [
    'Etapa 1 - Diagnóstico',
    'Etapa 2 - Estratégia',
    'Etapa 3 - Otimização de Processos',
    'Etapa 4 - Mapeamento',
    'Etapa 5 - Automação',
    'Etapa 6 - Digitalização',
    'Etapa 7 - Integração',
    'Etapa 8 - Indústria Inteligente'
]

const TechProductComponent: React.FC = () => {
    const [search, setSearch] = useState("");
    const [techProducts, setTechProducts] = useState<any[]>([])
    const [currentModal, setCurrentModal] = useState(<></>)
    const [sortData, setSortData] = useState<ISortData>({
        key: "createdAt",
        type: SortTypes.ASC
    })

    const [isLoading, setLoading] = useState(true)

    const getTechProducts = async () => {
        const res = await techProductService.getTechProducts();
        setTechProducts(res.data)
        setLoading(false)
    }

    const sortProducts = (a: any, b: any) => {
        if (a[sortData.key] > b[sortData.key])
            return (sortData.type === SortTypes.DESC) ? -1 : 1
        else if (a[sortData.key] < b[sortData.key])
            return (sortData.type === SortTypes.ASC) ? -1 : 1

        return 1
    }

    const handleOrderSelection = (key: 'journeyStep' | 'name' | 'createdAt') => {
        if (sortData.key != key) {
            return setSortData({
                key,
                type: SortTypes.ASC
            })
        }

        if (sortData.type == SortTypes.ASC) {
            setSortData({ ...sortData, type: SortTypes.DESC })
        } else if (sortData.type == SortTypes.DESC)
            setSortData({
                key: "createdAt",
                type: SortTypes.ASC
            })
    }

    const filterProducts = (product: any) => {             
        if (!search.trim().length) return true        
        return product.name.normalize('NFD').replace(/\p{Diacritic}/gu, "")?.toLowerCase().includes(search.normalize('NFD').replace(/\p{Diacritic}/gu, "")?.toLowerCase()) || 
        product.journeyStep.normalize('NFD').replace(/\p{Diacritic}/gu, "")?.toLowerCase().includes(search.normalize('NFD').replace(/\p{Diacritic}/gu, "")?.toLowerCase())
    }

    const openAddModal = () => {
        setCurrentModal(
            <TechProductModal onClose={closeModal} />
        )
    }

    const closeModal = () => {
        setCurrentModal(<></>)
        getTechProducts()
    }

    const [filterType,setFilterType]=useState('product')

    useEffect(() => {
        getTechProducts()
    }, [])

    function filterBySteps(e:any){                
        if(e=='0')setSearch('')
        else{
            setSearch(e)
            setFilterType('step')      
        }          
    }
    function filterProductsBySteps(product:any){
        if (!search.trim().length) return true        
        return product.journeyStep==search
    }

    return (
        <>
            <HeaderHelmetComponent nestedPage="Produtos Tecnológicos" />
            <div className="tech-product-page">
                <div className="card-container">
                    <div className="tech-product-header">
                        <h1>Produtos Tecnológicos</h1>

                        <button onClick={openAddModal}>Adicionar</button>
                    </div>

                    <div className="data-view-table-container">
                        <div className="data-view-search-wrapper">
                            <div className="step-filter">                                
                                <select onChange={(e)=>filterBySteps(e.target.value)} name="step-filters" className="jorney-step-select">
                                    <option value={0} selected >todos</option>
                                    {
                                        stepOptions.map((step, index) => 
                                            <option value={`${index + 1}`}>{step}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="data-view-search">
                                <input type={"text"} placeholder="Pesquisar" onChange={(e) => setSearch(e.target.value)} />
                                <img src="/assets/icons/search-icon.png" alt="search-icon" style={{ maxWidth: 28 }} />
                            </div>     
                        </div>
                        <div className="table-wrapper">

                            <table>
                                <tr>
                                    <th style={{ width: "20%", }}>
                                        <div className="table-header-content">
                                            Passo da jornada
                                            <button className="order-selector-button" onClick={() => handleOrderSelection("journeyStep")}>
                                                {(sortData.key === "journeyStep") ? sortData.type == SortTypes.ASC ? "⭣" : "⭡" : "⮃"}
                                            </button>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-header-content">
                                            Nome
                                            <button className="order-selector-button" onClick={() => handleOrderSelection("name")}>
                                                {(sortData.key === "name") ? sortData.type == SortTypes.ASC ? "⭣" : "⭡" : "⮃"}
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                                <tbody>
                                    {
                                        isLoading ?
                                            <Squares color="#9a1915" size={24} speed={1} animating={true} />
                                            :
                                            techProducts.filter((p)=>filterType=='product'?filterProducts(p):filterProductsBySteps(p)).sort(sortProducts).map((product) =>
                                                <tr>
                                                    <td style={{ width: "20%", }}>{product.journeyStep}</td>
                                                    <td>
                                                        <div className="tech-product-name-container">
                                                            <span>{product.name}</span>            
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {currentModal}
        </>
    )
}

export default TechProductComponent