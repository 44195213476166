import React, { SetStateAction, useEffect, useRef, useState } from "react"
import { Squares } from "react-activity"
import diagnosticService from "../../services/diagnostic"
import HeaderHelmetComponent from "../Layout/HeaderHelmet"
// import 

import ASCIcon from '../assets/up.svg';
import DESCIcon from '../assets/down.svg';
import NONEIcon from '../assets/opposite_arrows.svg';

// export const SortTypes = {
//     ASC: 'asc',
//     DESC: 'desc',
//     NONE: 'none',
// }

export const SortTypes = {
    ASC: ASCIcon,
    DESC: DESCIcon,
    NONE: NONEIcon,
}
// export const SortTypes = [
//     {
//         icon: NONEIcon,
//         name: 'NONE'
//     },

//     {
//         icon: ASCIcon,
//         name: 'ASC'
//     },
//     {
//         icon: DESCIcon,
//         name: 'DESC'
//     },

// ]

interface ISortData {
    key: keyof any,
    type: string
}


const DashboardComponent = () => {
    const [search, setSearch] = useState("")

    const [users, setUsers] = useState<any[]>([])
    const [sortData, setSortData] = useState<ISortData>({
        key: "nif",
        type: ASCIcon
    })

    const sortUsers = (a: any, b: any) => {
        if (a[sortData.key] > b[sortData.key])
            return (sortData.type === DESCIcon) ? -1 : 1
        else if (a[sortData.key] < b[sortData.key])
            return (sortData.type === ASCIcon) ? -1 : 1

        return 1
    }
    const searchConsultant = async () => {
        await diagnosticService.getUnititesStatusOfDiagnostics().then(data => {
            setUsers(data.data);
        })
    }

    const handleOrderSelectorClick = (_key: keyof any) => {
        if (sortData.key != _key)
            return setSortData({
                key: _key,
                type: ASCIcon
            })

        if (sortData.type == ASCIcon) {
            setSortData({ ...sortData, type: DESCIcon })
        } else if (sortData.type == DESCIcon)
            setSortData({
                key: "createdAt",
                type: ASCIcon
            })
    }

    const filterUsers = (user: any) => {
        if (!search.trim().length) return true

        return user.unity?.toLowerCase().includes(search?.toLowerCase())
    }

    useEffect(() => { searchConsultant() }, [])

    /*
    {
    "unity": "101",
    "total": "47",
    "open": "47",
    "running": "0",
    "awaiting_approval": "0",
    "finished": "0"
}
*/


    return (
        <>
            <HeaderHelmetComponent nestedPage="Dashboard" />
            <div className="journey-container">
                <div className="journey-card">

                    <div className='data-view-table-container'>
                        {/* <div className="data-view-search-wrapper table-header" style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%", marginRight: "5%", height:'6%', paddingBottom:'.6%'}}> */}
                        <div className="data-view-search-wrapper table-header">
                            <h1 className="user-page-title">Dashboard</h1>
                            <div className="data-view-search">
                                <input type={"text"} placeholder="Pesquisar" value={search} onChange={(e: any) => setSearch(e.target.value)} />
                                <img src="/assets/icons/search-icon.png" style={{ maxWidth: 28 }} />
                            </div>
                        </div>
                        <div className="table-container">
                            <table>
                                <tr>
                                    <th>
                                        <div className="table-header-content" onClick={() => handleOrderSelectorClick("unity")}>
                                            Unidade
                                            <button className="order-selector-button">
                                                <img src= {(sortData.key === "unity") ? sortData.type == ASCIcon ? DESCIcon : ASCIcon : NONEIcon}/>
                                            </button>
                                        </div>
                                    </th>
                                    <th>Total de Propostas</th>
                                    <th>
                                        <div>
                                            Abertas
                                        </div>
                                    </th>
                                    <th>Em andamento</th>
                                    {/* <th>Aguardando Validação</th> */}
                                    <th>Encerradas</th>


                                </tr>
                                <tbody>
                                    {
                                        users.length > 0 ?
                                            users.filter(filterUsers).sort(sortUsers).map((user: any) =>
                                                <tr>
                                                    <td>{user?.unity}</td>
                                                    <td>{user?.total}</td>

                                                    <td>{user?.open}</td>
                                                    <td>{user?.running}</td>
                                                    {/* <td>{user?.awaiting_approval}</td> */}
                                                    <td>{user?.finished}</td>

                                                </tr>
                                            )
                                            :
                                            <Squares color="#9a1915" size={24} speed={1} animating={true} />
                                    }
                                </tbody>
                            </table>
                            <footer>
                                <span>No total existem {users?.length} registros</span>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DashboardComponent