import React, { useState } from 'react'

interface IPasswordFieldProps {
    title: string
    passwordValue: string
    setPasswordValue: any
    isNecessaryPasswordLevel: boolean
    passwordStrenght?: any
    setPasswordStrenght?: any
}

const PasswordFieldComponent: React.FC<IPasswordFieldProps> = (props: IPasswordFieldProps) => {
    
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

    const passwordLevel = {
        strong: new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'),
        medium: new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    }

    const strenghtChecker = (password: string) => {
        if(props.passwordValue == ""){
            return ""
        }else if(passwordLevel.strong.test(password)){
            props.setPasswordStrenght("senha forte")
        }else if(passwordLevel.medium.test(password)){
            props.setPasswordStrenght("senha mediana (utilize letras maiúsculas e minúsculas, números e simbolos / * !)")
        }else{
            props.setPasswordStrenght("senha fraca (utilize letras maiúsculas e minúsculas, números e simbolos / * !)   ")
        }
    }

    const setClassNameWithStrenght = (passwordStrenght : string) => {
        if(props.passwordStrenght == "senha forte"){
            return "strongPassword"
        }else if (props.passwordStrenght == "senha mediana"){
            return "mediumPassword"
        }else {
            return "weakPassword"
        }
    }

    const handleChange = (e : any) => {
        props.setPasswordValue(e.target.value.replace(" ", "")  )

        strenghtChecker(e.target.value)
    }

    return (
        <>
            <div className='passwordContainer'>
                <span>{props.title}</span>
                <div>
                    <input className='field' type={passwordVisible ? "text" : "password"} value={props.passwordValue} onChange={handleChange} />
                    <button onClick={() => setPasswordVisible(!passwordVisible)} className='passwordVisible' type="button">
                        <img className="icon" src='/assets/icons/eyeicon.png' />
                    </button>
                </div>
                {props.isNecessaryPasswordLevel ? <span className={setClassNameWithStrenght(props.passwordStrenght)}>{props.passwordStrenght}</span> : null}
            </div>


        </>
    )
}

export default PasswordFieldComponent