import { useEffect, useState } from "react";
import { Squares } from "react-activity";
import { ISpeciality } from "../../interfaces/speciality";
import specialtyService from "../../services/specialty";
import HeaderHelmetComponent from "../Layout/HeaderHelmet"
import ConfirmationModal from "../utils/ConfirmationModal";
import AddSpecialtyModal from "./Modals/AddSpecialtyModal";


const SpecialtiesComponent = () => {
    const [specialties, setSpecialties] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('')
    const [currentModal, setCurrentModal] = useState(<></>)

    const getSpecialties = async () => {
        const data = await specialtyService.getSpecialties(search);

        setSpecialties(data ?? [])
        setLoading(false)
    }

    const openSpecialtyModal = (specialty?: { id: string, name: string }) => {
        setCurrentModal(
            <AddSpecialtyModal
                onClose={() => {
                    setLoading(true)
                    setCurrentModal(<></>)
                    getSpecialties()
                }}
                editingSpecialty={specialty}
            />
        )
    }

    const openDeleteConfirmation = (speciality: ISpeciality) => {
        setCurrentModal(
            <ConfirmationModal
                message={`Tem certeza que deseja deletar ${speciality.name}?`}
                negativeAction={() => setCurrentModal(<></>)}
                positiveAction={() => deleteSpecialty(speciality.id)}
                positiveText='Sim, deletar'
            />
        )
    }

    const deleteSpecialty = async (id?: string) => {
        if (!id) return

        const res = await specialtyService.removeSpecialty(id)

        if (res.hasError)
            alert(res.errorMessage)

        setLoading(true)
        setCurrentModal(<></>)
        getSpecialties()
    }

    useEffect(() => { getSpecialties() }, [search])

    return (
        <>
            <HeaderHelmetComponent nestedPage="Especialidades" />
            <div className="journey-container">
                <div className="journey-card" >

                    <div className="data-view-table-container" >
                        <div className="table-header">
                            <h1 className="user-page-title">Especialidades</h1>
                            <div className="header-filters-conatiner">
                                <div className="data-view-search">
                                    <input type={"text"} placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <img src="/assets/icons/search-icon.png" style={{ maxWidth: 28 }} />
                                </div>
                                <button disabled onClick={() => openSpecialtyModal()}>Adicionar</button>
                            </div>
                        </div>
                        <div className="table-wrapper" >
                            <table>
                                <tr>
                                    <th>Nome</th>
                                </tr>
                                <tbody>
                                    {
                                        loading ?
                                            <Squares color="#9a1915" size={24} speed={1} animating={true} />
                                            :
                                            (specialties.length > 0) ?
                                                specialties.map((specialty: any) =>
                                                    <tr>
                                                        <td>
                                                            <div className="specialty-cell-content">
                                                                <span>{specialty.name}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                <>Infelizmente nenhum registro foi encontrado</>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {currentModal}
        </>
    )
}

export default SpecialtiesComponent
