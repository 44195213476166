import evaluationService from "../../../../services/evaluation";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Answer, { Question } from "./Answer";
import { useParams } from "react-router-dom";
interface ICreateQuestionModal {
    action: "REGISTER" | "EDIT"
    reload: () => void
    close: () => void
    relation: 'criteria' | 'hub' | 'evaluation'
    questions: []
    id: string
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>
    }
    questionId?: string
    hasDependency?: string
    editQuestion?: any
    parentQuestion?: any
    sessions?: []
    journeyStep?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8'
}


const icons = {
    closeModal: '/assets/icons/close-modal.png'
}

const CreateQuestionModal = ({ action, journeyStep, reload, alert, close, editQuestion, questions, id, questionId, relation, parentQuestion, sessions }: ICreateQuestionModal) => {
    const initialQuestion: Question = {

        name: parentQuestion?.name || "",
        description: "",
        weight: 1,
        type: "",
        hasDependency: parentQuestion?.id || "",
        isWanted: true,
        sequenceIndex: editQuestion ? editQuestion.sequenceIndex : questions.length + 1,
        [relation]: {
            id: id
        }

    }

    const [wanted, setWanted] = useState(true)
    const [tableQuestionDesc, setTableQuestionDesc] = useState<string[]>([''])
    const [question, setQuestion] = useState(initialQuestion)

    const [answers, setAnswers] = useState([
        {
            id: "",
            name: "",
            description: "",
            weight: 0,
            sequenceIndex: 0,
            ramification: "",
            question: {
                id: ""
            }
        }
    ]
    )

    const handleRamification = (value: string, index: number) => {
        setAnswers(answers.map((answer, answerIndex) => answerIndex !== index ? answer : {
            ...answers[index],
            ramification: value
        }))
    }


    const handleAnswer = (event: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>, index: number) => {

        if (question.type == 'Tabela') {
            setAnswers(answers.map((answer, answerIndex) => answerIndex !== index ? answer : {
                ...answers[index],
                [event.target.name]: event.target.value,
                description: event.target.value
            }))
        } else if ((question.type == 'Alternativa' || question.type == 'Múltipla Escolha') && event.target.name === "description") {
            setAnswers(answers.map((answer, answerIndex) => answerIndex !== index ? answer : {
                ...answers[index],
                [event.target.name]: event.target.value,
                name: event.target.value
            }))
        } else {
            setAnswers(answers.map((answer, answerIndex) => answerIndex !== index ? answer : {
                ...answers[index],
                [event.target.name]: event.target.value,
            }))
        }

    }

    const removeAnswerRamification = (index: number) => {
        setAnswers(answers.map((answer, answerIndex) => answerIndex !== index ? answer : {
            ...answers[index],
            ramification: "",
        }))
    }

    const deleteAnswer = (index: number, id?: string) => {

        setAnswers(answers.filter((answers, answerIndex) => answerIndex !== index))
        if (id)
            evaluationService.deleteAnswer(id).then((res) => {
                reload()
            })

    }
    const addAnswer = () => {
        let newAnswer = {
            id: "",
            name: "",
            description: "",
            weight: 0,
            ramification: "",
            sequenceIndex: answers.length + 1,
            question: {
                id: ""
            }
        }

        setAnswers([...answers, newAnswer])
    }

    const handleWanted = () => {
        setQuestion({
            ...question,
            isWanted: !wanted
        })
        setWanted(!wanted)
    }

    const handleWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion({
            ...question,
            weight: parseInt(event.target.value)
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {

        if (event.target.name == "type" && event.target.value !== "Tabela" && RegExp("[#$#]").test(question.description)) {
            setTableQuestionDesc([""])
            setQuestion({
                ...question,
                [event.target.name]: event.target.value
            })
        }
        if (event.target.name == "type" && event.target.value == "Tabela" ||
            event.target.name == "type" && event.target.value == "Dissertativa" ||
            event.target.name == "type" && event.target.value == "Date" ||
            event.target.name == "type" && event.target.value == "Sim/Nao" ||
            event.target.name == "type" && event.target.value == "Telefone" ||
            event.target.name == "type" && event.target.value == "Cnpj" ||
            event.target.name == "type" && event.target.value == "Cpf" ||
            event.target.name == "type" && event.target.value == "Numero") {
            setQuestion({
                ...question,
                description: question.type === "Tabela" ? "" : question.description,
                [event.target.name]: event.target.value
            })
        } else {
            setQuestion({
                ...question,
                [event.target.name]: event.target.value
            })
        }


    }

    const handleTableQuestionChange = (e: any, index: number) => {
        const newTableQuestionDesc = tableQuestionDesc
        newTableQuestionDesc[index] = e.target.value

        setTableQuestionDesc(newTableQuestionDesc)

        setQuestion({
            ...question,
            description: newTableQuestionDesc.join('#$#')
        })
    }

    const removeTableQuestionRow = (index: number) => {
        const newTableQuestionDesc = tableQuestionDesc
        newTableQuestionDesc.splice(index, 1)

        setTableQuestionDesc(newTableQuestionDesc)

        setQuestion({
            ...question,
            description: newTableQuestionDesc.join('#$#')
        })
    }

    useEffect(() => {
        if (question?.type === "Tabela") {
            setTableQuestionDesc(question?.description?.split("#$#"))
        }
    }, [])

    const register = () => {
        if (question.name === "" || question.description === "" || question.type === "") {
            alert.setMessage("Preencha todos os campos da pergunta!")
            alert.setShow(true)
            setTimeout(() => {
                alert.setShow(false)
            }, 3175)
            return
        } else {
            let answersToSend: any = []

            if (question.type !== "Dissertativa" && question.type !== "Date" && question.type !== "Sim/Nao" && question?.type !== "Telefone" && question?.type !== "Cnpj" && question?.type !== "Cpf" && question?.type !== "Numero") answersToSend = answers.length !== 0 ? answers : [{ name: "", description: "" }]

            const answerFilter = answersToSend.filter((answer: any) => answer.name === "" || answer.description === "")
            if (answerFilter.length !== 0) {
                alert.setMessage("Preencha todos os campos de todas as respostas!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
                return

            } else {

                evaluationService.createQuestion(question).then(async (res) => {

                    if (res.statusCode == 200) {
                        if (question.type == "Sim/Nao") {

                            createYesOrNoAnswer(res.data.id)
                        } else if (question.type == "Dissertativa" || question.type == "Date") {
                        }
                        else if (answers.length > 0) {
                            answers.map(async (answer: any) => {
                                delete answer.id
                                answer.question.id = res.data.id
                                await evaluationService.createAnswer(answer)
                            })
                        }
                        alert.setMessage("Questão cadastrada com sucesso!")
                        alert.setShow(true)
                        alert.setConfirm(true)
                        setTimeout(() => {
                            alert.setShow(false)
                            alert.setConfirm(false)
                        }, 3175)
                        reload()
                        close()
                    } else {
                        alert.setMessage("Ocorreu um erro ao cadastrar a questão!")
                        alert.setShow(true)
                        setTimeout(() => {
                            alert.setShow(false)
                        }, 3175)
                    }
                })
            }
        }
    }

    const edit = () => {

        if (question.name === "" || question.description === "" || question.type === "") {
            alert.setMessage("Preencha todos os campos da pergunta!")
            alert.setShow(true)
            setTimeout(() => {
                alert.setShow(false)
            }, 3175)
            return
        } else {
            let answersToSend: any = []
            if (question.type !== "Dissertativa" && question.type !== "Date" && question?.type !== "Telefone" && question?.type !== "Cnpj" && question?.type !== "Cpf" && question?.type !== "Numero") answersToSend = answers.length !== 0 ? answers : [{ name: "", description: "" }]

            const answerFilter = answersToSend.filter((answer: any) => answer.name === "" || answer.description === "")

            if (answerFilter.length !== 0) {
                alert.setMessage("Preencha todos os campos de todas as respostas!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
                return

            } else {
                evaluationService.editQuestion(questionId, question).then(async (res) => {
                    if (question.type == "Dissertativa" || question.type == "Date" || question.type == "Sim/Nao") {
                        await answers.map((answer: any) => {
                            if (answer.id !== "") {
                                evaluationService.deleteAnswer(answer.id)
                            }
                        })
                    }
                    if (question.type == "Sim/Nao") {
                        createYesOrNoAnswer(questionId)
                    } else {
                        if (answers.length > 0) {
                            answers.map(async (answer: any) => {
                                if (answer.id) await
                                    evaluationService.editAnswer(answer.id, answer)
                                else {
                                    delete answer.id
                                    answer.question.id = questionId
                                    await evaluationService.createAnswer(answer).then((res) => {
                                    })
                                }
                            })
                        }
                    }
                    alert.setMessage("Questão editada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    reload()
                    close()
                })
            }
        }
    }

    const setAnswersEdit = async () => {
        await evaluationService.getAnswersByQuestionId(questionId).then((res: any) => {
            setAnswers(res || [])
        })
    }

    const createYesOrNoAnswer = async (id: string | undefined) => {
        setAnswers([])
        await evaluationService.createAnswer({
            sequenceIndex: 0,
            name: "Sim",
            description: "Sim",
            weight: 1,
            question: {
                id: id
            }
        })
        await evaluationService.createAnswer({
            sequenceIndex: 1,
            name: "Não",
            description: "Não",
            weight: 0,
            question: {
                id: id
            }
        })
    }

    useEffect(() => {
        if (editQuestion) {
            // editQuestion:Question={
            //     name: editQuestion.name,
            //     description: editQuestion.description,
            //     weight: editQuestion.weight,
            //     type: editQuestion.type,
            //     hasDependency: editQuestion.hasDependency,
            //     isWanted: editQuestion.isWanted,
            //     sequenceIndex: editQuestion.sequenceIndex,
            //     [relation]: {
            //         id: id
            //     }
            // }
            setQuestion(editQuestion)
            setAnswersEdit()

            if (editQuestion.type === 'Tabela')
                setTableQuestionDesc(editQuestion.description.split("#$#"))
        }
    }, [editQuestion])

    return (
        <>
            <div className="create-question-modal-container" >
                <div className="create-question-modal" >
                    <div className="create-question-header">
                        <p>{questionId ? "Editar questão" : "Cadastrar questão"}</p>
                        <span className="close-button" onClick={close}>
                            <img src={icons.closeModal} alt="close-modal" />
                        </span>
                    </div>
                    <div className="create-question-body">
                        <div className="create-question-input">
                            <p>Título: </p>
                            <textarea defaultValue={question.name} disabled={parentQuestion} name="name" onChange={(event) => handleChange(event)} />
                        </div>
                        {
                            question?.type !== "Tabela" ? (
                                <div className="create-question-input">
                                    <p>Enunciado: </p>
                                    <input defaultValue={question.description} name="description" onChange={(event) => handleChange(event)} type="text" />
                                </div>
                            ) : null
                        }

                        <div className="create-question-body-row">
                            {
                                question?.type == 'Sim/Nao' && journeyStep == '1' ? (
                                    <div className="create-question-input-row">
                                        <p>Peso: </p>
                                        <input value={question.weight} name="weight" onChange={(event) => handleWeight(event)} type="number" />
                                    </div>
                                ) : null
                            }

                            <div className="create-question-input-row">
                                <p>Tipo: </p>
                                <select onChange={(event) => handleChange(event)} name="type" >
                                    <option value="">Tipo de pergunta</option>
                                    <option selected={question?.type == "Dissertativa" ? true : false} value="Dissertativa">Dissertativa</option>
                                    <option selected={question?.type == "Alternativa" ? true : false} value="Alternativa">Alternativa</option>
                                    {parentQuestion?.type !== "Tabela" && journeyStep == '1' ? <option selected={question?.type == "Tabela" ? true : false} value="Tabela">Tabela</option> : null}
                                    <option selected={question?.type == "Date" ? true : false} value="Date">Data</option>
                                    <option selected={question?.type == "Múltipla Escolha" ? true : false} value="Múltipla Escolha">Múltipla Escolha</option>
                                    <option selected={question?.type == "Sim/Nao" ? true : false} value="Sim/Nao">Sim/Não</option>
                                    <option selected={question?.type === "Telefone" ? true : false} value="Telefone">Telefone</option>
                                    <option selected={question?.type === "Cnpj" ? true : false} value="Cnpj">CNPJ</option>
                                    <option selected={question?.type === "Cpf" ? true : false} value="Cpf">CPF</option>
                                    <option selected={question?.type === "Numero" ? true : false} value="Numero">Número</option>

                                </select>
                            </div>
                            {
                                question?.type !== 'Tabela' && parentQuestion?.type !== "Tabela" ? (
                                    <div className="create-question-input-row">
                                        <p>Dependente: </p>
                                        <select onChange={(event) => handleChange(event)} name="hasDependency" >
                                            <option selected={question.hasDependency ? false : true} value="">Sem dependencia</option>
                                            {
                                                questions.map((questionOption: Question) => {
                                                    return <option selected={question.hasDependency === questionOption.id} value={questionOption.id}>{questionOption.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                ) : null
                            }

                            {
                                question?.type !== 'Tabela' ? (
                                    <div className="create-question-input-row switch">
                                        <p>Opcional:</p>
                                        <label className="switch">
                                            <input name="isWanted" checked={!question.isWanted} onChange={() => handleWanted()} type="checkbox" />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                ) : null
                            }

                        </div>
                        {question?.type == "Tabela" ? (
                            <>
                                <div className="create-table-container">
                                    {/* Main */}
                                    <div className="table-main-container" >
                                        {/* Header Columns */}
                                        <div className="table-columns-container">
                                            <div className="columns-lines"></div>
                                            {answers?.map((answer: any, index: number) => {
                                                return (
                                                    <div className="create-table-column" >
                                                        <input onChange={(e) => handleAnswer(e, index)} name="name" type="text" placeholder={(index + 1) + "º Coluna"} defaultValue={answer.name} key={answer.id} />
                                                    </div>
                                                )
                                            })}
                                            {answers.length > 0 ? <div className="table-columns-action" onClick={() => deleteAnswer(answers.length - 1, answers[answers.length - 1].id)}>-</div> : null}
                                            <div className="table-columns-action" onClick={addAnswer}>+</div>
                                        </div>
                                        {
                                            tableQuestionDesc.map(
                                                (questionDesc: any, index: number) =>

                                                    <div className="create-table-column row-description">
                                                        <input value={questionDesc} onChange={(e) => handleTableQuestionChange(e, index)} name="description" className="evaluations-form-statement " type="text" placeholder={`${index + 1}º Linha`} />
                                                    </div>


                                            )
                                        }

                                        <div>
                                            <div style={{ cursor: "pointer" }} onClick={() => setTableQuestionDesc([...tableQuestionDesc, ""])}>+</div>
                                            {tableQuestionDesc.length > 0 && <div style={{ cursor: "pointer" }} onClick={() => removeTableQuestionRow(tableQuestionDesc.length - 1)}>-</div>}
                                        </div>

                                    </div>
                                </div>
                            </>
                        ) :
                            question?.type !== "Dissertativa" && question?.type !== "Date" && question?.type !== "Sim/Nao" && question?.type !== "Telefone" && question?.type !== "Cnpj" && question?.type !== "Cpf" && question?.type !== "Numero" ? (
                                <div className="create-question-answer-container">
                                    <div className="create-question-answer-header">
                                        <p className="create-question-answer-title">Respostas</p>
                                        <button onClick={() => addAnswer()} className="create-question-answer-button">Adicionar resposta</button>
                                    </div>

                                    <div className="answers-container">
                                        {answers.sort((a: any, b: any) => a.sequenceIndex > b.sequenceIndex ? 1 : -1).map((answer: any, index: number, arr) => {
                                            return (
                                                <Answer upNeighbor={arr[index - 1]} downNeighbor={arr[index + 1]} handleRamification={handleRamification}
                                                    sessions={sessions}
                                                    enableRamification={question.type == 'Sim/Nao' || question.type == 'Alternativa'}
                                                    question={question} questions={questions} answerId={answer.id} answer={answer} index={index} handleAnswer={handleAnswer} deleteAnswer={deleteAnswer} reload={setAnswersEdit} />
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : null
                        }

                    </div>
                    <div className="create-question-footer">
                        <button className="button-base-style" onClick={() => action === "REGISTER" ? register() : window.confirm("Tem certeza que deseja editar está questão?") ? edit() : null}>{editQuestion ? "Editar" : "Salvar"}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateQuestionModal
