import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signOut } from '../store/slices/user';

const NotFoundPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(signOut());

        localStorage.removeItem("token")
    }, [dispatch]);

    return (
        <>
            <div className='not-found-container'>
                <div className='not-found-box'>
                    <h3>Ops...</h3>
                   
                    <p>Parece que a página que você procura não foi encontrada.</p>
                   
                    <div className='not-found-return'>
                        <p>
                            <Link to={'/'}>
                                Retornar
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFoundPage;