import React, { ChangeEvent, useEffect, useState } from "react";
import { IUnity } from "../../interfaces/user";
import { useAppSelector } from "../../store";

import userService from '../../services/consultant'
import specialtyService from '../../services/specialty'
import { ISpeciality } from "../../interfaces/speciality";
import { UserQueryFilter } from ".";
import FilterItem from "../utils/Filter/FilterItem";
import FilterSearch from "../utils/Filter/FilterSearch";
import FilterBody from "../utils/Filter/FilterBody";

interface IFilterProps {
    selectedFilters: UserQueryFilter
    setSelectedFilters: React.Dispatch<React.SetStateAction<UserQueryFilter>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
}

const typeUserFilter = [{
    label: "Todos",
    value: ""
}, {
    label: "Coordenadores",
    value: "1"
}, {
    label: "Consultores",
    value: "2"
}]

const UserFilter: React.FC<IFilterProps> = ({ selectedFilters, setSelectedFilters, setCurrentPage }) => {
    const { user } = useAppSelector(state => state.user);

    const [unityOptions, setUnityOptions] = useState<IUnity[]>([])
    const [specialtyOptions, setSpecialtyOptions] = useState<ISpeciality[]>([])

    const [filterSearch, setFilterSearch] = useState({
        unities: '',
        specialties: ''
    })

    const [visibleItems, setVisibleItems] = useState({
        unities: false,
        specialties: false
    })

    const toggleVisibility = (key: keyof typeof visibleItems) => {
        setVisibleItems({
            ...visibleItems,
            [key]: !visibleItems[key]
        })
    }

    const handleSearchChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setFilterSearch({
            ...filterSearch,
            [target.name]: target.value
        })
    }

    const getFilters = async () => {
        if (user.userDetails?.role == 1) {
            setSpecialtyOptions(await specialtyService.getSpecialties(filterSearch.specialties));
            setUnityOptions(user.userDetails.unities!);
            return;
        } else {
            const unityRes = await userService.getUnityByName(filterSearch.unities)
            setUnityOptions(unityRes.result)
        }
    }

    const handleSelection = (filterId: string, key: 'unities' | 'specialties') => {
        setCurrentPage(1)
        setSelectedFilters({
            ...selectedFilters,
            [key]: (selectedFilters[key].includes(filterId)) ?
                selectedFilters[key].filter((f) => f != filterId)
                : [...selectedFilters[key], filterId]
        })
    }

    const clearCheckboxFilters = (key: keyof typeof selectedFilters) => {
        setSelectedFilters({
            ...selectedFilters,
            [key]: []
        })
    }

    const clearAllFilters = () => {
        setSelectedFilters({
            ...selectedFilters,
            search: '',
            specialties: [],
            unities: []
        })
    }

    const filterSearchOption = (key: "name" | "area", filterArray: any, inputValue: string) => {

        const filterLabel = filterArray.filter((item: any) => item[key] !== null && item[key]?.toLowerCase().includes(inputValue?.toLowerCase()))

        return filterLabel.map((filter: any) => {
            return {
                label: filter[key],
                value: filter.id
            }
        })
    }

    useEffect(() => {
        getFilters()

    }, [filterSearch])

    return (
        <div className="filter-container">
            <div className="filter-title">
                <span>Filtrar</span>
                <div className="filter-clear-all" onClick={clearAllFilters}>
                    <span>limpar</span>
                    <img src="/assets/icons/eraser.png" alt="" />
                </div>
            </div>
            <div className="filter-main">

                <FilterItem title="Usuários" numberOfSelectedFilters={selectedFilters.search.length} lockedOpen>
                    <FilterSearch value={selectedFilters.search} type="text" onChange={(event: any) => setSelectedFilters({ ...selectedFilters, search: event.target.value })} placeholder="Digite o nome ou NIF do usuário" />
                </FilterItem>

                {
                    (user.userDetails?.role == 0 || (user.userDetails?.role == 1 && user.userDetails?.unities.length > 1)) &&


                    <FilterItem title="Unidades" numberOfSelectedFilters={selectedFilters.unities.length} showNumber>
                        <FilterSearch value={filterSearch.unities} name="unities" type="text" onChange={handleSearchChange} placeholder="Pesquisar unidades" />
                        <FilterBody typeInput="checkbox" onClear={() => clearCheckboxFilters('unities')}
                            filterOptionsItem={filterSearchOption("name", unityOptions, filterSearch.unities)}
                            selectedFilterItems={selectedFilters.unities} onSelect={(value) => { handleSelection(value!, 'unities') }} />
                    </FilterItem>

                }
                {
                    user.userDetails?.role == 0 &&
                    <FilterItem title="Função" numberOfSelectedFilters={selectedFilters.typeUser.length}>
                        <FilterBody typeInput="radio" filterOptionsItem={typeUserFilter} selectedFilterItems={[selectedFilters.typeUser]} onSelect={(value) => setSelectedFilters({ ...selectedFilters, typeUser: value })} onClear={() => setSelectedFilters({ ...selectedFilters, typeUser: "" })} />
                    </FilterItem>
                }


                {
                    user.userDetails?.role == 1 &&
                    <FilterItem title="Especialidades" numberOfSelectedFilters={selectedFilters.specialties.length} showNumber>
                        <FilterSearch value={filterSearch.specialties} name="specialties" type="text" onChange={handleSearchChange} placeholder="Pesquisar unidades" />
                        <FilterBody typeInput="checkbox" onClear={() => clearCheckboxFilters('specialties')} filterOptionsItem={filterSearchOption("name", specialtyOptions, filterSearch.specialties)} selectedFilterItems={selectedFilters.specialties} onSelect={(value) => { handleSelection(value!, 'specialties') }} />
                    </FilterItem>
                }

            </div>
        </div>
    )
}

export default UserFilter