import React, { useEffect, useState } from "react";
import answerService from "../../../../services/answer"
export interface IAnswer {
    answer: {
        sequenceIndex: number,
        name: string
        description: string,
        weight: number,
        ramification: string
    },
    answerId: string,
    question: Question,
    questionId?: string,
    questions: [],
    index: number,
    enableRamification?: boolean
    handleAnswer: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number) => void
    handleRamification: (value: string, index: number) => void
    deleteAnswer: (index: number, id?: string) => void

    reload: () => void
    sessions: [] | undefined

    upNeighbor: any
    downNeighbor: any
}
interface Session {
    createdAt: string,
    criterion: [],
    hasQuestions: any,
    id: string,
    name: string,
    questions: Question[],
    updateAt: string
}
export interface Question {
    answers?: [],
    createAt?: string,
    description: string,
    hasDependency: string,
    id?: string,
    isRamified?: any,
    isWanted: boolean,
    name: string,
    observations?: [],
    sequenceIndex: Number,
    type: string,
    updateAt?: string,
    weight: number
}

const Answer = ({ answer, upNeighbor, downNeighbor, index, handleAnswer, deleteAnswer, answerId, reload, question, questions, enableRamification, sessions, handleRamification }: IAnswer) => {
    const [ramification, setRamification] = useState(false)

    useEffect(() => {
        if (answer.ramification !== "")
            setRamification(true)
    }, [answer.ramification])

    return (
        <div className="answer-container">
            <div className="answer-top-container">

                {/* <div className="answer-top-name">
                    <p>Título:</p>
                    <input value={answer.name} onChange={(event) => handleAnswer(event, index)} name="name" type="text" />
                </div> */}

            </div>
            <div className="answer-bottom-container">
                <div className="answer-bottom-order-container">
                    <div className="answer-top-number">
                        <p>{answer.sequenceIndex}.</p>
                    </div>
                    <div>
                        {upNeighbor && <img className="answer-bottom-image up" src="/assets/icons/right-arrow-gray.png" alt="" onClick={() => answerService.moveAnswer(answerId, upNeighbor.id).then(() => reload())} />}
                        {downNeighbor && <img className="answer-bottom-image down" src="/assets/icons/right-arrow-gray.png" alt="" onClick={() => answerService.moveAnswer(answerId, downNeighbor.id).then(() => reload())} />}
                    </div>
                </div>
                <div className="answer-bottom-description">
                    {enableRamification ? (
                        <ol className="wtree">
                            <li>
                                <span style={{ display: 'flex', flexDirection: 'row', gap: '2%' }}>
                                    <div style={{ width: "50%", display: 'flex', flexDirection: 'column', alignItems: 'baseline', fontSize: "clamp(4px, 4vw, 12px)" }}>
                                        <p> Alternativa:</p>
                                        <input style={{ height: "3vh", fontSize: "clamp(4px, 4vw, 10px)" }} value={answer.description} onChange={(event) => handleAnswer(event, index)} name="description" type="text" />
                                    </div>
                                    <div className="answer-top-weight" style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                        <p style={{ fontSize: "clamp(4px, 4vw, 12px)" }}>Peso:</p>
                                        <input style={{ height: "3vh", fontSize: "clamp(4px, 4vw, 10px)" }} value={answer.weight} onChange={(event) => handleAnswer(event, index)} name="weight" type="number" />
                                    </div>
                                    <div className="answer-ramification-container">
                                        <div onClick={() => deleteAnswer(index, answerId)} className="answer-top-delete">
                                            <img style={{ width: "30%" }} src="/assets/icons/trash-red.png" alt="" width={32} />
                                        </div>
                                        <div style={{ fontSize: "clamp(4px, 4vw, 10px)", cursor: "pointer", width: "80%" }} onClick={() => {
                                            if (ramification)
                                                handleRamification("", index)
                                            setRamification(!ramification)
                                        }}>
                                            {ramification ? "-" : "+"} Ramificação
                                        </div>
                                    </div>

                                </span>
                                {
                                    ramification ? (
                                        <ol>
                                            <li>
                                                <span>
                                                    <p style={{ fontSize: "clamp(4px, 4vw, 12px)" }}>Ramificar para: </p>
                                                    <select style={{ fontSize: "clamp(4px, 4vw, 10px)" }} onChange={(event) => handleAnswer(event, index)} name="ramification" >
                                                        {
                                                            sessions?.map((session: Session) => {
                                                                if (!session.questions.includes(question))
                                                                    return <option selected={session.id === answer.ramification} value={session.id}>{session.name}</option>

                                                            })
                                                        }
                                                    </select>
                                                </span>
                                            </li>
                                        </ol>
                                    ) : null
                                }

                            </li>
                        </ol>
                    ) : (
                        <span style={{ display: 'flex', flexDirection: 'row', gap: '2%', marginBottom: '1%' }}>
                            <div style={{ width: "50%", display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                <p> Alternativa:</p>
                                <input style={{ height: "3vh" }} value={answer.description} onChange={(event) => handleAnswer(event, index)} name="description" type="text" />
                            </div>
                            <div className="answer-top-weight" style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                <p>Peso:</p>
                                <input style={{ height: "3vh" }} value={answer.weight} onChange={(event) => handleAnswer(event, index)} name="weight" type="number" />
                            </div>
                            <div onClick={() => deleteAnswer(index, answerId)} className="answer-top-delete">
                                <img src="/assets/icons/trash-red.png" alt="" width={32} />
                            </div>

                        </span>
                    )}


                </div>
            </div>
        </div>
    )
}

export default Answer