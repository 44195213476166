import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { applyCnpjMask } from '../../utils/masks';
import { getLogoJtdRed, getLogoSenai, getStepJorney2CivilConstruction, getStepJorney3CivilConstruction, getStepJorney4CivilConstruction } from './ImagesStepsJorneyPDF';


const systemScale = window.devicePixelRatio;

const scaleCalcImage = (scale) => {
	if (scale < 1) return 0.9
	else if (scale === 1) return 0.7
	else if (scale >= 1 || scale <= 1.5) return 0.5
	else if (scale > 1.5) return 0.2

	return 1
}


export async function CivilConstructionGeneratePDF({ diagnostic, hubs, chatsToBase64, stateLocation, companyContext }) {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const [stepJorney2, stepJorney3, stepJorney4, logoJtdRed, logoSenai] = await Promise.all(
		[getStepJorney2CivilConstruction(), getStepJorney3CivilConstruction(), getStepJorney4CivilConstruction(), getLogoJtdRed(), getLogoSenai()]
	);

	const getStep = (hub) => {

		if (hub.length > 0) {

			const hub1 = hub[0].result * 100 / 3;
			const hub2 = hub[1].result * 100 / 3;
			const hub3 = hub[2].result * 100 / 3;

			if (hub1 < 60) {
				return stepJorney2;
			} else if (hub1 >= 60 && hub2 < 60) {
				return stepJorney2;
			} else if (hub1 >= 60 && hub2 >= 60 && hub3 < 80) {
				return stepJorney3
			} else {
				return stepJorney4
			}
		}
	}

	const imageBase64Array = Array.prototype.slice.call(chatsToBase64);

	const selectedDateTime = diagnostic.updatedAt || "-";

	function toMonthName(monthNumber) {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString('pt-BR', {
			month: 'long',
		});
	}

	var splitarray = new Array();
	splitarray = selectedDateTime.split("-");

	const setProposalDateAndUf = (stateLocation, splitarray) => {
		return stateLocation + ", " + splitarray[2]?.substring(0, 2) + " de " + toMonthName(splitarray[1]) + " de " + splitarray[0]
	}

	const estrategia = 0;
	const marketing = 1;
	const processos = 2;
	const goDigial = 3;

	var geral = [
		[[{ text: "", marginLeft: 15 }],
		[
			{
				text: `Avaliação `,
				alignment: 'center',
				fontSize: 12,
				marginTop: 50,
				marginLeft: 10
			},
			{
				text: `${(parseFloat(diagnostic.grade.constructionResult.total) * 100 / 12).toFixed(1)}% `,
				bold: true,
				alignment: 'center',
				fontSize: 14,
				marginLeft: 10
			},
		]

			, [{ text: "", marginLeft: 35 }], [
			{
				image: imageBase64Array[0].toDataURL(),
				width: imageBase64Array[0].width * scaleCalcImage(systemScale) * 0.75,
				height: imageBase64Array[0].height * scaleCalcImage(systemScale) * 0.75,
				alignment: 'center',
				marginBottom: 5
			}
		],
		[{ text: "", marginLeft: 15 }],
		[
			{
				text: 'Legenda:',
				margin: [20, 24, 0, 0],
				bold: true,
				fontSize: 10,
			},
			hubs.map((hub, i) => ({
				text: `${i + 1}. ${hub.name} : ${(hub.result * 100 / 3).toFixed(1)}%`,
				margin: [20, 6, 0, 0],
				fontSize: 10,
			}))
		]
		]
	];

	var grid1 = [
		[[{ text: "", marginLeft: 30 }], [
			{
				text: `1 - ${hubs[estrategia].name}`,
				fontSize: 12,
				bold: true,
				marginBottom: 5,
				marginLeft: 10,
			},
			{
				image: imageBase64Array[estrategia + 1].toDataURL(),
				width: imageBase64Array[estrategia + 1].width * scaleCalcImage(systemScale) * 0.7,
				height: imageBase64Array[estrategia + 1].height * scaleCalcImage(systemScale) * 0.7,
				alignment: 'center'
			},

			hubs[estrategia].questions.map((hub, i) => ({
				text: `1.${i + 1}. ${hub.name} : ${hub.answers[0].result.toFixed(2)}`,
				margin: [10, 6, 0, 0],
				fontSize: 8,
			})),
			{
				text: '',
				marginBottom: 25,
			},
		], [{ text: "", marginLeft: 60 }],
		[
			{
				text: `2 - ${hubs[marketing].name}`,
				fontSize: 12,
				bold: true,
				marginBottom: 5,
				marginLeft: 10,
			},
			{
				image: imageBase64Array[marketing + 1].toDataURL(),
				width: imageBase64Array[marketing + 1].width * scaleCalcImage(systemScale) * 0.7,
				height: imageBase64Array[marketing + 1].height * scaleCalcImage(systemScale) * 0.7,
				alignment: 'center'
			},

			hubs[marketing].questions.map((hub, i) => ({
				text: `2.${i + 1}. ${hub.name} : ${hub.answers[0].result.toFixed(2)}`,
				margin: [10, 6, 0, 0],
				fontSize: 8,
			})),
			{
				text: '',
				marginBottom: 25,
			},
		]
			,],

	];

	var grid2 = [
		[[{ text: "", marginLeft: 30 }],
		[
			{
				text: `3 - ${hubs[processos].name}`,
				fontSize: 12,
				bold: true,
				marginBottom: 5,
				marginLeft: 10,
			},
			{
				alignment: 'center',
				image: imageBase64Array[processos + 1].toDataURL(),
				width: imageBase64Array[processos + 1].width * scaleCalcImage(systemScale) * 0.7,
				height: imageBase64Array[processos + 1].height * scaleCalcImage(systemScale) * 0.7,
				alignment: 'center'
			},

			hubs[processos].questions.map((hub, i) => ({
				text: `3.${i + 1}. ${hub.name} : ${hub.answers[0].result.toFixed(2)}`,
				margin: [10, 6, 0, 0],
				fontSize: 8,
			})),
			{
				text: '',
				marginBottom: 0,
			},
		], [{ text: "", marginLeft: 60 }], [
			{
				text: `4 - ${hubs[goDigial].name}`,
				fontSize: 12,
				bold: true,
				marginBottom: 5,
				marginLeft: 10,
			},
			{
				alignment: 'center',
				image: imageBase64Array[goDigial + 1].toDataURL(),
				width: imageBase64Array[goDigial + 1].width * scaleCalcImage(systemScale) * 0.7,
				height: imageBase64Array[goDigial + 1].height * scaleCalcImage(systemScale) * 0.7,
				alignment: 'center'
			},

			hubs[goDigial].questions.map((hub, i) => ({
				text: `4.${i + 1}. ${hub.name} : ${hub.answers[0].result.toFixed(2)}`,
				margin: [10, 6, 0, 0],
				fontSize: 8,
			})),
			{
				text: '',
				marginBottom: 0,
			},
		],],

	];

	const content = [
		{
			image: logoJtdRed.image,
			width: 150,
			alignment: 'center',
			marginBottom: 20
		},
		{
			text: setProposalDateAndUf(stateLocation, splitarray),
			fontSize: 12,
			color: 'black',
			alignment: 'center',
			marginBottom: 20
		},
		{
			image: logoSenai.image,
			width: 120,
			marginBottom: 20,
			alignment: 'center'
		},
		{
			text: 'AVALIAÇÃO DIAGNÓSTICA - CONSTRUÇÃO CIVIL',
			style: ['header', 'marginDefault'],
			marginBottom: 20
		},
		{
			text: companyContext?.name,
			style: ['textAlignedCenter'],
		},
		{
			text: `CNPJ: ${applyCnpjMask(companyContext?.CNPJ)}`,
			style: ['textAlignedCenter'],
			marginBottom: 20
		},
		{
			text: 'Etapa da Jornada SENAI-SP de Transformação Digital\n que a Empresa se encontra nesse momento:',
			style: ['textAlignedCenter'],
			marginBottom: 20
		},
		{
			text: getStep(hubs).text,
			style: ['textAlignedCenter'],
			fontSize: 14,
			color: '#9a1915'
		},
		{
			image: getStep(hubs).image,
			width: 400,
			marginTop: 24,
			alignment: 'center',
			marginBottom: 20
		},
		{
			text: 'Plano de Ações Recomendadas',
			bold: true,
			fontSize: 12,
			margin: [24, 0, 0, 5]
		},
		{
			ul: diagnostic?.plans?.map((plan) => ({
				text: plan.name,
				bold: false,
				margin: [36, 0, 0, 5],
				fontSize: 12,
			}))
		},
		{
			text: '',
			pageBreak: 'after'
		},
		{
			text: 'DESEMPENHO ATUAL DA EMPRESA',
			style: ['text', 'marginDefault'],
			bold: true,
			marginBottom: 10,
			fontSize: 12,
		},
		{ layout: 'lightHorizontalLines', table: { headerRows: 1, body: geral, alignment: 'center' } },
		{ layout: 'lightHorizontalLines', table: { headerRows: 1, body: grid1, alignment: 'center' } },
		{ layout: 'lightHorizontalLines', table: { headerRows: 1, body: grid2, alignment: 'center' } },

	]

	const docDefinitions = {
		pageSize: 'A4',
		content: [content],
		footer(currentPage, pageCount) {
			if (currentPage > 1) {
				return {
					layout: 'noBorders',
					margin: [14, 0, 14, 22],
					table: {
						widths: ['auto'],
						body: [
							[
								{
									text:
										'_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
									alignment: 'center',
									fontSize: 5,
								},
							],
							[
								[
									{
										text: `Página ${currentPage.toString()} de ${pageCount}`,
										fontSize: 7,
										alignment: 'right',
										margin: [3, 0],
									},
									{
										text: '© Jornada de Transformação Digital',
										fontSize: 7,
										alignment: 'center',
									},
								],
							],
						],
					},
				};
			}
		},
		styles: {
			header: {
				bold: true,
				alignment: 'center',
				fontSize: 16,
			},
			textAlignedCenter: {
				alignment: 'center',
				fontSize: 12,
			},
			spacingDefault: {
				marginTop: 15,
			},
			text: {
				fontSize: 12,
			},
			marginDefault: {
				margin: 10
			}
		}
	}

	pdfMake.createPdf(docDefinitions).download(`CC-${diagnostic?.proposal_number}-${companyContext?.name.replace(/ +/g, "_")}`);

}
