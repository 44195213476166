import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import evaluationService from "../../../services/evaluation";
import HeaderHelmetComponent from "../../Layout/HeaderHelmet";
import AlertMessage from "../../utils/Alert";
import CreateEvaluationModal from "../modals/CreateEvaluationModal";
import EvaluationTableEvaluationLine from "./EvaluationTableEvaluationLine";
import "react-activity/dist/library.css";
import { Squares } from "react-activity";
import { useDispatch } from "react-redux";
import company, { companySlice, removeCompany, storeCompany } from "../../../store/slices/company";
import { removeDiagnostic } from "../../../store/slices/diagnostic";
import { useAppSelector } from "../../../store";

const stepOptions = [
    'Etapa 1 - Diagnóstico',
    'Etapa 2 - Estratégia',
    'Etapa 3 - Otimização de Processos',
    'Etapa 4 - Mapeamento',
    'Etapa 5 - Automação',
    'Etapa 6 - Digitalização',
    'Etapa 7 - Integração',
    'Etapa 8 - Indústria Inteligente'
]


const EvaluationTable = () => {
    const [journeyStep, setJouneyStep] = useState(1)
    const [evaluations, setEvaluation] = useState<any[]>([])
    const [alertShow, setAlertShow] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertConfirm, setAlertConfirm] = useState(false)
    const [currentPlatform, setCurrentPlatform] = useState("WEB")
    const [currentModal, setCurrentModal] = useState<any>();
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState<Boolean>(true);

    // const { company } = useAppSelector(state => state.company);

    const dispatch = useDispatch();

    const [currentEvaluationMenu, setCurrentEvaluationMenu] = useState("")


    const getEvaluations = () => {
        setLoading(true)
        evaluationService.getEvaluation(journeyStep).then((res) => {
            setEvaluation(res.result)
            setLoading(false)
        })
    }


    const handleJourneyStepChange = (e: any) => {
        setJouneyStep(e.target.value)
    }

    const modals = {
        none: <></>,
        register: <CreateEvaluationModal
            currentPlatform={currentPlatform}
            alert={{ setConfirm: setAlertConfirm, setMessage: setAlertMessage, setShow: setAlertShow }}
            journeyStep={`${journeyStep}`} reload={getEvaluations} action="REGISTER" type="EVALUATION" name={value || ""} close={() => setCurrentModal(modals.none)} />
    }

    useEffect(() => {
        getEvaluations()
    }, [journeyStep])

    useEffect(() => {

        dispatch(storeCompany({
            companyContext: {}
        }));

    }, [])

    return (
        <>
            <HeaderHelmetComponent nestedPage="Avaliações" />
            <div className="journey-container">
                <div className="journey-card">
                    <div className="table-header">
                        <h1>Avaliações</h1>
                        <div className="header-options-container">
                            <select value={journeyStep} name="journey-step" className='jorney-step-select' onChange={handleJourneyStepChange}>
                                {
                                    stepOptions.map((step, index) =>
                                        <option value={`${index + 1}`}>{step}</option>
                                    )
                                }
                            </select>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span style={{ fontSize: "clamp(0.4rem, .8vw, 1.2rem)" }} >WEB</span>
                                <label className="switch" style={{ margin: 8 }}>
                                    <input type="checkbox" checked={currentPlatform === 'MOBILE' ? true : false} onClick={() => setCurrentPlatform(currentPlatform === 'WEB' ? 'MOBILE' : 'WEB')} />
                                    <span className="slider round"></span>
                                </label>
                                <span style={{ fontSize: "clamp(0.4rem, .8vw, 1.2rem)" }} >MOBILE</span>
                            </div>
                            <button disabled className="evaluation-page-button" onClick={() => setCurrentModal(modals.register)}>Adicionar Avaliação</button>
                        </div>
                    </div>

                    <div className="evaluation-table">
                        <table >
                            <tr className="evaluation-table-header">
                                <th>Categoria</th>
                                <th>Título</th>
                            </tr>

                            {loading ? (<tr> <Squares color="#9a1915" size={24} speed={1} animating={true} />
                            </tr>) :
                                evaluations?.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? -1 : 1).filter(((evaluation: any) => evaluation.platform == currentPlatform)).map((evaluation: any, i) =>
                                    <EvaluationTableEvaluationLine isOdd={i % 2 === 0}
                                        currentPlatform={currentPlatform}
                                        alert={{ setConfirm: setAlertConfirm, setMessage: setAlertMessage, setShow: setAlertShow }} reload={getEvaluations} evaluation={evaluation} currentMenu={currentEvaluationMenu} setCurrentMenu={setCurrentEvaluationMenu} />
                                )}

                        </table>


                    </div>
                </div>
            </div>
            {
                currentModal
            }
            <AlertMessage confirm={alertConfirm} visible={alertShow} bgColor={"#F4F6F6"} message={alertMessage} />
        </>

    )

}

export default EvaluationTable
