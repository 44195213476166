import ColumnChart from './BaseColumn/ColumnChart';

type DigitalTechnologiesChartProps = {
    evaluationResult: number | null;
    columns: string[];
};

export default function DigitalTechnologiesChart({ evaluationResult, columns }: DigitalTechnologiesChartProps) {
    let indexOfSelectedColumn = -1;
    //Calculating the result using evaluationResult number
    if(evaluationResult !== null){
        indexOfSelectedColumn = evaluationResult < 1 ? 0 : evaluationResult < 1.7 ? 1 : evaluationResult <= 2.3 ? 2 : 3;
    }

    return (
        <ColumnChart
            columns={columns}
            indexOfSelectedColumn={indexOfSelectedColumn}
        />
    );
}
