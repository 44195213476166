import { api } from ".";

const getAllConsultants = async (page, keyword) => {
    try {
        let key = "&keyword=" + keyword || "";
        return api
            .get(`/Users?page=${page}&keySearch=name${key}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    } catch (error) {
        console.log(error);
    }
};

const getUsersWithFilter = async (filter, sortData, pagination) => {
    const page = `&page=${pagination.page}&limit=${pagination.limit}`;
    const formatedFilter = Object.entries(filter)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");
    const formatedSort =
        sortData && sortData.type !== "NONE"
            ? `&sort=${sortData.key}:${sortData.type}`
            : "";
    return await api.get(`/Users/new?${formatedFilter}${formatedSort}${page}`);
};

const getUsersByUnity = async (unity) => {
    try {
        return await api
            .get(`/Users`)
            .then((res) =>
                res.data.data.filter(
                    (user) => user.unity.name.replace(".", "") === unity
                )
            )
            .catch((err) => console.error(err));
    } catch (err) {
        console.error(err);
    }
};

const sendFirstLoginUser = async (userEmail, userName) => {
    const userDetails = {
        email: userEmail,
        name: userName,
    };

    await api
        .post(`/Users/first-login-email`, userDetails)
        .then(async (response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
};

const recoverUserPassword = async (userEmail) => {
    const email = {
        email: userEmail,
    };

    return await api
        .post(`/Users/get-id-by-email`, email)
        .then(async (res) => {
            return await api
                .get(`/Users/${res.data}`)
                .then(async (res) => {
                    return await api
                        .post(
                            `/Users/recover-password?name=${res.data.data.name}&email=${userEmail}&idUser=${res.data.data.id}`
                        )
                        .then(async (res) => {
                            return res;
                        });
                })
                .catch((err) => {
                    return err;
                });
        })
        .catch((err) => {
            return err;
        });
};

const getUnityByName = async (name) => {
    try {
        return await api
            .get(`/Unities?page=1&keySearch=name&keyword=${name}`)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllUnities = async (page) => {
    try {
        return await api
            .get(`/Unities`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    } catch (error) {
        console.log(error);
    }
};

const createUnity = async (unity) => {
    try {
        return await api
            .post(`/Unities`, unity)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {}
};

const createUser = async (user) => {
    try {
        return await api.post(`/Users`, user).then((response) => {
            return response.data;
        });
    } catch (e) {
        console.log(e);
    }
};

const getEmployesAll = async () => {
    return await api.get(`/Employes`);
};

const getUserByName = async (name, page) => {
    try {
        return await api
            .get(`/Users?page=${page | 1}&keySearch=name&keyword=${name}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    } catch (error) {
        console.log(error);
    }
};

const getSpecialties = async () => {
    try {
        return await api.get(`/Specialties`).then((response) => {
            return response.data.data;
        });
    } catch (err) {
        console.log(err);
    }
};

const updateUser = async (userId, data) => {
    try {
        return await api.put(`/Users/${userId}`, data).then((response) => {
            return response.data;
        });
    } catch (err) {
        console.log(err);
    }
};

const getUserByIdWithMail = async (userId) => {
    try {
        return await api
            .get(`/Users/${userId}?withMail=true`)
            .then((response) => {
                return response.data.data;
            });
    } catch (err) {
        console.log(err);
    }
};

const methodsToExport = {
    getAllConsultants,
    createUser,
    getAllUnities,
    getEmployesAll,
    getSpecialties,
    getUserByName,
    recoverUserPassword,
    createUnity,
    getUnityByName,
    sendFirstLoginUser,
    getUsersByUnity,
    getUsersWithFilter,
    updateUser,
    getUserByIdWithMail,
};

export default methodsToExport;
