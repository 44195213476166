import { api } from "."
import { ISpeciality } from "../interfaces/speciality";


const createSpecialty = async (specialty: ISpeciality) => {
    try {
        return await api.post(
            `/Specialties`,
            specialty
        ).then((res: any) => res.data)
    } catch (e) {
        console.error(e);
    }
}

const removeSpecialty = async (id: string) => {
    try {
        return await api.delete(
            `/Specialties/${id}`
        ).then((res: any) => res.data)
    } catch (error) {
        return error
    }
}

const editSpecialty = async (specialty: ISpeciality, id: string) => {
    try {

        return await api.put(
            `/Specialties/${id}`,
            specialty
        ).then((res: any) => res.data)
    } catch (error) {
        return error
    }
}

const getSpecialties = async (search?: string) => {
    try {
        const _endpoint = `/${((search ?? '').trim().length > 0) ? `Specialties?page=1&keySearch=name&keyword=${search}` : 'Specialties'}`
        const res = await api.get(_endpoint)
        console.log(res)
        if ((search ?? '').trim().length > 0) {
            return res.data.data.result
        } else {
            return res.data.data
        }
    } catch (err) {
        console.log(err);
    }
};


const specialtyService = {
    createSpecialty,
    removeSpecialty,
    editSpecialty,
    getSpecialties
}

export default specialtyService;