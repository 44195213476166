import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import SideBarComponent from "./Bars/Side";
import HeaderHelmetComponent from "./HeaderHelmet";
import NotFoundPage from "../../pages/NotFound";

type LayoutWrapperProps = {
    children: ReactNode;
}

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
    const location = useLocation();

    return (
        <>
            <HeaderHelmetComponent nestedPage="" />

            {
                location.pathname === "/" || location.pathname === "/enterprise-token" || location.pathname === "/password-recovery" ?
                    children
                    :
                    location.key === 'default' ?
                        <NotFoundPage />
                        : (
                            <SideBarComponent>
                                {children}
                            </SideBarComponent>
                        )
            }
        </>
    );
};

export default LayoutWrapper;