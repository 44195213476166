import { kStringMaxLength } from "buffer";
import React, { createRef, useEffect, useRef, useState } from "react";
import AlertMessage from "../utils/Alert";
import { createTypeReferenceDirectiveResolutionCache } from "typescript";
import axios from "axios";
import jwt from "jwt-decode"
import authService from "../../services/auth"
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { api } from "../../services";


const EnterpriseToken = () => {

    const navigate = useNavigate()

    const companyId: any = localStorage.getItem("companyId")

    let companyIdDecoded: any = jwt(companyId)

    const [resendClass, setResendClass] = useState("enterprise-token-content-resend-token")
    const [alertShow, setAlertShow] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [confirmAlert, setConfirmAlert] = useState(true)

    const inputFocus = useRef([
        createRef<HTMLInputElement>(),
        createRef<HTMLInputElement>(),
        createRef<HTMLInputElement>(),
        createRef<HTMLInputElement>(),
        createRef<HTMLInputElement>(),
        createRef<HTMLInputElement>()])

    const getCompany = async (token: string) => {

        const companyLogin = localStorage.getItem("companyLogin")
        let tokenCompany: any

        await api.get(API_URL + "/Companies/" + companyIdDecoded.id).then(async (data: any) => {

            if (data.data.data.token == token) {

                setConfirmAlert(true)
                setAlertMessage("Token validado com sucesso!")
                setAlertShow(true)
                await setTimeout(() => {
                    setAlertShow(false)
                }, 3175)



                navigate(`/password-recovery?id=${companyId}&type=company`)

            } else {

                setConfirmAlert(false)
                setAlertMessage("O token informado está errado!")
                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)
                }, 3175)
            }

        })

    }

    const createToken = () => {
        const token = tokenInput.token1 + tokenInput.token2 + tokenInput.token3 + tokenInput.token4 + tokenInput.token5 + tokenInput.token6

        getCompany(token)
    }

    const [tokenInput, setTokenInput] = useState({
        token1: "",
        token2: "",
        token3: "",
        token4: "",
        token5: "",
        token6: ""
    })



    const handleTokenInput = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {

        const text = numberMask(event.target.value)

        setTokenInput({
            ...tokenInput,
            [event.target.name]: text
        })

        if (index + 1 <= 5 && text != "") {
            inputFocus.current[index + 1].current?.focus()
        }
    }

    const resendToken = () => {

        setConfirmAlert(true)
        setResendClass("none")

        setAlertMessage("Token reenviado com sucesso!")
        setAlertShow(true)
        setTimeout(() => {
            setAlertShow(false)
        }, 3175)
    }

    const numberMask = (text: string) => {

        const textMask = parseInt(text)

        if (isNaN(textMask)) {
            return ""
        }
        return text
    }

    return (
        <div className="enterprise-token-page">
            <div className="enterprise-token-header">
                <div className="enterprise-token-header-image">
                    <img src="/assets/images/logo.png" alt="" />
                </div>
            </div>
            <div className="enterprise-token-content">
                <div className="enterprise-token-content-container">
                    <p>Insira o token para acessar seu perfil</p>
                    <div className="enterprise-token-content-input-container">
                        <input autoFocus ref={inputFocus.current[0]} maxLength={1} name={"token1"} value={tokenInput.token1} onChange={(e) => handleTokenInput(e, 0)} className="enterprise-token-content-input" type="text" />
                        <input maxLength={1} ref={inputFocus.current[1]} name={"token2"} value={tokenInput.token2} onChange={(e) => handleTokenInput(e, 1)} className="enterprise-token-content-input" type="text" />
                        <input maxLength={1} ref={inputFocus.current[2]} name={"token3"} value={tokenInput.token3} onChange={(e) => handleTokenInput(e, 2)} className="enterprise-token-content-input" type="text" />
                        <input maxLength={1} ref={inputFocus.current[3]} name={"token4"} value={tokenInput.token4} onChange={(e) => handleTokenInput(e, 3)} className="enterprise-token-content-input" type="text" />
                        <input maxLength={1} ref={inputFocus.current[4]} name={"token5"} value={tokenInput.token5} onChange={(e) => handleTokenInput(e, 4)} className="enterprise-token-content-input" type="text" />
                        <input maxLength={1} ref={inputFocus.current[5]} name={"token6"} value={tokenInput.token6} onChange={(e) => handleTokenInput(e, 5)} className="enterprise-token-content-input" type="text" />
                    </div>
                    <button onClick={() => createToken()} className="enterprise-token-content-button" >Confirmar token</button>
                </div>
            </div>
            <AlertMessage confirm={confirmAlert} visible={alertShow} bgColor={"#F4F6F6"} message={alertMessage} />
        </div>
    )

}

export default EnterpriseToken