import { useState } from 'react';

import ProposalsComponent from '../../components/Proposals';
import EnergyEfficiencyComponent from '../../components/EnergyEfficiency';
import AlertMessage from '../../components/utils/Alert';

import { useAppSelector } from '../../store';
interface IProposalPage {
  isReviewed: boolean
  isEnergyEfficiency: boolean
}

const ProposalsPage = (props: IProposalPage) => {
  const [alertShow, setAlertShow] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertConfirm, setAlertConfirm] = useState(false);

  const arrayAlert = {
    setAlertShow,
    setAlertMessage,
    setAlertConfirm
  };

  const { diagnostic } = useAppSelector(state => state.diagnostic);

  return (
    <>
      {props.isEnergyEfficiency ? 
        <>
          {diagnostic && <EnergyEfficiencyComponent isReviewed={props.isReviewed} arrayAlert={arrayAlert} proposalId={diagnostic?.proposal_id}  />}
        </>:
        <>
          {diagnostic && <ProposalsComponent isReviewed={props.isReviewed} arrayAlert={arrayAlert} proposalId={diagnostic?.proposal_id}  />}
        </>
      }

      <AlertMessage confirm={alertConfirm} visible={alertShow} bgColor={"#F4F6F6"} message={alertMessage} />
    </>
  );
};

export default ProposalsPage;