import { api } from ".";


const getAllCompanies = async (page) => {
    try {
        return await api
            .get(`/Companies/get-diagnostics`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const getAllCompaniesv2 = async () => {
    try {
        return await api
            .get(`/v2/Companies`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const getCompaniesTotal = async () => {
    try {
        return await api
            .get(`/Companies?page=1&take=1&keySearch=CNPJ`)
            .then((res) => res.data.data);
    } catch (err) {
        console.error(err);
    }
};

const getCompany = async (id) => {
    try {
        return await api.get(`/Companies/${id}`).then((res) => res.data.data);
    } catch (err) {
        console.error(err);
    }
};

const getAllCompaniesWithDiagnostics = async () => {
    try {
        return await api
            .get(`/Companies?page=1&keySearch=hasDiagnostic&keyword=true`)
            .then((res) => res.data.data);
    } catch (err) {
        console.error(err);
    }
};

const getAllCities = async () => {
    try {
        return await api
            .get(`/Cities`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const getAllSectors = async () => {
    try {
        return await api
            .get(`/Sectors`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const getCompanyDetails = async (companyCNPJ) => {
    try {
        return await api
            .get(
                `/Companies?page=1&take=1&keySearch=CNPJ&keyword=${companyCNPJ}`
            )
            .then((res) => {
                const { result } = res.data.data;
                return {
                    cnpj: result[0].CNPJ,
                    area: result[0].area,
                    name: result[0].name,
                    city: result[0].city,
                    contacts: result[0].contacts,
                };
            })
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const getCompanyDiagnostic = async (companyId) => {
    try {
        return await api
            .get(`/Companies/${companyId}`)
            .then((res) => res.data.data)
            .catch((err) => console.log(err));
    } catch (error) {
        console.log(error);
    }
};

const addsCompanyContact = async (companyContact) => {};

const addsCompany = async (company) => {};

const getCompanyByParam = async (value, param, page) => {
    try {
        return await api
            .get(
                `/Companies?page=${page || 1}&take=28&keySearch=${
                    param || "name"
                }&keyword=${value}`
            )
            .then((res) => res.data)
            .catch((err) => console.log(err));
    } catch (err) {
        console.log(err);
    }
};

const recoverCompanyPassword = async (companyCNPJ) => {
    try {
        return await api
            .get(
                `/Companies?page=1&take=28&keySearch=CNPJ&keyword=${companyCNPJ}`
            )
            .then(async (res) => {
                return await api
                    .post(`/Companies/recover-password`, {
                        companyContacts: res.data.data.result[0].contacts,
                        id: res.data.data.result[0].id,
                    })
                    .then((res) => res)
                    .catch((err) => err);
            })
            .catch((err) => err);
    } catch (err) {
        return err;
    }
};

const getDiagnosticsByCompanyId = async (companyId) => {
    try {
        return await api
            .get(`/Companies/${companyId}/diagnostics`)
            .then(async (res) => res.data);
    } catch (err) {
        return err;
    }
};

const methodsToExport = {
    getAllCompanies,
    getAllCities,
    getAllSectors,
    getCompanyByParam,
    getCompanyDetails,
    addsCompanyContact,
    addsCompany,
    recoverCompanyPassword,
    getDiagnosticsByCompanyId,
    getAllCompaniesWithDiagnostics,
    getCompaniesTotal,
    getAllCompaniesv2,
    getCompanyDiagnostic,
    getCompany
};

export default methodsToExport;
