import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { ICompanyFilter } from "../../store/slices/companyFilter";
import CompaniesService from "../../services/companies";
import { INewEnterpriseFilter } from ".";
import { useAppSelector } from "../../store";
import { usersPermissionsGeneralFilters } from "../../utils/utils";
import FilterItem from "../utils/Filter/FilterItem";
import FilterSearch from "../utils/Filter/FilterSearch";
import FilterBody from "../utils/Filter/FilterBody";

interface IFilterProps {
    filterOptions: INewEnterpriseFilter,
    setSelectedFilters: (companyFilter: ICompanyFilter) => void
    selectedFilters: ICompanyFilter
    setIsLoading: Dispatch<SetStateAction<Boolean>>
    getCompaniesByFilters: Function
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}
interface IHandleInputSearch {
    key: "cities" | "unities" | "consultants" | "tech_products" | "companies" | "status" | "stepsJorney"
    name: "city" | "unity" | "name" | "specific_title" | "stepsJorney"
}

const stateInitialSearchFilters = {
    unities: [],
    cities: [],
    tech_products: [],
    consultants: [],
    companies: "",
    hasDiagnostic: false,
    has_registry_report: false,
    stepsJorney: []
}


const CompanyFilterComponent: React.FC<IFilterProps> = (
    { filterOptions, selectedFilters, setSelectedFilters, setIsLoading, getCompaniesByFilters, setCurrentPage }) => {

    const [statusIsVisible, setStatusIsVisible] = useState(false)

    const [searchFilters, setSearchFilters] = useState<ICompanyFilter>(stateInitialSearchFilters)

    const { user } = useAppSelector(state => state.user);
    const unitiesArray: any = user.userDetails?.unities?.map((u: any) => u.name);

    const [companyInput, setCompanyInput] = useState("")
    const [consultantsInput, setConsultantsInput] = useState("")
    const [citiesInput, setCitiesInput] = useState("")
    const [unitiesInput, setUnitiesInput] = useState("")
    const [techProductsInput, setTechProductsInput] = useState("")
    const [stepsJorneyInput, setStepsJorneyInput] = useState("")

    const clearAllFilters = () => {
        setIsLoading(true)
        setSearchFilters(stateInitialSearchFilters)
        setSelectedFilters(stateInitialSearchFilters)
        setCompanyInput("")
        setConsultantsInput("")
        setCitiesInput("")
        setUnitiesInput("")
        setTechProductsInput("")
        setIsLoading(false)
    }

    const clearFilter = async (
        key: | "cities" | "unities" | "consultants" | "tech_products" | "companies" | "status" | "stepsJorney",
        setInput: (value: React.SetStateAction<string>) => void,
        search: string[]) => {
        setIsLoading(true)
        setInput("")
        setSearchFilters({
            ...searchFilters,
            [key]: search
        })
        setSelectedFilters({
            ...selectedFilters,
            [key]: []
        })
        setIsLoading(false)
    }

    const handleSelection = async (filterType: "cities" | "unities" | "consultants" | "tech_products" | "companies" | "status" | "stepsJorney", filter: string) => {
        setCurrentPage(1)
        if (filterType !== "companies" && filterType !== "status") {
            setIsLoading(true);

            if (!Object.keys(selectedFilters).includes(filterType))
                return console.log(`ERROR: Filter type "${filterType}" does not exist in enterprise filters!\nFilterTypes : ${Object.keys(selectedFilters)}`);

            const newSelectedFilters = { ...selectedFilters };


            if (selectedFilters[filterType].includes(filter))
                newSelectedFilters[filterType] = selectedFilters[filterType].filter((_filter: any) => _filter !== filter)
            else
                newSelectedFilters[filterType] = [...selectedFilters[filterType], filter]

            setSelectedFilters(newSelectedFilters);
            setIsLoading(false);
        }
    }

    const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>, setInputSearch: React.Dispatch<React.SetStateAction<string>>, search: IHandleInputSearch) => {
        if (search.key !== "companies" && search.key !== "status") {
            setInputSearch(event.target.value)

            setSearchFilters({
                ...searchFilters,
                [search.key]: filterOptions[search.key]?.filter((obj: any) => obj[search.name]?.toLowerCase().includes(event.target.value.toLocaleLowerCase()))
            })
        }
    }

    const handleCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFilters({ ...selectedFilters, companies: event.target.value })

    }

    const filterSearch = (key: "city" | "unity" | "name" | "specific_title" | "step", filterArray: string[], inputValue: string) => {

        const filterLabel = filterArray.filter((item: any) => item[key] !== null && item[key]?.toLowerCase().includes(inputValue?.toLowerCase()))
        
        return filterLabel.map((filter: any) => {
            return {
                label: filter[key],
                value: filter[key],
                description: filter?.description
            }
        })
    }

    const changeCompanyInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyInput(event.target.value)
        handleCompany(event)
        setCurrentPage(1)
    }

    useEffect(() => {
        getCompaniesByFilters()
        setSearchFilters({
            unities: filterOptions.unities,
            cities: filterOptions.cities,
            tech_products: filterOptions.tech_products,
            consultants: filterOptions.consultants,
            companies: filterOptions.companies,
            hasDiagnostic: filterOptions.hasDiagnostic,
            has_registry_report: filterOptions.has_registry_report,
            stepsJorney: filterOptions.stepsJorney
        })
    }, [selectedFilters])

    return (
        <div className="filter-container">
            <div className="filter-title">
                <span>Filtrar</span>
                <div className="filter-clear-all" onClick={() => clearAllFilters()}>
                    <span>limpar</span>
                    <img src="/assets/icons/eraser.png" alt="" />
                </div>
            </div>
            <div className="filter-main">

                <FilterItem lockedOpen title={"Empresas"} numberOfSelectedFilters={selectedFilters.companies.length} >
                    <FilterSearch value={selectedFilters.companies} onChange={(event: any) => { changeCompanyInput(event) }} placeholder="Digite o nome da empresa" />
                </FilterItem>

                <FilterItem showNumber title={"Consultores"} numberOfSelectedFilters={selectedFilters.consultants.length}  >
                    <FilterSearch value={consultantsInput} onChange={(event: any) => handleInputSearch(event, setConsultantsInput, { key: "consultants", name: "name" })} placeholder={"Digite o nome do consultor"} />
                    <FilterBody typeInput="checkbox" filterOptionsItem={filterSearch("name", filterOptions.consultants, consultantsInput)} onClear={() => clearFilter("consultants", setConsultantsInput, filterOptions.consultants)} onSelect={(value: string) => handleSelection("consultants", value)} selectedFilterItems={selectedFilters.consultants} />
                </FilterItem>

                <FilterItem showNumber numberOfSelectedFilters={selectedFilters.cities.length} title="Cidades" >
                    <FilterSearch value={citiesInput} onChange={(event: any) => handleInputSearch(event, setCitiesInput, { key: "cities", name: "city" })} placeholder={"Digite o nome da cidade"} />
                    <FilterBody typeInput="checkbox" filterOptionsItem={filterSearch("city", filterOptions.cities, citiesInput)} onClear={() => clearFilter("cities", setCitiesInput, filterOptions.cities)} onSelect={(value: string) => handleSelection("cities", value)} selectedFilterItems={selectedFilters.cities} />
                </FilterItem>

                <FilterItem showNumber title={"Produtos tecnológicos"} numberOfSelectedFilters={selectedFilters.tech_products.length}>
                    <FilterSearch value={techProductsInput} onChange={(event: any) => handleInputSearch(event, setTechProductsInput, { key: "tech_products", name: "specific_title" })} placeholder={"Digite o nome do produto"} />
                    <FilterBody typeInput="checkbox" filterOptionsItem={filterSearch("specific_title", filterOptions.tech_products, techProductsInput)} onClear={() => clearFilter("tech_products", setTechProductsInput, filterOptions.tech_products)} onSelect={(value: string) => handleSelection("tech_products", value)} selectedFilterItems={selectedFilters.tech_products} />
                </FilterItem>

                <FilterItem showNumber title={"Etapa da Jornada"} numberOfSelectedFilters={selectedFilters.stepsJorney.length}>
                    <FilterBody 
                        typeInput="checkbox" 
                        filterOptionsItem={filterSearch("step", filterOptions.stepsJorney, stepsJorneyInput)} 
                        onClear={() => clearFilter("stepsJorney", setTechProductsInput, filterOptions.stepsJorney)} 
                        onSelect={(value: string) => handleSelection("stepsJorney", value)} 
                        selectedFilterItems={selectedFilters.stepsJorney} />
                </FilterItem>

                {usersPermissionsGeneralFilters(user.userDetails?.role) &&
                    <FilterItem showNumber title="Unidades" numberOfSelectedFilters={selectedFilters.unities.length} >
                        <FilterSearch value={unitiesInput} onChange={(event: any) => handleInputSearch(event, setUnitiesInput, { key: "unities", name: "unity" })} placeholder={"Digite o nome da unidade"} />
                        <FilterBody typeInput="checkbox" filterOptionsItem={filterSearch("unity", filterOptions.unities, unitiesInput)} onClear={() => clearFilter("unities", setUnitiesInput, filterOptions.unities)} onSelect={(value: string) => handleSelection("unities", value)} selectedFilterItems={selectedFilters.unities} />
                    </FilterItem>
                }


                <FilterItem title="Status" numberOfSelectedFilters={selectedFilters.hasDiagnostic || selectedFilters.has_registry_report ? 1 : 0} >
                    <div className="filter-item" onClick={() => {
                        setSelectedFilters({ ...selectedFilters, hasDiagnostic: !selectedFilters.hasDiagnostic })
                        setCurrentPage(1)
                    }}>
                        <input type={"checkbox"} checked={selectedFilters.hasDiagnostic} />
                        <label>Diagnóstico Realizado</label>
                    </div>
                    <div className="filter-item" onClick={() => {
                        setSelectedFilters({ ...selectedFilters, has_registry_report: !selectedFilters.has_registry_report })
                        setCurrentPage(1)
                    }}>
                        <input type={"checkbox"} checked={selectedFilters.has_registry_report} />
                        <label>Registro de conclusão</label>
                    </div>
                </FilterItem>

                <div className={`filter-main-item-body enterprise filter-checkbox ${statusIsVisible ? "filter-item-body-shown" : "filter-item-body-hidden"}`}>

                </div>


            </div>
        </div>
    )
}

export default CompanyFilterComponent