type ColumnChartProps = {
    columns: string[];
    indexOfSelectedColumn: number;
};

export default function ColumnChart({ columns, indexOfSelectedColumn }: ColumnChartProps) {
    return (
        <div className="container-bars">
            {columns.map((column, index) => (
                <div className="container-bar-and-text">
                    <div className="container-bar-title">
                        <span
                            style={{ top: 0 }}
                            className={`${index == indexOfSelectedColumn && 'selected-column-text'}`}
                        >
                            {column}
                        </span>
                    </div>
                    <div
                        style={{ height: (80 / columns.length) * (index + 1) + '%' }}
                        className={`bar ${index == indexOfSelectedColumn && 'selected-column-bar'}`}
                    />
                </div>
            ))}
        </div>
    );
}
