import { api } from ".";

const path = "/RegistryReport";

export const create = async (registryReport: any): Promise<any> => {
	return await api.post(path, registryReport);
}

export const getFilters = async () => {
	return await api.get(`/RegistryReport/filters`);
}

export const getAllByFilter = async (name: string) => {
	return await api.get(`${path}/name?name=${name}`);
}
