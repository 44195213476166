import React from "react";

interface IDateInputComponent {
    questionNumber: number,
    enunciated: string,
    isWanted: boolean,
    textValue: any,
    handleAnswer: (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string) => void,
    sectionId: string
    disabled: boolean
}

const DateInputComponent = ({ questionNumber, enunciated, isWanted, handleAnswer, textValue, sectionId, disabled }: IDateInputComponent) => {
    return (

        <div className="registry-report-body-question-dissertative">
            <p>{questionNumber}. {enunciated} <span style={{ color: "#9a1915" }}>{isWanted ? "*" : ""}</span></p>
            <input value={textValue} onChange={(event) => handleAnswer(event, questionNumber - 1, sectionId)} type="date" disabled={disabled} required={isWanted ? true : false }/>
        </div>
    )
}

export default DateInputComponent