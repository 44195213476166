import { Helmet } from 'react-helmet'

interface IHeaderHelmet {
    nestedPage: string
}

const HeaderHelmetComponent = ({ nestedPage }: IHeaderHelmet) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>SGJD | {nestedPage}</title>
            <link rel="canonical" href="https://jornadadigital.sp.senai.br/" />
        </Helmet>
    )
}

export default HeaderHelmetComponent