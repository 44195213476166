import React, { useState } from "react";
interface IFilterItemProps extends React.PropsWithChildren {
    title: string
    numberOfSelectedFilters: number
    showNumber?: boolean
    lockedOpen?: boolean
}

const FilterItem = ({ title, numberOfSelectedFilters, children, showNumber, lockedOpen }: IFilterItemProps) => {

    const [isVisible, setIsVisible] = useState(lockedOpen ? true : false)

    return (
        <>
            <div className="filter-main-item-title" onClick={() => !lockedOpen && setIsVisible(!isVisible)}>
                {!lockedOpen ? isVisible ? <span>-</span> : <span>+</span> : null}
                <span>{title}</span>
                {
                    numberOfSelectedFilters > 0 ?
                        showNumber ?
                            <div>{numberOfSelectedFilters}</div>
                            :
                            <div className="filter-circle" />
                        : null
                }
            </div>
            {
                isVisible && children
            }
        </>
    )
}

export default FilterItem