import React from "react";

interface IConfirmationModalProps {
  negativeAction? : () => void
  positiveAction? : () => void
}

const ConfirmationModalComponent:React.FC<IConfirmationModalProps> = ({ negativeAction, positiveAction }) => {
  return(
    <>
      <div  className="confirmation-modal-container">
          <div className="confirmation-modal">
              <div className="confirmation-modal-header">
                <span>Você está de acordo com o diagnóstico?</span>
              </div>
              <div className="confirmation-modal-footer">
                <button type="button" onClick={ positiveAction }>Sim</button>
                <button type="button" onClick={ negativeAction }>Não</button>
              </div>
          </div>
      </div>
    </>
  )
}

export default ConfirmationModalComponent;