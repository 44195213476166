import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { applyCnpjMask } from '../../utils/masks';
import { getLogoJtdRed, getLogoSenai, getStepJorney3, getStepJorney4, getStepJorney5And6, getStepJorney6, getStepJorney6And7 } from './ImagesStepsJorneyPDF';


const systemScale = window.devicePixelRatio;

const scaleCalcImage = (scale) => {
	if (scale < 1) return 0.9
	else if (scale === 1) return 0.7
	else if (scale >= 1 || scale <= 1.5) return 0.5
	else if (scale > 1.5) return 0.2

	return 1
}

export async function DiagnosticEvaluationGeneratePDF({ diagnosticContext, hubs, quizData, companyContext, currentStep, nextStep, imagesBase64 }) {
	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const [stepJorney3, stepJorney4, stepJorney5And6, stepJorney6, stepJorney6And7, logoJtdRed, logoSenai] = await Promise.all(
		[getStepJorney3(), getStepJorney4(), getStepJorney5And6(), getStepJorney6(), getStepJorney6And7(), getLogoJtdRed(), getLogoSenai()]
	);

	let indexChartCount = -1;

	const imageBase64Array = Array.prototype.slice.call(imagesBase64)

	hubs.forEach((hub) => {
		hub.criterion.forEach((criteria) => {
			criteria.questions.forEach((question) => {
				Object.assign(question)
			})
		})
	})

	const selectedDateTime = companyContext.diagnostics[0].updatedAt || "-"

	function toMonthName(monthNumber) {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString('pt-BR', {
			month: 'long',
		});
	}

	var splitarray = new Array();
	splitarray = selectedDateTime.split("-");

	const stateLocation = companyContext.uf

	const setProposalDateAndUf = (stateLocation, splitarray) => {
		return stateLocation + ", " + splitarray[2]?.substring(0, 2) + " de " + toMonthName(splitarray[1]) + " de " + splitarray[0]
	}

	const getStep = (currentStep, nextStep) => {
		if (currentStep == "3" && nextStep == "") return stepJorney3
		else if (currentStep == "4" && nextStep == "") return stepJorney4
		else if (currentStep == "6" && nextStep == "") return stepJorney6
		else if (currentStep == "5" && nextStep == "6") return stepJorney5And6
		else return stepJorney6And7
	}

	const content = [
		{
			image: logoJtdRed.image,
			width: 150,
			alignment: 'center',
			marginBottom: 20
		},
		{
			text: setProposalDateAndUf(stateLocation, splitarray),
			fontSize: 12,
			color: 'black',
			alignment: 'center',
			marginBottom: 20
		},
		{
			image: logoSenai.image,
			width: 120,
			marginBottom: 20,
			alignment: 'center'
		},
		{
			text: 'AVALIAÇÃO DIAGNÓSTICA SENAI SP',
			style: ['header', 'marginDefault'],
			marginBottom: 20
		},
		{
			text: companyContext?.name,
			style: ['textAlignedCenter'],
		},
		{
			text: `CNPJ: ${applyCnpjMask(companyContext?.CNPJ)}`,
			style: ['textAlignedCenter'],
			marginBottom: 20
		},
		{
			text: 'Etapa da Jornada SENAI-SP de Transformação Digital\n que a Empresa se encontra nesse momento:',
			style: ['textAlignedCenter'],
			marginBottom: 20
		},
		{
			text: getStep(currentStep, nextStep).text,
			style: ['textAlignedCenter'],
			fontSize: 14
		},
		{
			image: getStep(currentStep, nextStep).image,
			width: 400,
			marginTop: 24,
			alignment: 'center',
			marginBottom: 20
		},
		{
			text: 'Plano de Ações Recomendadas',
			bold: true,
			fontSize: 12,
			margin: [24, 0, 0, 5]
		},
		{
			ul: diagnosticContext?.plans?.map((plan) => ({
				text: plan.name,
				bold: false,
				margin: [36, 0, 0, 5],
				fontSize: 12,
			}))
		},
		{
			text: '',
			pageBreak: 'after'
		},
		{
			text: 'DESEMPENHO ATUAL DA EMPRESA POR EIXO AVALIADO',
			style: ['text', 'marginDefault'],
			bold: true,
			marginBottom: 20,
			fontSize: 16
		},
		hubs.map((hub, index) => {
			return [
				{
					text: `${index + 1} - ${hub.name}`,
					fontSize: 14,
					bold: true,
					marginBottom: 20,
					marginLeft: 10
				},
				hub.criterion.map((criteria, criteriaIndex) => {
					indexChartCount += 1
					return quizData && quizData.find((quiz) => quiz.criteriaId == criteria.id) && (
						[
							{
								text: 'MACRO INDICADOR',
								marginLeft: 10,
								fontSize: 14,
								marginBottom: 16,
							},
							{
								text: `${criteriaIndex + 1}. ${criteria.name}`,
								margin: [20, 0, 0, 8],
								bold: true,
							},
							criteria.questions.filter((question) => question.type != 'Tabela')
								.map((question, questionIndex) => {

									return quizData && quizData.find((quiz) => quiz.data.questionName == question.name) && (
										[
											{
												text: `${criteriaIndex + 1}.${questionIndex + 1}. ${question.name}`,
												margin: [30, 5, 0, 0]
											},
											{
												text: question.description.match(/De acordo com a tabela|declarados na tabela acima/) ? '' : question.description,
												margin: [30, 5, 0, 0]
											},
											question.answers.map((answer) => {
												return quizData && quizData.find((quiz) => quiz.data.answerName == answer.name) && ({
													text: answer.description,
													margin: [35, 6, 0, 8],
													color: '#9A1915'
												})
											})
										]
									)
								}),
							{
								text: '',
								marginBottom: 20,
							},
							{
								image: imageBase64Array[indexChartCount].toDataURL(),
								width: imageBase64Array[indexChartCount].width * scaleCalcImage(systemScale),
								height: imageBase64Array[indexChartCount].height * scaleCalcImage(systemScale),
								alignment: 'center'
							},
							{
								text: 'Legenda:',
								margin: [100, 24, 0, 0],
								bold: true
							},
							criteria.questions.filter((question) => question.type != 'Tabela').map((question, questionIndex) => {
								return quizData && quizData.find((quiz) => quiz.data.questionName == question.name) && (
									{
										text: `${criteriaIndex + 1}.${questionIndex + 1}. ${question.name}`,
										margin: [110, 6, 0, 0],
										fontSize: 10,
									}
								)
							}),
							{
								text: '',
								marginBottom: 40,
							},
						]
					)
				}),
				{
					text: '',
					pageBreak: hubs.length === index + 1 ? 'none' : 'after',
				},
			]
		}),
	]

	const docDefinitions = {
		pageSize: 'A4',
		content: [content],
		footer(currentPage, pageCount) {
			if (currentPage > 1) {
				return {
					layout: 'noBorders',
					margin: [14, 0, 14, 22],
					table: {
						widths: ['auto'],
						body: [
							[
								{
									text:
										'_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
									alignment: 'center',
									fontSize: 5,
								},
							],
							[
								[
									{
										text: `Página ${currentPage.toString()} de ${pageCount}`,
										fontSize: 7,
										alignment: 'right',
										/* horizontal, vertical */
										margin: [3, 0],
									},
									{
										text: '© Jornada de Transformação Digital',
										fontSize: 7,
										alignment: 'center',
									},
								],
							],
						],
					},
				};
			}
		},
		styles: {
			header: {
				bold: true,
				alignment: 'center',
				fontSize: 16,
			},
			textAlignedCenter: {
				alignment: 'center',
				fontSize: 12,
			},
			spacingDefault: {
				marginTop: 15,
			},
			text: {
				fontSize: 12,
			},
			marginDefault: {
				margin: 10
			}
		}
	}

	// pdfMake.createPdf(docDefinitions).open({}, window.open('', '_blank'));
	pdfMake.createPdf(docDefinitions).download(`AD-${diagnosticContext?.proposal_number}-${companyContext?.name.replace(/ +/g, "_")}`);

}
