import React, { useEffect, useState } from "react";
import { Spinner } from "react-activity";

import consultantService from "../../../services/consultant"
import { useAppSelector } from "../../../store";

import "react-activity/dist/library.css";

interface CreateUserModalProps {
    onClose: any,
    reload?: any,
    alert?: {
        confirm: React.Dispatch<React.SetStateAction<boolean>>,
        show: React.Dispatch<React.SetStateAction<boolean>>,
        message: React.Dispatch<React.SetStateAction<string>>
    }
    userId?: string
    onDisable?: () => void
}

interface IinitialStateFormValues {
    name: string,
    email: string,
    role: number,
    nif: string,
    unityInfos: {
        name: string,
        description: string
    },
    unities: {
        id: string
    }[],
    userUnity: string,
    specialties: {
        id: string
    }[]
}

const initialStateFormValues: IinitialStateFormValues = {
    name: "",
    email: "",
    role: 2,
    nif: "",
    unityInfos: {
        name: "",
        description: ""
    },
    unities: [],
    userUnity: "",
    specialties: []
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({ onClose, reload, alert, userId, onDisable }) => {

    const { user } = useAppSelector(state => state.user);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employees, setEmployees] = useState<any>([]);
    const [unities, setUnities] = useState<any>([]);
    const [unitiesProfile, setUnitiesProfile] = useState<any>([]);
    const [specialties, setSpecialties] = useState<any>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedSpecialtyId, setSelectedSpecialtyId] = useState("");
    const [userIsActive, setUserIsActive] = useState(false);
    const [enableEdit, setEnableEdit] = useState(true);


    const [formValues, setFormValues] = useState<typeof initialStateFormValues>(initialStateFormValues);

    const getSpecialties = async () => {
        try {
            const res = await consultantService.getSpecialties();

            setSpecialties(res);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {

        if (enableEdit) {


            let employeeSelected: any = employees.find((employee: any) => employee.name == event.target.value);

            if (employeeSelected == undefined) {
                setFormValues(initialStateFormValues);
                return
            }

            let data = {
                ...formValues,
                email: employeeSelected.email?.toLowerCase(),
                name: employeeSelected.name?.toLowerCase(),
                nif: employeeSelected.nif,
                userUnity: employeeSelected.unity
            }

            let usersUnitys: any = user.userDetails?.unities;

            if (user?.userDetails?.role == 0) {
                data = { ...data, role: 1 }
            } else {
                data = {
                    ...data,
                    unities: usersUnitys,
                    role: 2
                }
            }

            setFormValues(data);
        }

    }

    const handleSpecialtySelected = (event: React.ChangeEvent<HTMLSelectElement>) => {

        setFormValues({
            ...formValues,
            specialties: [
                ...formValues.specialties,
                {
                    id: event.target.value
                }]
        });

        setSelectedSpecialtyId(event.target.value);

    }

    const handleUnitySelected = (e: React.ChangeEvent<HTMLSelectElement>) => {



        if (formValues.unities.filter((unity: any) => unity.id == e.target.value).length > 0) {
            return
        }

        let newFormValues = { ...formValues };

        newFormValues.unities.push({
            id: e.target.value
        })

        setFormValues(newFormValues);

    }

    const createUser = async (e: any) => {
        e.preventDefault();

        if (isLoading) return
        setIsLoading(true)

        let userRes = (await consultantService.getUserByName(formValues.name)).data.data.result[0];

        if (userRes) {

            if (userRes.unities.length == 0) {
                let userUpdateUnitys = {
                    ...userRes,
                    unities: formValues.unities
                }

                consultantService.updateUser(userRes.id, userUpdateUnitys).then((res: any) => {

                    if (res.statusCode === 200) {

                        alert?.message('Usuário editado com sucesso!')
                        alert?.confirm(true)
                        alert?.show(true);
                        setTimeout(() => {
                            alert?.show(false)
                        }, 3175)
                        onClose()
                    } else {
                        alert?.message('Não foi possível editar o usuário!')
                        alert?.confirm(false)
                        alert?.show(true);
                        setTimeout(() => {
                            alert?.show(false)
                        }, 3175)
                        onClose()
                    }
                })
            } else {
                let messageUsers = `O usuário já está cadastrado nas Unidades (${userRes?.unities?.map((u: any) => u.name).join(", ")})`;

                setIsLoading(false)
                alert?.message(messageUsers)
                alert?.confirm(false)
                alert?.show(true);
                setTimeout(() => {
                    alert?.show(false)
                }, 3175)
            }

            return
        };

        const unityRes = await consultantService.getUnityByName(formValues.unityInfos.name);

        let unityId = unityRes.result[0].id

        if (unityId == undefined) {
            console.log("Nenhuma unidade encontrada!");
            return
        }

        let userToBeCreated: any = {
            name: formValues.name,
            email: formValues.email,
            role: formValues.role,
            nif: formValues.nif,
            unities: formValues.unities
        }

        if (user.userDetails?.role != 0 && user.userDetails?.role != undefined) {
            userToBeCreated = {
                ...userToBeCreated,
                specialties: formValues.specialties
            }
        }

        setIsLoading(false)


        await consultantService.createUser(userToBeCreated).then((response) => {
            setSelectedEmployeeId('')
            setIsLoading(false)
            if (response.createdUser.statusCode === 200) {
                alert?.message('Usuário cadastrado com sucesso!')
                alert?.confirm(true)
                alert?.show(true);
                setTimeout(() => {
                    alert?.show(false)
                }, 3175)

                onClose()
                reload()

            } else {
                return null
            }

        })

    }

    const editUser = async (e: any) => {
        e.preventDefault();

        setIsLoading(true)
        let userToBeEdited: any = {
            ...formValues,
            id: userId,
        }

        consultantService.updateUser(userId, userToBeEdited).then((res: any) => {

            if (res.statusCode === 200) {

                alert?.message('Usuário editado com sucesso!')
                alert?.confirm(true)
                alert?.show(true);
                setTimeout(() => {
                    alert?.show(false)
                }, 3175)
                onClose()
            } else {
                alert?.message('Não foi possível editar o usuário!')
                alert?.confirm(false)
                alert?.show(true);
                setTimeout(() => {
                    alert?.show(false)
                }, 3175)
                onClose()
            }
        })

        setIsLoading(false)
    }

    const getEmployes = async () => {
        try {
            let res = await consultantService.getEmployesAll();
            setEmployees(res.data.data);
        } catch (error) {
            console.error(error)
        }
    }

    const getUnities = async () => {
        try {
            let res = await consultantService.getAllUnities()
            setUnities(res.data.data.sort((a: any, b: any) => a.name < b.name ? -1 : 1))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (user?.userDetails?.role == 1) {
            setFormValues({
                ...formValues,
                unities: user.userDetails?.unities,
                role: 2
            });
            setUnitiesProfile(user.userDetails?.unities)

        } else if (user?.userDetails?.role == 0) {
            setFormValues({
                ...formValues,
                role: 1
            });
        }

        getSpecialties();
    }, [user]);


    useEffect(() => {
        getEmployes();
        getUnities()
    }, [user]);

    useEffect(() => {
        consultantService.getUserByIdWithMail(userId).then((userToEdit: any) => {
            setFormValues({
                ...formValues,
                email: userToEdit?.email,
                nif: userToEdit?.nif,
                name: userToEdit?.name,
                role: userToEdit?.role,
                unities: userToEdit?.unities,
                specialties: userToEdit?.specialties
            })

            setEnableEdit(userToEdit?.role === 2 && user.userDetails?.role === 0 ? false : true)
            setUserIsActive(userToEdit?.isActive)
        })

    }, [userId])

    return (
        <>
            <div className="modal-wrapper" style={{ width: "100%" }}>
                <div className="modal-create-user-container">
                    <div className="modal-create-user-close-container">
                        <span className="close-button" onClick={onClose}>
                            <img src={`/assets/icons/close-modal.png`} alt="close-modal" />
                        </span>
                    </div>
                    <div className="modal-create-user-title">
                        {userId ? "Informações do usuário" : "Cadastro de usuário"}
                    </div>
                    <div className="modal-create-user-form">
                        <form onSubmit={userId ? editUser : createUser}>
                            <div className="modal-create-user-form-input">
                                <label htmlFor="employeeName">Nome:</label>

                                {
                                    !userId ?
                                        <>
                                            <input disabled={userId !== undefined} type={"text"} list="employee" id={"name"} name="employeeName" onChange={handleChange} defaultValue={userId ? formValues.name : selectedEmployeeId} />
                                            <datalist id="employee" >
                                                <option value={""} selected>Selecione um Funcionário</option>
                                                {
                                                    employees?.map((employee: any, index: number) => {
                                                        return (
                                                            <option value={employee.name} key={index}>{employee.nif} - {employee.name}</option>
                                                        )
                                                    })
                                                }
                                            </datalist>
                                        </>
                                        :
                                        <p className="modal-create-user-name">{formValues.name}</p>
                                }
                            </div>
                            <div className="modal-create-user-form-input">
                                <label>NIF:</label>
                                <code>{formValues.nif}</code>

                            </div>
                            <div className="modal-create-user-form-input">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="employee_name"
                                    value={formValues.email}
                                    disabled={!enableEdit}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })} />
                            </div>
                            {
                                !userId &&
                                <div className="modal-create-user-form-input">
                                    <label>Unidade de origem:</label>
                                    <code>{formValues.userUnity}</code>
                                </div>
                            }

                            {
                                user.userDetails?.role === 0 &&
                                <div className="modal-create-user-form-input">
                                    <label>Função:</label>
                                    <select onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setFormValues({ ...formValues, role: parseInt(event.target.value) })} defaultValue={"0"}  >

                                        <option selected={formValues.role === 1} value="1">Coordenador</option>
                                        <option selected={formValues.role === 2} value="2">Consultor</option>


                                    </select>
                                </div>
                            }

                            <div className="modal-create-user-form-input">
                                <label>Unidades Selecionadas:</label>
                                <select onChange={handleUnitySelected} defaultValue={"0"}  >
                                    <option disabled key={0} value="0">Selecione uma Unidade</option>
                                    {user.userDetails?.role == 0 ? unities?.map((unity: any, index: number) => {
                                        return (
                                            <option value={unity.id} key={index + 1}>{unity.name}</option>
                                        )
                                    }) :
                                        unitiesProfile?.map((unity: any, index: number) => {
                                            return (
                                                <option value={unity.id} key={index + 1}>{unity.name}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>
                            <div className="create-evaluation-tech-products-selected-container">
                                {formValues.unities?.map((unity: any) => {
                                    let unityToRender: any = unities.find((unityFind: any) => unityFind.id == unity.id)

                                    if (unity?.id.length !== 0) {
                                        return (
                                            <div className="create-evaluation-tech-products-selected-card">
                                                <p>{unityToRender?.name}</p>
                                                <div onClick={() => {
                                                    setFormValues({
                                                        ...formValues,
                                                        unities: formValues.unities.filter((unityFilter: any) => unityFilter.id !== unity.id)
                                                    })
                                                }}>x</div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>

                            {formValues.role === 2 && (
                                <>
                                    <div className="modal-create-user-form-input">
                                        <label>Especialidade:</label>
                                        <select onChange={handleSpecialtySelected} value={selectedSpecialtyId}  >
                                            <option value={""} selected>Selecione uma Especialidade</option>
                                            {
                                                specialties.map((specialty: any, index: number) => {
                                                    return (
                                                        <option value={specialty.id} key={index}>{specialty.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="create-evaluation-tech-products-selected-container">
                                        {formValues.specialties?.map((specialty: any) => {
                                            let specialtyToRender: any = specialties.find((specialtyFind: any) => specialtyFind.id == specialty.id)

                                            if (specialty?.id.length !== 0) {
                                                return (
                                                    <div className="create-evaluation-tech-products-selected-card">
                                                        <p>{specialtyToRender?.name}</p>
                                                        <div onClick={() => {
                                                            setFormValues({
                                                                ...formValues,
                                                                specialties: formValues.specialties.filter((specialtyFilter: any) => specialtyFilter.id !== specialty.id)
                                                            })
                                                        }}>x</div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </>
                            )}

                            <div className="create-user-button-container">
                                {
                                    userId &&
                                    <button type="button" onClick={onDisable}>{userIsActive ? "Desativar" : "Reativar"} usuário</button>
                                }
                                <button disabled={
                                    !formValues.name ||
                                    isLoading ||
                                    (userId == undefined ? formValues.unities.length == 0 : false)
                                } type="submit">
                                    {
                                        isLoading ?
                                            <Spinner color="#FFF" size={21} speed={1} animating={true} /> :
                                            userId ? "Editar" : "Cadastrar"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CreateUserModal;