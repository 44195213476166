import { useEffect, useState } from "react";

import FilterJourneyComponent from "./FilterJourney";
import DigitalTransformationElevatorSection from "./DigitalTransformationElevatorSection";
import HeaderHelmetComponent from "../Layout/HeaderHelmet";

import { useAppSelector } from "../../store";

import DiagnosticService from "../../services/diagnostic";
import CompaniesService from "../../services/companies";
import { usersPermissionsGeneralFilters } from "../../utils/utils";

export interface ICompanyFilter {
    cities: any[],
    techProducts: any[],
    unities: any[],
}

export interface ITotals {
    journeyStep: string,
    results: string,
    total: string,
}

export interface ICompaniesTotals {
    totalCompanies: string,
}

const totalsInitialState: ITotals[] = [];

const inicialCompanyFilter = {
    cities: [],
    techProducts: [],
    unities: [],
}


const JourneyComponent = () => {

    const [selectedFilters, setSelectedFilters] = useState<ICompanyFilter>({
        cities: [],
        techProducts: [],
        unities: [],
    });
    const [filters, setFilters] = useState<ICompanyFilter>({
        cities: [],
        techProducts: [],
        unities: [],
    });

    const { user } = useAppSelector(state => state.user);

    const [totals, setTotals] = useState<ITotals[]>(totalsInitialState);
    const [companiesTotals, setCompaniesTotals] = useState<ICompaniesTotals>()
    const [isLoading, setIsLoading] = useState(true);
    const [aux, setAux] = useState(false) // magic code, DO NOT touch it

    const unitiesArray: any = user.userDetails?.unities?.map((u: any) => u.name);

    const getFilters = async () => {
        let { data } = await DiagnosticService.getAllFilters();

        setFilters(data);
    }

    const getDiagnosticsTotals = async () => {
        try {
            const { data } = usersPermissionsGeneralFilters(user.userDetails?.role) ?
                await DiagnosticService.getAllTotais()
                :
                await DiagnosticService.getAllTotais({ ...inicialCompanyFilter, unities: unitiesArray });

            setTotals(data);
        } catch (error) {
            console.error(error)
        }
    }

    const getCompaniesTotals = async () => {
        setIsLoading(true);
        try {
            let { data } = usersPermissionsGeneralFilters(user.userDetails?.role) ?
                await CompaniesService.getCompaniesCount(selectedFilters)
                :
                await CompaniesService.getCompaniesCount({ ...selectedFilters, unities: unitiesArray });

            setCompaniesTotals(data);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getFilters();
        getCompaniesTotals();
    }, []);

    useEffect(() => {
        getDiagnosticsTotals();
    }, [user]);

    return (
        <>
            <HeaderHelmetComponent nestedPage="Etapas da Jornada" />

            <div className="journey-container" style={{ display: "flex" }}>
                <div className="journey-card">
                    <DigitalTransformationElevatorSection
                        totais={totals}
                        companiesTotais={companiesTotals}
                        loading={isLoading} />
                </div>

                {
                    usersPermissionsGeneralFilters(user.userDetails?.role) && (
                        <div className="journey-filter">
                            <FilterJourneyComponent
                                aux={aux}
                                setAux={setAux}
                                setTotais={setTotals}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                                unities={filters.unities}
                                cities={filters.cities}
                                techProducts={filters.techProducts}
                                setIsLoading={setIsLoading}
                                setCompaniesTotais={setCompaniesTotals}
                            />
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default JourneyComponent;