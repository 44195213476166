import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CompanyFilterComponent from "./CompanyFilter";
import DataTableVisualization from "./DataVisualization/DataTable";
import HeaderHelmetComponent from "../Layout/HeaderHelmet";
import CompaniesService from '../../services/companies'
import { useAppSelector } from "../../store";
import { ICompanyFilter, storeCompanyFilter } from "../../store/slices/companyFilter";
import { usersPermissionsGeneralFilters } from "../../utils/utils";

import ASCIcon from './assets/up.svg';
import DESCIcon from './assets/down.svg';
import NONEIcon from './assets/opposite_arrows.svg';

import "react-activity/dist/library.css";
import { IUnity } from "../../interfaces/user";
import Pagination from "../Pagination";


export interface INewEnterpriseFilter {
    companies: string
    diagnostic: boolean
    unities: string[]
    cities: string[]
    tech_products: string[]
    consultants: string[]
    stepsJorney: string[]
    hasDiagnostic: boolean,
    has_registry_report: boolean,
    sort?: SortData
}

// TODO padronizar essas interfaces
export interface IEnterprise {
    id: string
    company: string
    CNPJ: boolean
    city: string
    uf: string
    name: string
    unity: string
    id_diagnostic: string
    consultant_name: string
    tech_product: string
}

export interface Company {
    id: string
    id_company: string
    company: string
    cnpj: string
    city: string
    uf: string
    area: string
    consultant: string
    unity: string
    id_diagnostic: string
    status_diagnostic: string
    proposal_number: string
    tech_product: string
    has_registry_report: boolean
    created_at: string
}

export interface SortData {
    key: keyof typeof initialSortState
    type: any
}

export const SortIcons = [
    {
        icon: NONEIcon,
        name: 'NONE'
    }, {
        icon: ASCIcon,
        name: 'ASC'
    }, {
        icon: DESCIcon,
        name: 'DESC'
    }
]

export const filtersInitialState = {
    companies: "",
    diagnostic: false,
    unities: [],
    tech_products: [],
    cities: [],
    consultants: [],
    hasDiagnostic: false,
    has_registry_report: false,
    stepsJorney: []
}

export interface IArrayFilters {
    unities: string[]
    cities: string[]
    consultants: string[]
    tech_products: string[]
    company?: string
    has_registry_report: boolean
    hasDiagnostic: boolean
    stepsJorney: string[]
}

export interface SortType {
    CNPJ: number,
    NAME: number,
    CITY: number,
    UNITY: number,
    PROPOSAL: number,
}

const initialSortState = {
    CNPJ: 0,
    NAME: 0,
    CITY: 0,
    UNITY: 0,
    PROPOSAL: 0
}


const CompanyComponent = () => {
    const dispatch = useDispatch()
    const { user } = useAppSelector(state => state.user);
    const { companyFilter } = useAppSelector(state => state.companyFilter)
    const [selectedSortType, setSelectedSortType] = useState<SortType>(initialSortState);
    const [filterOptions, setFilterOptions] = useState<INewEnterpriseFilter>(filtersInitialState);
    const [companies, setCompanies] = useState<Array<Company>>();
    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [sortData, setSortData] = useState<SortData>({
        key: 'CNPJ',
        type: SortIcons[0].name
    })
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(30);

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
    }

    const selectSortType = (key: keyof typeof selectedSortType) => {
        let current = selectedSortType[key] + 1;
        if (current >= SortIcons.length) current = 0;
        setSelectedSortType({ ...initialSortState, [key]: current })
        setSortData({ key: key, type: SortIcons[current].name })
    }

    const handleSelectedFiltersCompany = (companyFilter: ICompanyFilter) => {
        dispatch(storeCompanyFilter(companyFilter))
    }

    const getOrderCompaniesWithFilters = async () => {
        const pagination = {
            page: currentPage,
            limit: limit
        }
        setIsLoading(true);
        const unities = user.userDetails?.unities?.map((unity: IUnity) => unity.name);
        const companies = user.userDetails?.role === 2 ?
            await CompaniesService.getOrderedCompanies(sortData, pagination, { ...companyFilter, unities: unities === undefined ? [""] : unities }) :
            await CompaniesService.getOrderedCompanies(sortData, pagination, companyFilter);
        setCompanies(companies.data);
        setTotal(parseInt(companies.headers.total || '0'));
        setIsLoading(false);
    }

    const getFiltersOptions = async () => {
        try {
            let { data } = user.userDetails?.role === 2 ?
                await CompaniesService.getFilters({ unities: user.userDetails?.unities?.map((unity: IUnity) => unity.name) }) :
                await CompaniesService.getFilters({ unities: [] })

            if (user.userDetails?.role === 2)
                delete data.unities

            data.stepsJorney = [
                {
                    step: "1",
                    description: "Diagnóstico"
                },
                {
                    step: "2",
                    description: "Estratégia"
                },
                {
                    step: "3",
                    description: "Otimização de Processos"
                },
                {
                    step: "4",
                    description: "Mapeamento"
                },
                {
                    step: "5",
                    description: "Automação"
                },
                {
                    step: "6",
                    description: "Digitalização"
                },
                {
                    step: "7",
                    description: "Integração"
                },
                {
                    step: "8",
                    description: "Indústria Inteligente"
                },
            ]

            setFilterOptions(data);
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        handleSelectedFiltersCompany(companyFilter)
        getFiltersOptions();
    }, [user]);

    useEffect(() => {
        setCurrentPage(1)
    }, [user]);


    useEffect(() => {
        getOrderCompaniesWithFilters()
    }, [sortData, currentPage])

    return (
        <>
            <HeaderHelmetComponent nestedPage="Empresas" />
            <div className="companies-container">
                <div className="journey-container">
                    <div className="journey-card">
                        <div className='table-header'>
                            <h1 className="company-page-title" >Empresas</h1>
                        </div>
                        <div className="enterprise-table-container">
                            <DataTableVisualization
                                handleOrderSelector={selectSortType}
                                companies={companies}
                                totalCompanies={total}
                                setLoading={setIsLoading}
                                selectedSortType={selectedSortType}
                                loading={isLoading} />
                        </div >
                    </div>

                    <CompanyFilterComponent
                        getCompaniesByFilters={getOrderCompaniesWithFilters}
                        filterOptions={filterOptions}
                        setIsLoading={setIsLoading}
                        selectedFilters={companyFilter}
                        setSelectedFilters={handleSelectedFiltersCompany}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                <Pagination currentPage={currentPage} limit={limit} total={total} onPageChange={onPageChange} />
            </div >
        </>

    );
};

export default CompanyComponent;