import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import RadarChartComponent from '../utils/RadarChart';
import { applyCnpjMask } from '../../utils/masks';
import { useAppSelector } from "../../store";
import { storeDiagnostic } from '../../store/slices/diagnostic';
import { getAllTechDiagnostics, getDiagnosticsByProposalId } from '../../utils/utils';


type Contact = {
    id: string;
    name: string;
    email: string;
    phone: string;
}

const ConstructionComponent = ({proposalId}:{proposalId:string}) => {  
    const dispatch = useDispatch(); 
    
    const { company } = useAppSelector(state => state.company);
    const companyContacts: Contact[] = company.companyContext?.contacts;

    const allDiagnostics = getAllTechDiagnostics(company.companyContext);
    const diagnostic = getDiagnosticsByProposalId(allDiagnostics,proposalId);

    const filteredDiagnosticResult = diagnostic?.grade?.constructionResult;
    
    const defaultResults={
        noValues:0,
        value2:0,
        value3:0,
        value4:0,
        value5:0,
    }
 
    const [currentContact, setCurrentContact] = useState<any>({
        contact: {
            id: "",
            name: "",
            email: "",
            phone: ""
        }
    });
 
    const convertValues =(obj:any[])=>{
        let value = {}
        try {
            obj.forEach((values) =>{
                value = {...value, [values.name]:values.answers[0].result}
            } )
            return value
        } catch (error) {
            return value
        }
    
    }

    const convertValuesMain =(obj:any[])=>{
        let value = {}
        try {
            obj.forEach((values) =>{
                value = {...value, [values.name]:values.result * 100 /3 }
            } )
            return value
        } catch (error) {
            return value
        }

    }

    const getContactPosition = (contact: Contact) => companyContacts.findIndex((item) => item?.id === contact?.id) + 1;

    const handlePreviousContact = () => {
        const previousContactIndex = getContactPosition(currentContact) - 2;
        previousContactIndex >= 0 && setCurrentContact(companyContacts[previousContactIndex]);
    }

    const handleNextContact = () => {
        const nextContactIndex = getContactPosition(currentContact);
        const contactsQuantity = companyContacts.length - 1;
        if (nextContactIndex > contactsQuantity) return;
        setCurrentContact(companyContacts[nextContactIndex]);
    }

    useEffect(() => {
        dispatch(storeDiagnostic(diagnostic));
        company.companyContext?.contacts[0] &&
            setCurrentContact(company.companyContext?.contacts[0]);
    }, []);

    console.log(filteredDiagnosticResult);
    
    const len = filteredDiagnosticResult?.hubs?.length / 2 
    
    return (
        <> 
           <div id="process-container">
           {Array.from(Array(Math.ceil(len) || 0), (event,id) => ( 
             <div className="container-charts" style={{marginBottom:'0.7rem'}}>
             <div className="container-chart-content"> 
                 <div className="radar-chart-container">
                    <div className="container-chart-title" style={{marginLeft:'-3rem'}}>
                        <h4>{filteredDiagnosticResult?.hubs[id===0?id:2]?.name}</h4>
                    </div>
                     <div style={{ height: "90%",width:'90%',marginLeft:id===0?'2rem':0 }}>
                        <RadarChartComponent
                            evaluation='0'
                            data={convertValues(filteredDiagnosticResult?.hubs[id===0?id:2 ]?.questions) ?? defaultResults}
                        ></RadarChartComponent>
                     </div>
                     <div className="evaluation-result-container">
                         <div className="chart-evaluation">
                            <span>Avaliação: {filteredDiagnosticResult?.hubs[id===0?id:2 ]?.result ? filteredDiagnosticResult?.hubs[id===0?id:2 ]?.result.toFixed(2) : 0} / 3</span>
                         </div>
                     </div>
                 </div>
                 {(filteredDiagnosticResult?.hubs?.length !== 1 && id === 0) || (filteredDiagnosticResult?.hubs?.length !== 3 && id === 1) ?
                     <div className="radar-chart-container">
                     <div className="container-chart-title" style={{marginLeft:'-3rem'}}>
                         <h4>{filteredDiagnosticResult?.hubs[id===0?1:3]?.name}</h4>
                     </div>
                      <div style={{ height: "90%",width:'90%' }}>
                         <RadarChartComponent
                             evaluation='0'
                             data={convertValues(filteredDiagnosticResult?.hubs[id===0?1:3 ]?.questions) ?? defaultResults}
                         ></RadarChartComponent>
                      </div>
                      <div className="evaluation-result-container">
                          <div className="chart-evaluation">
                             <span>Avaliação: {filteredDiagnosticResult?.hubs[id===0?1:3 ]?.result ? filteredDiagnosticResult?.hubs[id===0?1:3 ]?.result.toFixed(2) : 0} / 3</span>
                          </div>
                      </div>
                  </div>
                 :null }
                
             </div>
         </div>
            ))}
 
            </div>    
            <aside id="side-data-container">
            <div className="container-charts" style={{height:'440px',width:'100%',paddingLeft:'3rem'}}>
             <div className="container-chart-content"> 
                 <div className="radar-chart-container">
                    <p style={{paddingTop:'5px',paddingLeft:'0rem'}}>Diagnóstico de construção civil</p>
                    <div className="container-chart-title" style={{fontSize:'1.5rem',marginBottom:'0.7rem',marginTop:"1rem" ,marginLeft:'2rem'}} >
                         <h4>Nota {(filteredDiagnosticResult?.total * 100 / (filteredDiagnosticResult?.hubs.length * 3) ).toFixed(2)} %</h4>
                     </div>
                     <div style={{ height: "90%",width:'90%',marginTop:'1rem'}}>
                        <RadarChartComponent
                            hideText
                            evaluation='0'
                            data={convertValuesMain(filteredDiagnosticResult?.hubs) ?? defaultResults}
                        ></RadarChartComponent>
                     </div> 
                 </div> 
             </div>
         </div> 
                <div id="company-info-container">
                    <div className="company-info-item">
                        <div className="company-info-title">
                            <h3>Dados da empresa</h3>
                        </div>
                        <div id="company-details-container">
                            <p>CNPJ: {applyCnpjMask(company.companyContext?.CNPJ)}</p>
                            <p>Cidade: {company.companyContext?.city}</p>
                            <p>Área: {company.companyContext?.area}</p>
                        </div>
                    </div>
                    <div className="company-info-item">
                        <div className="company-info-title">
                            <h3>Contatos</h3>
                        </div>
                        {
                        company.companyContext?.contacts.length === 0 ? (
                            <span>Nenhum contato encontrado.</span>
                        ) : (
                            <>
                                <div id="company-data-container">
                                    <div id="contact-edition-container" >
                                        <span>{getContactPosition(currentContact)}º Contato</span>
                                        {/* TODO {
                                            user.companyDetails?.id  && (
                                                <div id="edition-button-container" onClick={() => setIsModalOpen(true)}>
                                                    <img src="/assets/icons/edit-blue-outline.png" alt="Editar" />
                                                    <span>editar</span>
                                                </div>
                                            )
                                        } */}
                                    </div>

                                    <p>Contato: {currentContact?.name}</p>
                                    <p>E-mail: {currentContact?.email}</p>
                                    <p>Telefone: {currentContact?.phone}</p>
                                </div>
                                <div id="contact-pagination-container">
                                    <div id="pagination-button-container">
                                        <img
                                            src="/assets/icons/left-arrow-black.png"
                                            alt="Contato anterior"
                                            onClick={() => handlePreviousContact()}
                                        />
                                        <span> {getContactPosition(currentContact)} de {companyContacts?.length} </span>
                                        <img
                                            src="/assets/icons/right-arrow-black.png"
                                            alt="Próximo contato"
                                            onClick={() => handleNextContact()}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            </aside>
 
        </>
    );
};

export default ConstructionComponent;