
export const removeDuplicatedIndexes = (arr: any[]) => {
    const uniqueArr = arr?.filter((element, index) => {
        return arr.indexOf(element) === index;
    });

    return uniqueArr
}

export const usersPermissionsGeneralFilters = (userRole: number | undefined): any => {
    if( userRole !== undefined ) {
        return [0, 1].includes(userRole)
    }
    return false
}

export const usersPermissionsCoordenatorFilters = (userRole: number | undefined): boolean => {
    if (userRole !== undefined) {
        return [0, 1].includes(userRole)
    }
    return false
}

export const usersPermissionAdmin = (userRole: number | undefined): boolean => {
    if (userRole !== undefined) {
        return [0].includes(userRole)
    }
    return false
}

export const getAllTechDiagnostics = (companyContext: any)=> companyContext?.diagnostics?.filter((diagnostic:any)=> 
    diagnostic?.specific_title === 'Diagnóstico Tecnológico' && diagnostic?.grade !== null
)

export const getAllDiagnostics = (companyContext: any)=> {
    const diagnostics = companyContext?.diagnostics

    return [...new Map(diagnostics.map((item:any)=> [item['proposal_id'],item])).values()]
}

export const getDiagnosticsByProposalId = (diagnostics:any,proposalId:any) => {
    let diagnosticById:any = null

    diagnostics?.forEach((item:any)=>{
        if(item.proposal_id === proposalId) diagnosticById = item
    })
    
    return diagnosticById
}
