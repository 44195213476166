import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ICompanyFilter } from "..";

import diagnosticService from "../../../services/diagnostic";
import CompaniesService from "../../../services/companies";
import FilterItem from "../../utils/Filter/FilterItem";
import FilterSearch from "../../utils/Filter/FilterSearch";
import FilterBody from "../../utils/Filter/FilterBody";

interface IFilterProps {
    selectedFilters: ICompanyFilter,
    setSelectedFilters: Dispatch<SetStateAction<ICompanyFilter>>
    unities: string[]
    cities: string[]
    techProducts: string[]
    setIsLoading?: any
    setCompaniesTotais?: any

    setTotais?: any

    aux: any
    setAux: any
}



const FilterJourneyComponent: React.FC<IFilterProps> = ({ setCompaniesTotais, selectedFilters, setSelectedFilters, setIsLoading, unities, cities, techProducts, aux, setAux, setTotais }) => {

    const [hasVisible, setHasVisible] = useState({ item1: false, item2: false, item3: false })

    const [techProductsSearch, setTechProductsSearch] = useState<any>(techProducts)
    const [citiesSearch, setCitiesSearch] = useState<any>(cities)
    const [unitySearch, setUnitySearch] = useState<any>(unities)
    const [techProductsInput, setTechProductsInput] = useState("")
    const [citiesInput, setCitiesInput] = useState("")
    const [unityInput, setUnityInput] = useState("")

    const clearAllFIlters = async () => {
        setHasVisible({ item1: false, item2: false, item3: false })
        setIsLoading(true)
        setTechProductsSearch(techProducts)
        setCitiesSearch(cities)
        setUnitySearch(unities)
        setCitiesInput("")
        setTechProductsInput("")
        setUnityInput("")
        setSelectedFilters({
            cities: [],
            techProducts: [],
            unities: [],
        })
        await reloadFilters({
            cities: [],
            techProducts: [],
            unities: [],
        })
        setIsLoading(false)
    }

    const handleSelection = async (filterType: keyof typeof selectedFilters, filter: string | number) => {
        setIsLoading(true)

        if (!Object.keys(selectedFilters).includes(filterType))
            return console.log(`ERROR: Filter type "${filterType}" does not exist in enterprise filters!\nFilterTypes : ${Object.keys(selectedFilters)}`);

        setSelectedFilters({
            cities: [],
            techProducts: [],
            unities: [],
        })
        setAux(!aux)

        const newSelectedFilters = selectedFilters;

        if (selectedFilters[filterType].includes(filter)) {
            newSelectedFilters[filterType] = selectedFilters[filterType].filter((_filter: any) => _filter !== filter)

            setSelectedFilters(newSelectedFilters);
        } else {
            newSelectedFilters[filterType] = [...selectedFilters[filterType], filter]

            setSelectedFilters(newSelectedFilters);
        }

        await reloadFilters(newSelectedFilters)

        setIsLoading(false)
    }

    const reloadFilters = async (newSelectedFilters: ICompanyFilter) => {
        try {
            const res = await diagnosticService.getAllTotais(newSelectedFilters)
            const resCountCompanies = await CompaniesService.getCompaniesCount(newSelectedFilters)

            setTotais(res.data)

            setCompaniesTotais(resCountCompanies.data)
        } catch (error) {
            console.error(error)
        }
    }

    const onTechProductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTechProductsInput(event.target.value)
        setTechProductsSearch(techProducts?.filter((techProduct: any) => techProduct.techProduct?.toLowerCase().includes(event.target.value?.toLowerCase())))
    }

    const onRegionSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCitiesInput(event.target.value)
        setCitiesSearch(cities?.filter((city: any) => city.city?.toLowerCase().includes(event.target.value.toLocaleLowerCase())))
    }

    const onUnitySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnityInput(event.target.value)
        setUnitySearch(unities?.filter((unity: any) => unity.unity?.toLowerCase().includes(event.target.value.toLocaleLowerCase())))
    }

    const filterSearch = (key: "techProduct" | "city" | "unity", filterArray: string[], inputValue: string) => {
        const filterLabel = filterArray.filter((item: any) => item[key] !== null && item[key]?.toLowerCase().includes(inputValue?.toLowerCase()))

        return filterLabel.map((filter: any) => {
            return {
                label: filter[key],
                value: filter[key]
            }
        })
    }


    const clearFilter = async (key: "techProducts" | "cities" | "unities", setInput: (value: React.SetStateAction<string>) => void, setSearch: React.Dispatch<any>, search: string[]) => {

        setIsLoading(true)
        setInput("")
        setSearch(search)
        setSelectedFilters({
            ...selectedFilters,

            [key]: [],

        })
        await reloadFilters({
            ...selectedFilters,

            [key]: [],

        })
        setIsLoading(false)
    }

    useEffect(() => {
        setTechProductsSearch(techProducts)
    }, [techProducts])

    useEffect(() => {
        setCitiesSearch(cities)
    }, [cities])

    useEffect(() => {
        setUnitySearch(unities)
    }, [unities])

    return (
        <>
            <div className="filter-container">
                <div className="filter-title" style={{ marginBottom: "8px" }}>
                    <span>Filtrar</span>
                    <div className="filter-clear-all" onClick={() => clearAllFIlters()}>
                        <span>limpar</span>
                        <img src="/assets/icons/eraser.png" alt="" />
                    </div>
                </div>
                <div className="filter-main">
                    {
                        cities &&
                        <FilterItem numberOfSelectedFilters={selectedFilters.cities.length} title={"Regiões"} showNumber >
                            <FilterSearch value={citiesInput} type="text" placeholder="Digite aqui a região" onChange={(event: any) => onRegionSearch(event)} />
                            <FilterBody typeInput="checkbox" selectedFilterItems={selectedFilters.cities} filterOptionsItem={filterSearch("city", citiesSearch, citiesInput)} onClear={() => { clearFilter("cities", setCitiesInput, setCitiesSearch, cities) }} onSelect={(value) => handleSelection("cities", value)} />
                        </FilterItem>
                    }
                    {
                        unities &&
                        <FilterItem numberOfSelectedFilters={selectedFilters.unities.length} title={"Unidades"} showNumber >
                            <FilterSearch value={unityInput} type="text" placeholder="Digite aqui uma unidade" onChange={(event: any) => onUnitySearch(event)} />
                            <FilterBody typeInput="checkbox" selectedFilterItems={selectedFilters.unities} filterOptionsItem={filterSearch("unity", unitySearch, unityInput)} onClear={() => { clearFilter("unities", setUnityInput, setUnitySearch, unities) }} onSelect={(value) => handleSelection("unities", value)} />

                        </FilterItem>
                    }
                    {
                        techProducts &&
                        <FilterItem numberOfSelectedFilters={selectedFilters.techProducts.length} title={"Produtos Tecnológicos"} showNumber >
                            <FilterSearch value={techProductsInput} type="text" placeholder="Digite aqui um produto tecnológico" onChange={(event: any) => onTechProductSearch(event)} />
                            <FilterBody typeInput="checkbox" selectedFilterItems={selectedFilters.techProducts} filterOptionsItem={filterSearch("techProduct", techProductsSearch, techProductsInput)} onClear={() => { clearFilter("techProducts", setTechProductsInput, setTechProductsSearch, techProducts) }} onSelect={(value) => handleSelection("techProducts", value)} />
                        </FilterItem>
                    }
                </div>
            </div>
        </>
    )
}

export default FilterJourneyComponent
