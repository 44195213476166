import evaluationService from "../../../../../../../services/evaluation";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CreateQuestionModal from "../../../../../modals/CreateQuestion/CreateQuestionModal";
import QuestionService from "../../../../../../../services/question";

const icons = {
    rightArrowGray: "assets/icons/right-arrow-gray.png"
}
interface IEvaluationTableQuestionLine {
    questionId: string
    reload: () => void
    title: string
    question: any
    questions: any
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>

    }
    answers?: any
    sequence: number
    relation: 'criteria' | 'hub' | 'evaluation'
    id: string
    currentMenu: string
    setCurrentMenu: Dispatch<SetStateAction<string>>
    dependencies: string[]
    setDependencies: Dispatch<SetStateAction<string[]>>
    sessions?: []
    upNeighbor : any
    downNeighbor : any
    journeyStep ?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' 

}

const EvaluationTableQuestionLine = ({ journeyStep, questionId, reload, alert, title, question, questions, upNeighbor, downNeighbor, answers, sequence, id, relation, currentMenu, setCurrentMenu, dependencies, setDependencies, sessions }: IEvaluationTableQuestionLine) => {

    const [visibilityOptions, setVisibilityOptions] = useState(false)
    const [drop, setDrop] = useState(false)

    const deleteQuestion = (questionToDelete: any) => {
        if (questionToDelete.answers.length > 0) {
            questionToDelete.answers.forEach(async (answer: any) => await evaluationService.deleteAnswer(answer.id))
            evaluationService.deleteQuestion(questionToDelete.id).then((res: any) => {
                if (res.data.statusCode === 200) {
                    alert.setMessage("Pergunta deletada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    setVisibilityOptions(false)
                    reload()
                } else {
                    alert.setMessage("Ocorreu um erro ao deletar a questão!")
                    alert.setShow(true)
                    setTimeout(() => {
                        alert.setShow(false)
                    }, 3175)
                }
            })
        } else {
            evaluationService.deleteQuestion(questionToDelete.id).then((res: any) => {
                try {

                    if (res.data.statusCode === 200) {
                        alert.setMessage("Pergunta deletada com sucesso!")
                        alert.setShow(true)
                        alert.setConfirm(true)
                        setTimeout(() => {
                            alert.setShow(false)
                            alert.setConfirm(false)
                        }, 3175)
                        setVisibilityOptions(false)
                        reload()
                    } else {
                        alert.setMessage("Ocorreu um erro ao deletar a questão!")
                        alert.setShow(true)
                        setTimeout(() => {
                            alert.setShow(false)
                        }, 3175)
                    }
                } catch (error) {
                    alert.setMessage("Ocorreu um erro ao deletar a questão!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                }
            })
        }

    }

    const reloadQuestion = () => {
        reload()
        setVisibilityOptions(false)
    }


    const [currentModal, setCurrentModal] = useState<any>();
    let modals = {
        none: <></>,
        edit: <></>,
        registerQuestion: <></>
    }

    const handleEditModal = (editQuestion: any, idQuestion: any) => {
        return modals.edit = <CreateQuestionModal  journeyStep={journeyStep} sessions={sessions} alert={alert} questions={questions} relation={relation} editQuestion={editQuestion} id={id} questionId={idQuestion} reload={reloadQuestion} action="EDIT" close={() => setCurrentModal(modals.none)} />
    }

    const handleRegisterQuestion = (parentQuestion: any) => {
        return modals.registerQuestion = <CreateQuestionModal  journeyStep={journeyStep} sessions={sessions} parentQuestion={parentQuestion} alert={alert} relation={relation} reload={reload} action="REGISTER" questions={questions} id={id} close={() => setCurrentModal(modals.none)} />

    }

    useEffect(() => {
        if (question.hasDependency)
            setDependencies([...dependencies, question.hasDependency])
    }, [])

    return (
        <>
            {
                questions.find((q: any) => q.id === question.hasDependency && q.type == 'Tabela') == undefined ? (

                    <tr className="evaluation-table-line child">
                        
                        <td onClick={() => setCurrentModal(handleEditModal(question, question.id))}>Questão:{question.type}</td>
                        <td>
                            <div className="evaluation-table-name-container question">
                                <div >
                                    {dependencies.includes(question.id) && question.type == 'Tabela' ?
                                        <div onClick={() => setDrop(!drop)} className={drop === true ? "evaluation-table-name-image-container dropped" : "evaluation-table-name-image-container"}>
                                            <img src={icons.rightArrowGray} alt="" />
                                        </div>
                                        : null}
                                    <div className="evaluation-table-name" >{title}</div>
                                </div>

                                <div className="evaluation-table-options-container question">
                                    { upNeighbor && <img className="evaluation-table-options up" src={icons.rightArrowGray} alt="" onClick={() => { QuestionService.moveQuestion(questionId, upNeighbor.id).then(() => reloadQuestion()) }} /> }
                                    { downNeighbor && <img className="evaluation-table-options down" src={icons.rightArrowGray} alt="" onClick={() => { QuestionService.moveQuestion(questionId, downNeighbor.id).then(() => reloadQuestion()) }} />}
                                    <img onClick={() => {
                                        setVisibilityOptions(!visibilityOptions)
                                        setCurrentMenu(question.id)
                                    }} className="evaluation-table-options-more" src="/assets/icons/more-options-gray.png" alt="" />
                                </div>
                            </div>
                        </td>
                        {visibilityOptions == true && currentMenu == question.id ?
                            <div className="evaluation-table-menu-container">
                                {question.type == "Tabela" ?
                                    <div onClick={() => setCurrentModal(handleRegisterQuestion(question))} className="evaluation-table-menu-item"> Adicionar questão </div>
                                    : null}
                                <div onClick={() => setCurrentModal(handleEditModal(question, question.id))} className="evaluation-table-menu-item"> Editar </div>
                                <div onClick={() => deleteQuestion(question)} className="evaluation-table-menu-item"> Excluir </div>
                            </div>
                            : null}
                    </tr>

                ) : null
            }


            {drop === true ?
                (
                    questions.filter((q: any) => dependencies.includes(q.hasDependency)).map((dependencyQuestion: any) => {
                        return (
                            <tr className="evaluation-table-line">

                                <td onClick={() => setCurrentModal(handleEditModal(dependencyQuestion, question.id))}>Questão: {dependencyQuestion.type}</td>
                                <td>
                                    <div className="evaluation-table-name-container" style={{ paddingLeft: "12%" }}>
                                        <div >
                                            <div className="evaluation-table-name"  >{dependencyQuestion.name}</div>
                                        </div>

                                        <div className="evaluation-table-options-container question">

                                            <img onClick={() => {
                                                setVisibilityOptions(!visibilityOptions)
                                                setCurrentMenu(dependencyQuestion.id)
                                            }} className="evaluation-table-options-more" src="/assets/icons/more-options-gray.png" alt="" />
                                        </div>
                                    </div>
                                </td>
                                {visibilityOptions == true && currentMenu == dependencyQuestion.id ?
                                    <div className="evaluation-table-menu-container">

                                        <div onClick={() => setCurrentModal(handleEditModal(dependencyQuestion, dependencyQuestion.id))} className="evaluation-table-menu-item"> Editar </div>
                                        <div onClick={() => deleteQuestion(dependencyQuestion)} className="evaluation-table-menu-item"> Excluir </div>
                                    </div>
                                    : null}
                            </tr>
                        )
                    })

                )
                : null}

            {
                currentModal
            }

        </>
    )

}

export default EvaluationTableQuestionLine