import React, { ReactNode, useState } from "react";

type CollapsibleMenuComponentProps = {
    children: ReactNode;
}

const CollapsibleMenuComponent = ({ children }: CollapsibleMenuComponentProps) => {

    return (
        <>
            <div className="collapsible-menu-container">
                {children}
            </div>
        </>
    );
}

export default CollapsibleMenuComponent;