import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-activity";

import CompanyService from "../../../services/companies";
import DiagnosticService from "../../../services/diagnostic";
import { useAppSelector } from "../../../store";
import { storeDiagnostic } from "../../../store/slices/diagnostic";
import { storeCompany } from "../../../store/slices/company";

import "react-activity/dist/library.css";

interface IAvaliationModalProps {
  onConfirm?: any
  closeModal: any
  setAlert: any
  description: string,
  companyAgrees: boolean,
  isReviewed: boolean,
  setDowloadVisibleButton: any
}

const AvaliationModalComponent: React.FC<IAvaliationModalProps> = ({ onConfirm, closeModal, setAlert, description, companyAgrees, isReviewed, setDowloadVisibleButton }) => {

  const [evaluation, setEvaluation] = useState({
    timeResolution: 1,
    punctuality: 1,
    technicalSolutions: 1,
    specialistCompetency: 1,
    profissionalism: 1,
    price: 1,
    general: 1
  });

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAppSelector(state => state.user);
  const { diagnostic } = useAppSelector(state => state.diagnostic);
  const { company } = useAppSelector(state => state.company);

  const renderStars = (fullStars: number, key: keyof typeof evaluation) => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
      stars[i] = <span className="star" key={i} onClick={() => setEvaluation({ ...evaluation, [key]: i + 1 })}>{(i < fullStars) ? <>&#9733;</> : <>&#9734;</>}</span>
    }

    return stars;
  }

  const sendAvaliation = async () => {

    if (isLoading) return

    setIsLoading(true)

    const satisfactionRatingDetails = {
      "evaluation": evaluation,
      "companyAgrees": companyAgrees,
      "description": description,
      "company": {
        "id": user.companyDetails?.id
      },
      [isReviewed == true ? "reviewed_diagnostic" : "diagnostic"]: {
        "id": diagnostic?.id
      }
    }
    const diagnosticStatus = {
      "status": isReviewed ? 7 : 3
    }

    await CompanyService.sendSatisfactionRating(satisfactionRatingDetails, diagnostic.id, diagnosticStatus).then(async (res: any) => {

      if (res.data.statusCode && res.data.statusCode == 500) {
        setDowloadVisibleButton(true)
        closeModal()
        setIsLoading(false)
        setAlert.setAlertMessage("já exite uma avaliação para este diagnóstico")
        setAlert.setAlertShow(true)
        setTimeout(() => {
          setAlert.setAlertShow(false)
        }, 3175)
      } else {

        try {

          const diagnosticResponse = await DiagnosticService.getDiagnosticById(diagnostic.id);
          const companyResponse = await CompanyService.getCompanyById(company.id);
          
          dispatch(storeDiagnostic(diagnosticResponse));
          dispatch(storeCompany(companyResponse));
        } catch (error) {
          console.log(error);
        } finally {
          setDowloadVisibleButton(true);
          closeModal();

          setIsLoading(false);

          setAlert.setAlertMessage("avaliação enviada com sucesso");

          setAlert.setAlertShow(true);

          setAlert.setAlertConfirm(true);

          setTimeout(() => {
            setAlert.setAlertConfirm(false)
            setAlert.setAlertShow(false)
          }, 3175);

          navigate("/enterprise/proposals");

          setAlert.setAlertConfirm(true);

          setTimeout(() => {
            setAlert.setAlertConfirm(false)
            setAlert.setAlertShow(false)
          }, 3175);
        }
      }
    });
  }

  return (
    <>
      <div className="avaliation-modal-container">
        <div className="avaliation-modal" style={{ minWidth: "480px" }}>
          <div className="avaliation-modal-close-container">
            <span className="close-button" onClick={() => closeModal()}>
              <img src={`/assets/icons/close-modal.png`} alt="close-modal" />
            </span>
          </div>
          <div className="avaliation-modal-header" style={{ marginBottom: -10 }}>
            <span>Olá {user.companyDetails?.name}</span>
            <p>Como você avalia o nosso atendimento em termos de:</p>
          </div>
          <div className="avaliation-modal-body">
            <div className="avaliation-container" >
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Tempo de resolução </span>
              </div>
              <div className="stars-container">
                {renderStars(evaluation.timeResolution, "timeResolution")}
              </div>
            </div>
            <div className="avaliation-container">
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Pontualidade </span>
              </div>
              <div className="stars-container">
                {renderStars(evaluation.punctuality, "punctuality")}
              </div>
            </div>
            <div className="avaliation-container">
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Soluções  técnicas propostas </span>
              </div>
              <div className="stars-container">
                {renderStars(evaluation.technicalSolutions, "technicalSolutions")}
              </div>
            </div>
            <div className="avaliation-container">
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Conhecimento e competência do especialista </span>
              </div>
              <div className="stars-container">
                {renderStars(evaluation.specialistCompetency, "specialistCompetency")}
              </div>
            </div>
            <div className="avaliation-container">
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Cortesia e profissionalismo </span>
              </div>
              <div className="stars-container">
                {renderStars(evaluation.profissionalism, "profissionalism")}
              </div>
            </div>
            <div className="avaliation-container" style={{ marginBottom: 10 }}>
              <div className="text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}> Preço </span>
              </div>
              <div className="stars-container ">
                {renderStars(evaluation.price, "price")}
              </div>
            </div>
            <div className="all-avaliation-container">
              <div className="avaliation-text-container">
                <span style={{ fontSize: "15px", fontWeight: "300" }}>Como você avalia o nosso atendimento em geral?</span>
                <div style={{ paddingLeft: '32%', paddingRight: '2.8%' }}>
                  <div className="stars-container">
                    {renderStars(evaluation.general, "general")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="avaliation-modal-footer">
            <button disabled={isLoading} type="button" onClick={() => sendAvaliation()}>{isLoading ? <Spinner color="#FFF" size={21} speed={1} animating={true} /> : "Confirmar Pesquisa"}</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AvaliationModalComponent;