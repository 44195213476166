import React, { useState } from "react"
import { Spinner } from "react-activity"
import specialtyService from "../../../services/specialty"

interface IAddSpecialtyModalProps {
    onClose : () => void
    editingSpecialty ?: {
        id : string,
        name : string
    }
}

const AddSpecialtyModal : React.FC<IAddSpecialtyModalProps> = ({ onClose, editingSpecialty }) => {
    const [specialty, setSpecialty] = useState(editingSpecialty ?? { name : "" })
    const [loading, setLoading] = useState(false)

    const onSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (loading || !specialty.name) return

        setLoading(true)

        const res = (editingSpecialty) ?
            await specialtyService.editSpecialty({...specialty, area : specialty.name}, editingSpecialty.id)
        :
            await specialtyService.createSpecialty({...specialty, area : specialty.name})
        
        setLoading(false)

        if (res.hasError){
            alert(res.errorMessage)
        }

        onClose()
    }

    const handleChange = (e: any) => {
        setSpecialty({ 
            ...specialty,
            [e.target.name] : e.target.value 
        })
    }

    return (
        <div className="modal-wrapper">
            <div className="create-specialty-modal">
                <img 
                    src={`/assets/icons/close-modal.png`} 
                    alt="close-modal" 
                    className="close-button" 
                    onClick={onClose}
                />

                <h2>{(editingSpecialty) ? 'Edição' : 'Cadastro'} de especialidade</h2>

                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="input-container">
                        <label>Nome</label>
                        <input className="modal-input" type="text" placeholder="Lean Office" name="name" onChange={handleChange} value={specialty.name} />
                    </div>

                    <button disabled={!specialty.name || loading}>{ loading ? <Spinner color="#000" size={21} speed={1} animating={true} /> : "Cadastrar"}</button>
                </form>
            </div>
        </div>
    )
}

export default AddSpecialtyModal