export const applyCnpjMask = (value: string) => {
    return value
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/, "$1.$2")
        .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        .replace(/\.(\d{3})(\d)/, ".$1/$2")
        .replace(/(\d{4})(\d{2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const removeCnpjMask = (value: string) => {
    let masked = value.replace(/\D/g, "");

    masked = masked.replaceAll(".", "")
    masked = masked.replace("/", "")
    masked = masked.replace("-", "")

    return masked
}

export const applyCpfMask = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export const onlyNumbers = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\n{3})/, '$1')
}

export const applyPhoneMask = (value: string) => {
    if (value[5] === "9")
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{5})(\d{4}).*/, "$1-$2");
    else
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{4})(\d{4}).*/, "$1-$2");
}