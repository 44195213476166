import React from "react";
import { Squares } from "react-activity";

interface ITransformationStageCard {
    name: string
    stage: any
    toDo: any
    finished: any
    loading: Boolean
}

const TransformationStageCard: React.FC<ITransformationStageCard> = ({ name, stage, toDo, finished, loading = false }) => {
    return (
        <>
            <div className="transformation-stage-card-container">
                {loading ? <Squares /> : (
                    <>
                        <div className="transformation-stage-card-header">
                            <div className="transformation-stage-card-header-step" >0{stage}</div>
                            <div className="card-container-image-container">
                                <div className="enterprise-transformation-stage-card-header-image" >
                                    <img src={`/assets/icons/journeySteps/journeyStep${stage}.png`} alt="" />
                                </div>
                                <div className="transformation-stage-card-header-step-title">{name}</div>
                            </div>
                        </div>
                        <div className="transformation-stage-card-body" >

                            <span >Total <code>{toDo}</code></span>
                            <span >Realizados <code>{finished}</code></span>

                        </div>
                    </>
                )}

            </div>
        </>
    )
}

export default TransformationStageCard