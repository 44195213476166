import { useState } from "react";
import { createStringLiteralFromNode } from "typescript";
import { ISpeciality, ISpecialtyRes } from "../../../interfaces/speciality";
import specialtyService from "../../../services/specialty";

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

interface ICreateSpecialityModalProps {
    onClose : () => void;
    reload : () => void;
}

const CreateSpecialityModal : React.FC<ICreateSpecialityModalProps> = ({ onClose, reload }) => {

    const [speciality, setSpeciality] = useState<ISpeciality>({
        name: "",
        area: ""
    });

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = async (e : any) => {
        e.preventDefault();

        if (isLoading) return

        setIsLoading(true)

        if (!speciality.name || !speciality.area) return;

        const res = (await specialtyService.createSpecialty(speciality)) as ISpecialtyRes;

        if (res.hasError) 
            return alert(res.errorMessage);

        setIsLoading(false)
        onClose()
        reload()
        setSpeciality({ name : "", area : "" }) 
    }

    const handleChange = (e : any) => setSpeciality({ ...speciality, [e.target.name] : e.target.value })

    return (
        <div className="modal-wrapper">
            <div className="speciality-modal-container">
                <span className="close-button" onClick={onClose}>
                    <img src={`/assets/icons/close-modal.png`} alt="close-modal" />
                </span>

                <h2 className="title">Cadastro de especialidade</h2>

                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="input-container">
                        <label>Nome</label>
                        <input className="modal-input" type="text" placeholder="Lean Office" name="name" onChange={handleChange} value={speciality.name} />
                    </div>

                    <div className="input-container">
                        <label>Area</label>
                        <input className="modal-input" type="text" placeholder="Lean" name="area" onChange={handleChange} value={speciality.area}/>
                    </div>
                    <button disabled={!speciality.name || !speciality.area || isLoading}>{ isLoading ? <Spinner color="#000" size={21} speed={1} animating={true} /> : "Cadastrar"}</button>
                </form>
            </div>
        </div>
    )
}

export default CreateSpecialityModal;


