import React, { useState } from "react"
import techProductService from "../../../services/techProduct"

interface ITechProductProps {
    editProduct?: any
    onClose: () => void
}

const TechProductModal: React.FC<ITechProductProps> = ({ editProduct, onClose }) => {
    const [productName, setProductName] = useState(editProduct?.name || "")
    const [selectedStep, setSelectedStep] = useState(editProduct?.journeyStep || "1")

    const onSubmit = async () => {
        const body = {
            name: productName,
            journeyStep: selectedStep
        }

        const res = (editProduct) ?
            await techProductService.updateTechProduct(editProduct.id, body)
            : await techProductService.addTechProduct(body)

        onClose()
    }

    const renderStepOptions = () => {
        const options = []

        for (let i = 1; i <= 8; i++) {
            options.push(
                <option value={i}>{i}</option>
            )
        }

        return options
    }

    return (
        <div className="modal-wrapper">
            <div className="tech-product-modal-container">
                <div className="header">
                    <h2>{(editProduct) ? 'Editar' : 'Adicionar'} produto tecnológico</h2>

                    <img src="/assets/icons/cancel-red.png" alt="" onClick={() => onClose()} />
                </div>

                <div className="input-container">
                    <span>Nome:</span>
                    <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} />
                </div>

                <div className="input-container">
                    <span>Passo da jornada:</span>
                    <select onChange={(e) => setSelectedStep(e.target.value)}>
                        {renderStepOptions()}
                    </select>
                </div>

                <button disabled={productName ? false : true} onClick={onSubmit}>{(editProduct) ? 'Salvar' : 'Adicionar'}</button>
            </div>
        </div>
    )
}

export default TechProductModal