import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
} from 'chart.js';

import { Radar } from 'react-chartjs-2';


ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    LinearScale,
    BarElement,
    CategoryScale,
    Title
);

type GenericObject = {
    [key: string]: any;
};

interface IRadarChartProps {
    evaluation: string;
    data: GenericObject;
    hideText?: boolean;
}

const RadarChartComponent: React.FC<IRadarChartProps> = ({ evaluation, data,hideText }: IRadarChartProps) => {
    const dataValues = Object.values(data);
    const dataKeys = Object.keys(data);

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend,
        LinearScale,
        BarElement,
        CategoryScale,
        Title
    );

    const chartData = {
        labels: dataKeys.map((value,index)=> `1.${index + 1}`),
        datasets: [ 
            {
                label: 'Nota',
                data: dataValues,
                backgroundColor: 'transparent',
                borderColor: '#9A1915',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: !hideText ? {
                    stepSize: 0.5,
                    min: 0.5,
                    max: 3,
                }:{} ,
            },
        },
    };

    return (
        <>
            <div className={`chart-container ${hideText?'chat-222':''}`} style={hideText?{height:'220px',width:'220px'}:{}}>
                <Radar data={chartData} options={options} />
                {(data.noValues !== 0 ) && 
                <div id='chart-container-content' style={{marginLeft:hideText?'1rem':'-10%',fontSize:'0.9rem'}}>
                    {dataKeys.map((value,index)=>(
                        <p>1.{index + 1} - {dataKeys[index]}: <span style={{color:'green'}}>{dataValues[index]?.toFixed(2)}{hideText?'%':''}</span></p>
                    ))} 
                </div>
                }
            </div>
        </>
    );
}

export default RadarChartComponent;
