interface IFilterItemProps {
    selectedFilterItems: string[]
    filterOptionsItem: Array<{
        label: string
        value: string
        description?: string
    }>
    onClear: () => void
    onSelect: (value: string) => void
    typeInput: "checkbox" | "radio"
}

const FilterBody = ({ onClear, onSelect, filterOptionsItem, selectedFilterItems, typeInput }: IFilterItemProps) => {

    return (
        <>
            {selectedFilterItems.length > 0 &&
                <div onClick={onClear} className="filter-item-clear">
                    <span>Limpar</span>
                    <img src="/assets/icons/eraser.png" alt="" />
                </div>
            }
            <div className={`filter-main-item-body enterprise filter-checkbox filter-item-body-shown`}>
                {filterOptionsItem.map((option, index: number) => (
                    <div onClick={() => onSelect(option.value)} className="filter-item" key={index}>
                        <input style={{ cursor: "pointer" }} checked={selectedFilterItems.includes(option.value)} type={typeInput} />
                        <label>{option.label} {  option.description && ` - ${option.description}`}</label>
                    </div>)

                )}
                {
                    filterOptionsItem.length === 0 &&
                    <div className="filter-item-not-send">
                        <p > Não foi encontrado nenhum filtro correspondente</p>
                    </div>
                }
            </div>
        </>
    )
}

export default FilterBody