import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import evaluationService from "../../../../../services/evaluation";
import CreateEvaluationModal from "../../../modals/CreateEvaluationModal";
import CreateQuestionModal from "../../../modals/CreateQuestion/CreateQuestionModal";
import EvaluationTableCriteriaLine from "./EvaluationTableCriteriaLine/EvaluationTableCriteriaLine";
import EvaluationTableQuestionLine from "./EvaluationTableCriteriaLine/EvaluationTableQuestionLine/EvaluationTableQuestionLine";

interface IEvaluationTableHubLine {
    reload: () => void
    hub: any
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>

    }
    currentMenu: string,
    currentPlatform: string,
    setCurrentMenu: Dispatch<SetStateAction<string>>
    sessions: []
    journeyStep ?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' 
    
}

const EvaluationTableHubLine = ({ journeyStep, reload, hub, alert, currentMenu, setCurrentMenu, sessions, currentPlatform }: IEvaluationTableHubLine) => {
    const [drop, setDrop] = useState(false)
    const [visibilityOptions, setVisibilityOptions] = useState(false)
    const [dependencies, setDependencies] = useState<string[]>([])

    const deleteHub = () => {
        evaluationService.deleteHub(hub.id).then((res: any) => {
            try {
                if (res.data.statusCode === 200) {
                    alert.setMessage("Pergunta deletada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    setVisibilityOptions(false)
                    reload()
                    return
                }
                alert.setMessage("Ocorreu um erro ao deletar o eixo!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)

            } catch (error) {
                alert.setMessage("Ocorreu um erro ao deletar o eixo!")
                alert.setShow(true)
                setTimeout(() => {
                    alert.setShow(false)
                }, 3175)
            }
        })
    }


    const registerCriteria = () => {
        setCurrentModal(modals.register)
    }

    const reloadCriteria = () => {
        reload()
        setVisibilityOptions(false)
    }

    const [currentModal, setCurrentModal] = useState<any>();

    const modals = {
        none: <></>,
        register: <CreateEvaluationModal alert={alert} reload={reloadCriteria} action="REGISTER" type={"CRITERIA"} name={""} id={hub.id} close={() => setCurrentModal(modals.none)} />,
        editHub: <CreateEvaluationModal alert={alert} reload={reloadCriteria} action="EDIT" type={"HUB"} name={hub.name} id={hub.id} close={() => setCurrentModal(modals.none)} />,
        editSession: <CreateEvaluationModal alert={alert} reload={reloadCriteria} action="EDIT" type={"SESSION"} name={hub.name} id={hub.id} close={() => setCurrentModal(modals.none)} />,
        registerQuestion: <CreateQuestionModal  journeyStep={journeyStep} sessions={sessions} alert={alert} relation="hub" reload={reloadCriteria} action="REGISTER" questions={hub.questions} id={hub.id} close={() => setCurrentModal(modals.none)} />
    }

    useEffect(() => {
        if (drop)
            setVisibilityOptions(false)
    }, [drop])
    return (<>
        <tr onClick={() => setDrop(!drop)} className={drop ? "evaluation-table-line dropped" : "evaluation-table-line"} >

            <td>{journeyStep && parseInt(journeyStep) === 1 && currentPlatform === "MOBILE" ? "Eixo" : "Seção"}</td>
            <td>
                <div className="evaluation-table-name-container hub">
                    <div>
                        {hub.criterion.length > 0 || hub.questions.length > 0 ?
                            <div className={drop === true ? "evaluation-table-name-image-container dropped" : "evaluation-table-name-image-container"}>
                                <img src="/assets/icons/right-arrow-gray.png" alt="" />
                            </div>
                            : null
                        }

                        <div className="evaluation-table-name">{hub.name}</div>
                    </div>

                    <div className="evaluation-table-options-container">
                        <img onClick={() => {
                            setVisibilityOptions(!visibilityOptions)
                            setCurrentMenu(hub.id)
                        }} className="evaluation-table-options-more" src="/assets/icons/more-options-gray.png" alt="" />
                    </div>
                </div>
            </td>
            {visibilityOptions && currentMenu == hub.id ?
                <div onClick={() => setVisibilityOptions(!visibilityOptions)} className="evaluation-table-menu-container">
                    {hub.questions.length == 0 && (journeyStep == "1" || journeyStep == "4") && currentPlatform === "MOBILE" && <div onClick={() => registerCriteria()} className="evaluation-table-menu-item"> Adicionar critério </div>}
                    {hub.criterion.length == 0 && <div onClick={() => setCurrentModal(modals.registerQuestion)} className="evaluation-table-menu-item"> Adicionar pergunta </div>}
                    {journeyStep && parseInt(journeyStep) === 1 && currentPlatform === "MOBILE" ? <div className="evaluation-table-menu-item" onClick={() => setCurrentModal(modals.editHub)}> Editar </div> : <div className="evaluation-table-menu-item" onClick={() => setCurrentModal(modals.editSession)}> Editar </div>}
                    <div onClick={() => deleteHub()} className="evaluation-table-menu-item"> Excluir </div>
                </div>
                : null}
        </tr>

        {drop === true && hub.criterion.length != 0 ?
            hub.criterion?.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1).map((criteria: any) => {
                return (
                    <EvaluationTableCriteriaLine  journeyStep={journeyStep}  currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} alert={alert} reload={reload} criteriaId={criteria.id} questions={criteria.questions} title={criteria.name} />
                )
            })
            : null}
        {drop === true && hub.questions.length != 0 ?
            hub.questions?.sort((a: any, b: any) => (a.sequenceIndex > b.sequenceIndex) ? 1 : -1).map((question: any, index: number) => {
                return (
                    <EvaluationTableQuestionLine journeyStep={journeyStep}  upNeighbor={hub.questions[index + 1]} downNeighbor={hub.questions[index - 1]} sessions={sessions} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} alert={alert} questions={hub.questions} relation={"hub"} id={hub.id} question={question} reload={reload} questionId={question.id} sequence={question.sequenceIndex} key={question.id} answers={question.answers} title={question.name} dependencies={dependencies} setDependencies={setDependencies} />
                )
            })
            : null}

        {currentModal}
    </>
    )
}

export default EvaluationTableHubLine