import { useState, useEffect } from "react";

import CreateUserModal from "./Modals/CreateUser";

import DataTableVisualization from "./DataVisualization/DataTable";
import HeaderHelmetComponent from "../Layout/HeaderHelmet";
import CreateSpecialityModal from "./Modals/CreateSpeciality";

import consultantService from "../../services/consultant"
import { IUser } from "../../interfaces/user";
import { useAppSelector } from "../../store";
import UserFilter from "./UserFilter";

import ASCIcon from '../assets/up.svg';
import DESCIcon from '../assets/down.svg';
import NONEIcon from '../assets/opposite_arrows.svg';
import AlertMessage from "../utils/Alert";
import Pagination from "../Pagination";

export interface UserQueryFilter {
    unities: string[]
    specialties: string[]
    search: string
    sort?: string
    showDisabled?: boolean
    loggedUser: number | null
    typeUser: string
    take?: number
    page?: number
}

export const SortIcons = [
    {
        icon: NONEIcon,
        name: 'NONE'
    },

    {
        icon: ASCIcon,
        name: 'ASC'
    },
    { icon: DESCIcon, name: 'DESC' },

]

export interface SortType {
    NIF: number,
    UNITIES: number,
    CITY: number,
    NAME: number,
}

const initialSortState = {
    NIF: 0,
    NAME: 0,
    UNITIES: 0,
    CITY: 0,
}
export interface SortData {
    key: keyof typeof initialSortState
    type: any
}


const UsersComponent = () => {
    const [selectedSortType, setSelectedSortType] = useState<SortType>(
        initialSortState
    )
    const [users, setUsers] = useState<IUser[]>([])
    const [aux, setAux] = useState(false) // magic code, DO NOT touch it
    const [specialties, setSpecialties] = useState([])
    const [loading, setLoading] = useState<Boolean>(true)
    const { user } = useAppSelector(state => state.user);
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [totalUsers, setTotalUsers] = useState(0);


    const [sortData, setSortData] = useState<SortData>({
        key: 'NIF',
        type: SortIcons[0].name
    })

    const [selectedFilters, setSelectedFilters] = useState<UserQueryFilter>({
        unities: [],
        specialties: [],
        search: '',
        typeUser: "",
        loggedUser: user?.userDetails?.role === 1 ? 1 : 1,
        showDisabled: true
    });

    const visualizeTable = true;

    const selectSortType = (key: keyof typeof selectedSortType) => {
        let current = selectedSortType[key] + 1;
        if (current >= SortIcons.length) current = 0;
        setSelectedSortType({ ...selectedSortType, [key]: current })
        setSortData({ key: key, type: SortIcons[current].name })
    }

    const searchConsultant = async () => {
        setLoading(true);
        const pagination = {
            page: currentPage,
            limit: limit
        }
        try {
            const res = await consultantService.getUsersWithFilter(selectedFilters, sortData, pagination);
            setUsers(res.data);
            setTotalUsers(parseInt(res.headers.total || '0'));
        }
        catch (e) {
            console.error('error rquest data', e)
        } finally {
            setLoading(false)
        }
    }
    const modals = {
        none: <></>,
        createUser: <CreateUserModal
            alert={{
                confirm: setAlertConfirm,
                show: setAlertShow,
                message: setAlertMessage
            }}
            onClose={() => {
                searchConsultant()
                setAux(!aux)
                setCurrentModal(modals.none)
            }} />,
        createSpeciality: (
            <CreateSpecialityModal reload={() => refreshSpecialty()} onClose={
                () => {
                    setAux(!aux)
                    setCurrentModal(modals.none)
                }
            } />
        )
    }

    const [currentModal, setCurrentModal] = useState(modals.none);

    const refreshSpecialty = () => {
        consultantService.getSpecialties().then((specialties: any) => {
            setSpecialties(specialties)
        })
    }

    const onPageChange = (newPage: number) => {
        setCurrentPage(newPage);
    }

    useEffect(() => {
        searchConsultant()
    }, [selectedFilters, sortData, currentPage]);

    useEffect(() => {
        setCurrentPage(1)
    }, [selectedFilters]);

    useEffect(() => {
        const role = user.userDetails?.role!;
        setSelectedFilters({ ...selectedFilters, loggedUser: role })
    }, [user])

    useEffect(() => {
        consultantService.getSpecialties().then((specialties: any) => {
            setSpecialties(specialties)
        });
    }, []);

    return (

        <>
            <HeaderHelmetComponent nestedPage="Usuários" />

            <div className="main-container">
                <div className="company-container">
                    <div className="journey-card">
                        <div className="table-header">
                            <h1 className="user-page-title">Usuários</h1>
                            <div className="user-page-header-container">
                                <button disabled className="user-page-button" onClick={() => setCurrentModal(modals.createUser)}>Adicionar Usuário</button>
                            </div>
                        </div>

                        {
                            visualizeTable ? (
                                <>
                                    <DataTableVisualization users={users}
                                        setUsers={setUsers}
                                        totalUsers={totalUsers}
                                        searchConsultant={searchConsultant}
                                        forceReload={() => setAux(!aux)}
                                        loading={loading}
                                        handleOrderSelector={selectSortType}
                                        selectedSortType={selectedSortType}
                                        alert={{
                                            confirm: setAlertConfirm,
                                            show: setAlertShow,
                                            message: setAlertMessage
                                        }} />
                                </>
                            ) : (
                                <>
                                </>
                            )
                        }

                    </div>

                    <UserFilter
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
                <Pagination currentPage={currentPage} limit={limit} total={totalUsers} onPageChange={setCurrentPage} />

            </div >
            {currentModal}

            < AlertMessage confirm={alertConfirm} visible={alertShow} bgColor={"#F4F6F6"} message={alertMessage} />
        </>
    );
};

export default UsersComponent;