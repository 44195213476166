import { createSlice } from '@reduxjs/toolkit';


interface ICompany {
    id: string,
    CNPJ: string,
    name: string,
    diagnostics: any[],
    companyContext: any
}

const initialState = {
    company: {} as ICompany
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        storeCompany: (state, action) => {
            state.company = {
                ...state.company,
                ...action.payload
            }
        },
        removeCompany: (state) => {
            state = initialState;
        }
    },
});

export const { storeCompany, removeCompany } = companySlice.actions;

export default companySlice.reducer;