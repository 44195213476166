import { api } from ".";


const createEvaluation = async (evaluation: any) => {
  try {
    return await api.post(`/Evaluations`, evaluation).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const getEvaluation = async (journeyStep: any) => {
  try {
    return await api
      .get(`/Evaluations?page=1&keySearch=journey_step&keyword=${journeyStep}`)
      .then((response) => {
        return response.data.data;
      });
  } catch (e) {
    console.log(e);
  }
};

const getEvaluationByTechProduct = async (techProductName: any) => {
  try {
    return await api.get(`/Evaluations`).then((response) => {
      return response.data.data.filter((evaluation: any) =>
        evaluation.tech_products.find((techProduct: any) =>
          techProduct.name.includes(techProductName)
        )
      );
    });
  } catch (e) {
    console.log(e);
  }
};

const getEvaluationByTechProductName = async (techProductName: any) => {
  try {
    return await api.get(
      `/Evaluations/byTechProductName?techProductName=${techProductName}`
    );

  } catch (e) {}
};

const getEnergyEvaluation = async () => {
  try {
    return await api.get(`/Evaluations`).then((response) => {
      return response.data.data.filter(
        (evaluation: any) =>
          evaluation.name.includes("Eficiência") &&
          (evaluation.name.includes("Pré-Diagnóstico") ||
            (evaluation.name.includes("energética") &&
              evaluation.name.includes("Pré-Diagnóstico")))
      );
    });
  } catch (e) {
    console.log(e);
  }
};

const getEvaluationById = async (evaluationId: any) => {
  try {
    return await api.get(`/Evaluations/${evaluationId}`).then((response) => {
      return response.data.data;
    });
  } catch {}
};

const getAllEvaluations = async () => {
  try {
    return await api.get(`/Evaluations`).then((response) => {
      return response.data.data.filter(
        (evaluation: any) =>
          evaluation.journey_step === 3 ||
          evaluation.journey_step === 4 ||
          evaluation.journey_step === 5 ||
          evaluation.journey_step === 6 ||
          evaluation.journey_step === 7 ||
          evaluation.journey_step === 8
      );
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteEvaluation = async (id: any) => {
  try {
    return await api.delete(`/Evaluations/${id}`).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const editEvaluation = async (id: any, evaluation: any) => {
  try {
    return await api.put(`/Evaluations/${id}`, evaluation).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const createHub = async (hub: any) => {
  try {
    return await api.post(`/Hubs`, hub).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const getHubByEvaluationId = async (idEvaluation: any) => {
  try {
    return await api.get(`/Evaluations/${idEvaluation}`).then((response) => {
      if (!response.data.hasError) {
        return {
          data: response.data.data.hubs,
          hasError: false,
        };
      } else {
        return response.data;
      }
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteHub = async (id: any) => {
  try {
    return await api.delete(`/Hubs/${id}`).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const editHub = async (id: any, hub: any) => {
  try {
    return await api.put(`/Hubs/${id}`, hub).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const createCriterion = async (criterion: any) => {
  try {
    return await api.post(`/Criterion`, criterion).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const getCriterionByHubId = async (idHub: any) => {
  try {
    return await api.get(`/Hubs/${idHub}`).then((response) => {
      if (!response.data.hasError) {
        return {
          data: response.data.data.criterion,
          hasError: false,
        };
      }
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const getCriterion = async (id: any) => {
  try {
    return await api.get(`/Criterion/${id}`).then((response) => {
      if (!response.data.hasError) {
        return {
          data: response.data.data,
          hasError: false,
        };
      }
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteCriterion = async (id: any) => {
  try {
    return await api.delete(`/Criterion/${id}`).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const editCriterion = async (id: any, criterion: any) => {
  try {
    return await api.put(`/Criterion/${id}`, criterion).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const createQuestion = async (question: any) => {
  try {
    return await api.post(`/Questions`, question).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteQuestion = async (id: any) => {
  try {
    return await api.delete(`/Questions/${id}`).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const editQuestion = async (id: any, question: any) => {
  try {
    return await api.put(`/Questions/${id}`, question).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const createAnswer = async (answer: any) => {
  try {
    return await api.post(`/Answers`, answer).then((response) => {
      return response.data;
    });
  } catch (e) {
    console.log(e);
  }
};

const deleteAnswer = async (id: any) => {
  try {
    return await api.delete(`/Answers/${id}`).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const getAnswersByQuestionId = async (id: any) => {
  try {
    return await api.get(`/Questions/${id}`).then((response) => {
      return response.data.data.answers;
    });
  } catch (error) {
    return error;
  }
};

const editAnswer = async (id: any, question: any) => {
  try {
    return await api.put(`/Answers/${id}`, question).then((response) => {
      return response;
    });
  } catch (e) {
    console.log(e);
  }
};

const methodsToExport = {
  createEvaluation,
  getEvaluation,
  deleteEvaluation,
  editEvaluation,
  createHub,
  deleteHub,
  editHub,
  createCriterion,
  deleteCriterion,
  editCriterion,
  createQuestion,
  deleteQuestion,
  getHubByEvaluationId,
  getCriterionByHubId,
  getCriterion,
  getAnswersByQuestionId,
  createAnswer,
  deleteAnswer,
  editAnswer,
  editQuestion,
  getAllEvaluations,
  getEvaluationById,
  getEnergyEvaluation,
  getEvaluationByTechProduct,
  getEvaluationByTechProductName,
};

export default methodsToExport;
