import { Dispatch, FormHTMLAttributes, ReactElement, SetStateAction } from 'react';

interface FormModalContainerProps extends FormHTMLAttributes<HTMLFormElement> {
    children: ReactElement<any, string> | ReactElement<any, string>[];
    title: string;
    buttonSubmitText: string;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function FormModalContainer({
    children,
    title,
    buttonSubmitText,
    setIsModalOpen,
    ...rest
}: FormModalContainerProps) {
    return (
        <form className="form-modal-container" {...rest}>
            <img src="/assets/icons/x-icon-red.png" alt="Close" onClick={() => setIsModalOpen(false)} />
            <h3>{title}</h3> {children} <button>{buttonSubmitText}</button>
        </form>
    );
}
