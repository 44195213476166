import axios from "axios"
import { API_URL } from "../constants";

export const api = axios.create({
  baseURL: API_URL,
  // headers: {
  //   'Authorization': `Baerer ${localStorage.getItem("token")}`
  // }
})

api.interceptors.request.use(async function (config) {
  const token = await localStorage.getItem("token");

  if (token && config.headers)
    Object.assign(config.headers, { Authorization: `Bearer ${token}` })
  return config;
}, function (error) {
  return Promise.reject(error);
});