
import { api } from "."


const authUser = async (user: string, pass: string) => {
    try {
        const {data} = await api.post(`/Users/login`, {
            user: user,
            password: pass
        })

        return data
    } catch (error : any) {
        console.log(error.response);
        return error.response
    }
}

const setUserIsActive = async (id: string, value: number) => {
    try {
        return await api.put(`/users/${id}`,
            { isActive: value })
            .then((res: any) => res.data)
    } catch (error : any) {
        console.error(error);
        return error.response
    }
}

const methodsToExport = {
    authUser,
    setUserIsActive
}

export default methodsToExport