import { useAppSelector } from '../../store';
import CivilConstructionComponentPDF from '../../components/Proposals/CivilConstructionComponentPDF';


const CivilConstruction = () => {

  const { diagnostic } = useAppSelector(state => state.diagnostic);

  return (
    <>
      {diagnostic && <CivilConstructionComponentPDF proposalId={diagnostic?.proposal_id} />}
    </>
  );
};

export default CivilConstruction;