import { api } from ".";
import { IDiagnosticRes } from "../interfaces/diagnostic";


const getAllDiagnostics = async (): Promise<IDiagnosticRes | any> => {
  try {
    return await api
      .get(`/Diagnostics`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    console.log(err);
  }
};

const getCompaniesAtJourneyStep = async () => {
  return await api.get(`/Companies/analytics`)
    .then((res) => res.data[0].result);
};

const getAllCompanies = async (): Promise<any> => {
  try {
    return await api.get(`/Companies?keySearch=name&page=1&take=1`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    console.log(err);
  }
};

const updateDiagnostic = async (diagnostic: any, id: string) => {
  try {
    return await api.put(`/Diagnostics/${id}`, diagnostic)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    return err;
  }
};
const getDiagnosticById = (id: string) => {
  return api.get(`/Diagnostics/${id}`)
    .then((res) => res.data.data);
};
const getDiagnosticByEnterprise = async (cnpjCompany: string) => {
  try {
    return await api.get(
      `/Proposals?page=1&keySearch=enterprise_cnpj&keyword=${cnpjCompany}`
    )
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

const getSatisfactionRatingById = async (satisfactionRatingId: string) => {
  return await api.get(`/SatisfactionRating/${satisfactionRatingId}`)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      return error;
    });
};

const getAllTotais = async (
  obj: any = { cities: ([] = []), techProducts: ([] = []), unities: ([] = []) }
) => {
  var url = `/Diagnostics/totais/all?cities=${obj?.cities.join()}&tech_products=${obj?.techProducts.join()}&unities=${obj?.unities.join()}`;
  return await api.get(url);
};

const getAllFilters = async () => {
  return await api.get(`/Diagnostics/totais/filters`);
};

const getUnititesStatusOfDiagnostics = async () => {
  return await api.get(`/Diagnostics/report/generate`);
}

const doDiagnosticts = {
  getAllDiagnostics,
  getDiagnosticByEnterprise,
  updateDiagnostic,
  getDiagnosticById,
  getSatisfactionRatingById,
  getAllCompanies,
  getCompaniesAtJourneyStep,
  getAllTotais,
  getAllFilters,
  getUnititesStatusOfDiagnostics
}

export default doDiagnosticts;
