import React from "react";
import { useAppSelector } from "../../../../store";

interface IReportRegistrySubMenuItem {
    proposal_number: String
    specific_title: String
    evaluations: any
    diagnostic: any
    onChange: Function
}

export const ReportRegistrySubMenuItem: React.FC<IReportRegistrySubMenuItem> = ({ proposal_number, evaluations, diagnostic, specific_title, onChange }) => {

    const dataArray = evaluations?.filter((evaluation: any) =>
        evaluation?.tech_products?.find(((tech: any) =>
            tech?.name.includes(specific_title)
        )))
    const { company } = useAppSelector(state => state.company);

    const convertedTimestampToDate = (updatedAt: string) => {
        const [year, month, day] = (updatedAt)?.substr(0, 10)?.split('-')
        if (year) return day + "/" + month + "/" + year
        return null
    }

    return (
        <>
            <div className="proposal-container">
                <span className="proposal-referent" style={{ fontSize: '15px' }}>
                    - {proposal_number} - {specific_title}
                </span>
                {
                    dataArray?.length > 0 ?
                        dataArray?.map((evaluation: any, index: number) => {
                            let registerReport = (company.companyContext?.registry_reports?.find((rp: any) => {
                                return rp?.diagnostic?.proposal_number === proposal_number
                            }))
                            return (
                                <div className="proposal-evaluation-container" key={index}>
                                    <span
                                        className="proposal-evaluation"
                                        style={{ fontSize: '14px' }}
                                        onClick={() => onChange(evaluation?.id, diagnostic.id)}
                                    >
                                        {evaluation?.name}
                                    </span>
                                    <span className="proposal-evaluation-date">
                                        {registerReport?.updatedAt &&
                                            <>
                                                {convertedTimestampToDate(registerReport?.updatedAt)}
                                                <label className="container-check-date">
                                                    <input checked type="checkbox" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </>
                                        }
                                    </span>
                                </div>
                            )
                        })
                        :
                        <div className="proposal-evaluation-container">
                            <span className="proposal-evaluation-common" style={{ color: "#878686" }}>
                                Sem Registro de Conclusão
                            </span>
                        </div>
                }
            </div>
        </>
    )
}