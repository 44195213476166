import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import evaluationService from "../../../services/evaluation"
import CompanyService from "../../../services/enterprise"
import { Company } from "..";

import { storeCompany } from "../../../store/slices/company";

interface IProps {
    company: Company
    index: number
    setLoading: any
}


const DataLine: React.FC<IProps> = ({ company, index, setLoading }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [drop, setDrop] = useState(false);

    const populateCompanyContext = async (id: string) => {
        setLoading(true);
        try {
            const res = await CompanyService.getCompany(id);
            const res2 = await evaluationService.getEvaluationByTechProductName(res?.__diagnostics__?.map((diag: any) => {
                let [, date] = diag?.proposal_number.split('/')
                if (parseInt(date) > 2020 && diag.specific_title !== null) { return diag.specific_title?.replace(/,/g, '-----') } else { return 'null' }
            }).toString()?.replace(/,null/g, '')?.replace(/,/g, '--')?.replace(/-----/g, ','));
            dispatch(storeCompany({
                companyContext: {
                    ...res,
                    evaluations: res2?.data,
                    diagnostics: res.__diagnostics__.sort((diag: any) => {
                        return diag?.id === company?.id_diagnostic ? -1 : 1
                    })
                }
            }));

            navigate(`/admin/enterprise/${company.cnpj}`);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <tr
                style={{ cursor: 'pointer' }}
                onClick={() => populateCompanyContext(company.id_company)}>


                <td onClick={() => setDrop(!drop)}>{company?.company}
                </td>

                <td >
                    {company?.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}
                </td>
                <td >
                    {company?.city}
                </td>
                <td >
                    {company?.unity}
                </td>

                <td >
                    {company?.consultant}
                </td>
                <td >
                    {company?.proposal_number}
                </td>
                <td >
                    {company?.tech_product}
                </td>
                <td className="status">
                    <div className="status-tag-container">
                        {company.has_registry_report &&
                            <div className="status-tag selected"> <p>Registro de conclusão</p> </div>
                        }
                        {(parseInt(company.status_diagnostic) >= 2 && company.tech_product === 'Diagnóstico Tecnológico') &&
                            <div className="status-tag selected"> <p>Diagnóstico Realizado</p> </div>
                        }
                    </div>

                </td>
            </tr>
        </>
    );
};

export default DataLine;