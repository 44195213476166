import React from "react";
import diagnostic from "../../../store/slices/diagnostic";
import { ReportRegistrySubMenuItem } from "./ReportRegistrySubMenuItem";

interface IReportRegistrySubMenuProps {
    diagnostic: any[]
    evaluation: any
    onChange: Function
}

const ReportRegistrySubMenu: React.FC<IReportRegistrySubMenuProps> = ({ diagnostic, onChange,evaluation }) => {
    return (
        <>
            <div className="report-registry-menu-container journey-card">
                {
                    diagnostic.map((diagnostic: any, index: number) => (
                        <ReportRegistrySubMenuItem 
                            key={index} 
                            onChange={onChange}
                            specific_title={diagnostic.specific_title} 
                            proposal_number={diagnostic.proposal_number} 
                            diagnostic={diagnostic}
                            evaluations={evaluation}
                        />
                    ))
                }
            </div>
        </>
    )
}

export default ReportRegistrySubMenu