import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
} from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

interface IChartProps {
    title?: string;
    evaluation?: string;
    data?: any;
    setImagesBase64: any;
    imagesBase64: any;
    indexHub?: any;
}

const CivilConstructionChart: React.FC<IChartProps> = ({ title, evaluation, data, setImagesBase64, imagesBase64, indexHub }: IChartProps) => {

    const dataValues = Object.values(data);
    const dataKeys = Object.keys(data);


    Object.keys(data).map((item: any) => dataValues.push(data[item]));

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend,
        LinearScale,
        BarElement,
        CategoryScale,
        Title
    );

    const chartData = {
        labels: dataKeys.map((value, index) => `${typeof indexHub == "number" ? indexHub + 1 + "." : ""}${index + 1}`),
        datasets: [
            {
                label: '',
                data: dataValues,
                backgroundColor: 'rgba(255, 0, 0, 0.16)',
                borderColor: '#9A1915',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: typeof indexHub == "number" ? {
                    stepSize: 0.5,
                    min: 0,
                    max: 3,
                } : {
                    stepSize: 20,
                    min: 10,
                    max: 100,
                },
            }
        },
        title: {
            display: false
        },
        legend: {
            display: false
        }
    };

    const ref = useRef<ChartJS>(null)

    setImagesBase64(ref.current?.toBase64Image())

    return (
        <>
            <div>
                <div className="chart" style={{ marginBottom: "16px" }}>
                    {Object.keys(data).length > 2 ? (
                        <Chart type="radar" data={chartData} options={options} ref={ref} />
                    ) : (
                        <Chart type="bar" data={chartData} options={options} ref={ref} />
                    )}
                </div>
                {evaluation ? (
                    <div className="chart-evaluation" style={{ textAlign: "center" }}>
                        <span>Avaliação</span>
                        {typeof indexHub == "number" ?
                            <h4>{evaluation}/3 = <span >{(parseFloat(evaluation) * 100 / 3).toFixed(1)}% </span></h4>
                            :
                            <h3>{(parseFloat(evaluation) * 100 / 12).toFixed(1)}% </h3>
                        }
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default CivilConstructionChart;
