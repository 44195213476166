import { api } from ".";


const getTechProducts = async () => {
    try {
        return await api.get(`/TechProduct`)
            .then((res: any) => res.data)
            .catch((err: any) => console.log(err));
    } catch (err) {
        console.log(err)
    }
}

const addTechProduct = async (body: { name: string, journeyStep: string }) => {
    try {
        return await api.post(`/TechProduct`, body)
            .then((res: any) => res.data)
            .catch((err: any) => console.log(err));
    } catch (err) {
        console.log(err)
    }
}

const updateTechProduct = async (id: string, body: { name: string, journeyStep: string }) => {
    try {
        return await api.put(`/TechProduct/${id}`, body)
            .then((res: any) => res.data)
            .catch((err:any) => console.log(err));
    } catch (err) {
        console.log(err)
    }
}

const deleteTechProduct = async (id: string) => {
    try {
        return await api.delete(`/TechProduct/${id}`)
            .then((res: any) => res.data)
            .catch((err: any) => console.log(err));
    } catch (err) {
        console.log(err)
    }
}

const techProductService = {
    getTechProducts,
    addTechProduct,
    updateTechProduct,
    deleteTechProduct
}

export default techProductService