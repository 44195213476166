import { api } from "."

const moveAnswer = async (answerId: string, targetId : string) => {
    try {
        return await api.patch(
            `/Answers/move`, {
                answer : answerId,
                target : targetId
            },
        ).then((res: any) => {
            return res.data
        }).catch((err: any) => console.error(err))
    } catch (err) {
        console.error(err)
    }
}

const AnswerService = {
    moveAnswer
}

export default AnswerService