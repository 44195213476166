import { useState, useEffect } from "react";
import { Squares } from "react-activity";

import { IUser } from "../../../interfaces/user";
import UserInformationComponent, { IUserToEdit } from "../Modals/UserInformation";
import consultantService from "../../../services/consultant";
import authService from "../../../services/auth";
import ConfirmationModal from "../../utils/ConfirmationModal";
import { useAppSelector } from "../../../store";

import { SortIcons, SortType } from '../';
import CreateUserModal from "../Modals/CreateUser";

export const SortTypes = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: 'none',
}
interface UserDataTableVisualizationProps {
    setUsers: Function,
    totalUsers: number,
    users: any[],
    forceReload: Function,
    selectedSortType: SortType,
    handleOrderSelector: Function,
    loading: Boolean,
    searchConsultant: Function,
    alert: {
        confirm: React.Dispatch<React.SetStateAction<boolean>>,
        show: React.Dispatch<React.SetStateAction<boolean>>,
        message: React.Dispatch<React.SetStateAction<string>>
    }
}

const DataTableVisualization: React.FC<UserDataTableVisualizationProps> = ({ alert, setUsers, searchConsultant, totalUsers,
    handleOrderSelector, loading, users, selectedSortType, forceReload }) => {

    const modals = {
        none: <></>
    }
    const [currentModal, setCurrentModal] = useState(modals.none);

    const { user } = useAppSelector(state => state.user);

    const setUserIsActive = (id: string, value: number) => {
        authService.setUserIsActive(id, value).then(() => setCurrentModal(modals.none))

        const i = users.findIndex((user: any) => user.id === id);

        const updatedUsers = users;
        updatedUsers[i] = {
            ...users[i],
            isActive: value
        }
        setUsers(updatedUsers)
    }

    const openEditUserModal = (userToBeEdited: IUserToEdit) => {
        setCurrentModal(<CreateUserModal
            onDisable={() => {
                setUserIsActive(userToBeEdited.id, userToBeEdited.isActive == 1 ? 0 : 1)
                alert?.message(`Usuário ${userToBeEdited.isActive == 1 ? "desativado" : "ativado"} com sucesso!`)
                alert?.confirm(true)
                alert?.show(true);
                setTimeout(() => {
                    alert?.show(false)
                }, 3175)
                forceReload()
            }}
            alert={{
                confirm: alert?.confirm,
                show: alert?.show,
                message: alert?.message
            }}
            onClose={() => {
                setCurrentModal(modals.none)
                searchConsultant()
                forceReload()


            }} userId={userToBeEdited.id} />)
        // setCurrentModal(
        //     <UserInformationComponent
        //         userToEdit={userToBeEdited}
        //         onClose={() => {
        //             setCurrentModal(modals.none)
        //             searchConsultant()
        //             forceReload()
        //         }}
        // onDisable={() => {
        //     setUserIsActive(userToBeEdited.id, userToBeEdited.isActive == 1 ? 0 : 1)

        //     forceReload()
        // }}
        //         reload={() => forceReload()} />
        // )
        forceReload();

    }

    return (
        <>
            <div className='data-view-table-container'>
                <div className="table-container" style={{ height: '86%' }}>
                    <table className="user-table" data-role={`${user?.userDetails?.role}`} style={{ marginBottom: "8px" }}>
                        <tr>
                            <th className="nif">
                                <div className="table-header-content">
                                    NIF
                                    <button className="order-selector-button" onClick={() => handleOrderSelector("NIF")}>
                                        <img src={SortIcons[selectedSortType.NIF].icon} alt="sort icon" />
                                    </button>
                                </div>
                            </th>
                            <th className="user-name">
                                <div className="table-header-content">
                                    Nome
                                    <button className="order-selector-button" onClick={() => handleOrderSelector("NAME")}>
                                        <img src={SortIcons[selectedSortType.NAME].icon} alt="sort icon" />
                                    </button>
                                </div>
                            </th>
                            <th className="user-role">Função</th>
                            <th className="user-unity">
                                <div className="table-header-content">
                                    Unidade
                                    <button className="order-selector-button" onClick={() => handleOrderSelector("UNITIES")}>
                                        <img src={SortIcons[selectedSortType.UNITIES].icon} alt="sort icon" />
                                    </button>
                                </div>
                            </th>
                            {user?.userDetails?.role != 0 && <th className="user-specialty">Especialidade</th>}
                            <th className="user-status">Status</th>
                        </tr>

                        <tbody className="user-table-body">
                            {
                                loading ?
                                    <Squares color="#9a1915" size={24} speed={1} animating={true} />
                                    : users.length > 0 ?
                                        users
                                            .map((actualUser: IUserToEdit) =>
                                                <tr>
                                                    <td className="nif">{actualUser.nif}</td>
                                                    <td className='user-name'>{actualUser.name}</td>
                                                    <td className='user-role'>{actualUser.role === 2 ? "Consultor" : actualUser.role === 1 ? "Coordenador" : ""}</td>
                                                    <td className="user-unity">{actualUser.unities.map((unity: any) => unity.name).join(', ')}</td>
                                                    {
                                                        user?.userDetails?.role == 1 &&
                                                        <td className="user-specialty">
                                                            <div className="create-evaluation-tech-products-selected-container">
                                                                {
                                                                    actualUser.specialties.map((specialty) => {
                                                                        return (

                                                                            <div className="create-evaluation-tech-products-selected-card">
                                                                                <p>{specialty.name}</p>
                                                                            </div>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </td>
                                                    }

                                                    <td className="user-status">
                                                        <div className="status-wrapper">
                                                            <div className="status-indicator" data-status={`${actualUser.isActive}`} />
                                                            <span>{actualUser.isActive == 1 ? 'Ativo' : 'Inativo'}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        : <div className="table-item-not-send">
                                            <p> Não foi encontrado nenhum registro correspondente</p>
                                        </div>
                            }
                        </tbody>
                    </table>
                    <footer>
                        <span>{`No total existem ${totalUsers} usuários`} </span>
                    </footer>
                </div>

            </div>
            {currentModal}
        </>
    )
}

export default DataTableVisualization;