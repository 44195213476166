import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-activity";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";

import HeaderHelmetComponent from "../Layout/HeaderHelmet";

import { signInFromToken } from "../../store/slices/user";
import company, { storeCompany } from "../../store/slices/company";
import {
    storeDiagnostic,
    removeDiagnostic,
} from "../../store/slices/diagnostic";
import { removeCompanyFilter } from "../../store/slices/companyFilter";

import authService from "../../services/auth";
import consultantService from "../../services/consultant";
import companyService from "../../services/enterprise";
import AlertMessage from "../utils/Alert";
import { applyCnpjMask, removeCnpjMask } from "../../utils/masks";
import { ILogin } from "../../interfaces/Login";
import { getLinkDownloadApk } from "../../services/apk";

import "react-activity/dist/library.css";

const loginCredentialsInitialState: ILogin =
    process.env.REACT_APP_ENV === "development"
        ? {
            user: "",
            password: "",
        }
        : ({} as ILogin);

const LoginComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingForgotMyPass, setIsLoadingForgotMyPass] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [state, setState] = useState<ILogin>(loginCredentialsInitialState);
    const [linkApk, setLinkApk] = useState<any>();

    const getLinkApk = async () => {
        try {
            let { data } = await getLinkDownloadApk();
            let apkInfo = data.data.find(
                (e: any) => e.environment === process.env.REACT_APP_NODE_ENV && e.active
            );
            setLinkApk(apkInfo);
        } catch (error: any) {
            setAlertText(error.code);
            setAlertShow(true);
            setAlertConfirm(false);
            setTimeout(() => {
                setAlertShow(false);
            }, 3000);
        }
    };

    const enterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode == 13) {
            login();
        }
    };

    const handleChange = (evt: { target: HTMLInputElement }) => {
        const value = evt.target.value;

        setState({
            ...state,
            [evt.target.name]:
                !value.includes("@") &&
                    RegExp("[0-9]").test(value) &&
                    evt.target.name != "password"
                    ? value
                    : value,
        });
    };

    const forgotPassword = async () => {
        if (state.user != "") {
            if (isLoadingForgotMyPass) return;

            setIsLoadingForgotMyPass(true);

            if (state.user.includes("@")) {
                await consultantService.recoverUserPassword(state.user).then((res) => {
                    if (res.status == 200) {
                        setIsLoadingForgotMyPass(false);
                        setAlertText("Email enviado com sucesso!");
                        setAlertConfirm(true);
                        setAlertShow(true);
                        setTimeout(() => {
                            setAlertShow(false);
                            setAlertConfirm(true);
                        }, 3000);
                    } else {
                        setIsLoadingForgotMyPass(false);
                        setAlertText("Não foi possivel encontrar o usuário!");
                        setAlertShow(true);
                        setAlertConfirm(false);
                        setTimeout(() => {
                            setAlertShow(false);
                        }, 3000);
                        return;
                    }
                });
            } else {
                await companyService
                    .recoverCompanyPassword(removeCnpjMask(state.user))
                    .then((res) => {
                        if (res.status == 200) {
                            setIsLoadingForgotMyPass(false);
                            setAlertText("Email enviado com sucesso!");
                            setAlertConfirm(true);
                            setAlertShow(true);
                            setTimeout(() => {
                                setAlertShow(false);
                                setAlertConfirm(true);
                            }, 3000);
                        } else {
                            setIsLoadingForgotMyPass(false);
                            setAlertText("Não foi possivel encontrar o usuário!");
                            setAlertShow(true);
                            setAlertConfirm(false);
                            setTimeout(() => {
                                setAlertShow(false);
                            }, 3000);
                            return;
                        }
                    });
            }
        } else {
            setIsLoadingForgotMyPass(false);
            setAlertText("Não foi possivel encontrar o usuário!");
            setAlertShow(true);
            setAlertConfirm(false);
            setTimeout(() => {
                setAlertShow(false);
            }, 3000);
            return;
        }
    };

    const login = async () => {
        if (isLoading) return;

        setIsLoading(true);

        if (state.password == "") {
            setAlertText("Usuário ou senha inválidos!");
            setAlertConfirm(false);
            setIsLoading(false);
            setAlertShow(true);
            setTimeout(() => {
                setAlertShow(false);
            }, 3000);
            return;
        }

        try {
            const { data, statusCode } = await authService.authUser(
                !state.user.includes("@") && RegExp("[0-9]").test(state.user)
                    ? removeCnpjMask(state.user)
                    : state.user,
                state.password
            );

            if (statusCode === 200) {
                let tokenDecoded: any = jwt(data);

                dispatch(signInFromToken(data));

                // TODO: remover utilização do storage
                localStorage.setItem("token", data);

                if (tokenDecoded.companyDetails?.id?.length >= 1) {
                    await companyService
                        .getDiagnosticsByCompanyId(tokenDecoded.companyDetails.id)
                        .then((res) => {
                            dispatch(
                                storeCompany({
                                    companyContext: {
                                        ...res,
                                        diagnostics: res.__diagnostics__,
                                    },
                                })
                            );

                            dispatch(storeDiagnostic(res.__diagnostics__[0]));
                        });
                }

                navigate(
                    tokenDecoded.companyDetails?.id?.length >= 1
                        ? "/enterprise/journey"
                        : tokenDecoded.userDetails.isActive == 1
                            ? "/admin/journey"
                            : "/"
                );
            } else if (statusCode === 403) {
                setAlertText("Nenhuma unidade vinculada ao perfil!");
                setAlertConfirm(false);
                setAlertShow(true);
                setTimeout(() => {
                    setAlertShow(false);
                }, 3000);
            } else {
                setAlertText("Usuário ou senha inválidos!");
                setAlertConfirm(false);
                setAlertShow(true);
                setTimeout(() => {
                    setAlertShow(false);
                }, 3000);
            }
        } catch (error: any) {
            console.error(error);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getLinkApk();
    }, []);

    useEffect(() => {
        let localToken: any = localStorage.getItem("token");
        let tokenDecoded: any;

        if (localToken) {
            tokenDecoded = jwt(localToken);

            if (tokenDecoded.exp) {
                if (tokenDecoded.exp * 1000 > Date.now()) {
                    dispatch(signInFromToken(localToken));

                    navigate(
                        tokenDecoded.companyDetails?.id?.length >= 1
                            ? "/enterprise/journey"
                            : "/admin/journey"
                    );
                } else {
                    localStorage.removeItem("token");

                    navigate("/");
                }
            }
        } else {
            dispatch(removeDiagnostic());

            navigate("/");
        }
        dispatch(removeCompanyFilter());
    }, []);

    return (
        <>
            <HeaderHelmetComponent nestedPage="Login" />
            <div className="login-box">
                <div className="login-box-left">
                    <div className="login-box-left-logo">
                        <img src="/assets/images/logoSenai.png" alt="logo-senai" />
                    </div>
                </div>

                <div className="login-box-right">
                    <div className="login-box-right-container">
                        <div className="login-box-right-form">
                            <h3>Login</h3>

                            <form>
                                <div className="login-box-right-form-field">
                                    <label>Usuário:</label>
                                    <input
                                        onKeyUp={(e) => enterPress(e)}
                                        type={"text"}
                                        name={"user"}
                                        value={state.user}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="login-box-right-form-field">
                                    <label>Senha:</label>
                                    <input
                                        onKeyUp={(e) => enterPress(e)}
                                        type={"password"}
                                        name={"password"}
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div
                                    className="login-box-right-form-button"
                                    onClick={() => login()}
                                >
                                    <button disabled={isLoading} type="button">
                                        {isLoading ? (
                                            <Spinner
                                                color="#FFF"
                                                size={21}
                                                speed={1}
                                                animating={true}
                                            />
                                        ) : (
                                            "Entrar"
                                        )}
                                    </button>
                                </div>
                            </form>

                            <div className="login-box-right-forgot-pass">
                                <button
                                    disabled={isLoadingForgotMyPass}
                                    onClick={() => forgotPassword()}
                                >
                                    {isLoadingForgotMyPass ? (
                                        <Spinner
                                            color="#FFF"
                                            size={21}
                                            speed={1}
                                            animating={true}
                                        />
                                    ) : (
                                        <span>Esqueci minha senha</span>
                                    )}
                                </button>
                            </div>

                            <div className="mobile-download">
                                <p>
                                    A aplicação web do SGJD não da suporte a mobile, por favor
                                    baixe nosso app
                                </p>
                                <a
                                    href={linkApk?.link ?? "./favicon.ico"}
                                    download={linkApk?.link}
                                >
                                    <img
                                        src="/assets/icons/android-icon-white.png"
                                        alt="android"
                                    />
                                    <span>Baixar App android</span>
                                </a>
                            </div>

                            <span className="warning-system-migration">
                                {/* Esta versão está disponivel apenas para visualização. acesse a{" "}
                                <a
                                    href="https://jornadadigital127-prod-front.azurewebsites.net/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    nova versão
                                </a>{" "}
                                para realizar novos diagnosticos. */}
                                A plataforma está disponivel apenas para visualização por conta da atualização para a nova versão.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <AlertMessage
                    confirm={alertConfirm}
                    visible={alertShow}
                    message={alertText}
                />
            </div>
        </>
    );
};

export default LoginComponent;
