import { FormEvent, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import TextInputComponent from "./Inputs/TextInput";
import CheckboxInputComponent from "./Inputs/CheckboxInput";
import DateInputComponent from "./Inputs/DateInput";
import RadioInputComponent from "./Inputs/RadioInput";

import evaluationService from "../../../../services/evaluation";
import CompanyService from "../../../../services/enterprise"
import { useAppSelector } from "../../../../store";
import { useDispatch } from "react-redux";
import { storeCompany } from "../../../../store/slices/company";
import AlertMessage from "../../../utils/Alert";
import { usersPermissionAdmin } from "../../../../utils/utils";
import { Squares } from "react-activity";
import { create } from "../../../../services/RegistryReport";

interface IAnswer {
    id: string
    name: string
    description: string
    ramification: string
    weight: number
    sequenceIndex: number
    createdAt: string
    updatedAt: string
}
interface IQuestion {
    id: string
    type: string
    name: string
    description: string
    isWanted: boolean
    isRamified: boolean
    sequenceIndex: number
    weight: number
    answers: IAnswer[]
}

interface IHub {
    id: string
    name: string
    questions: IQuestion[]
    createdAt: any
}

interface IReportRegistryEvaluationPageProps {
    viewOnly?: boolean
    evaluationId: string | undefined
    proposal: any
}

const ReportRegistryEvaluationPage: React.FC<IReportRegistryEvaluationPageProps> = ({ viewOnly, evaluationId, proposal }) => {
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertConfirm, setAlertConfirm] = useState(false);
    const [evaluationName, setEvaluationName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [sections, setSections] = useState<IHub[]>([]);
    const [mandatoryQuestions, setMandatoryQuestions] = useState<string[]>([]);
    const { company } = useAppSelector(state => state.company);
    const { user } = useAppSelector(state => state.user);
    const { diagnostic } = useAppSelector(state => state.diagnostic);

    const [view, setView] = useState(false);

    const [reportAnswers, setReportAnswers] = useState<any>([]);
    const [sectionsToRender, setSectionsToRender] = useState<string[]>([]);
    const [aux, setAux] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const permissionsUnity = () => {
        const evaluation = company.companyContext?.evaluations?.find((eva: any) => eva?.id === evaluationId)
        const diag = company.companyContext?.__diagnostics__?.find((diagnostic: any) =>
            evaluation?.tech_products?.some((eva: any) => diagnostic?.specific_title?.includes(eva.name))
        )
        return user.userDetails?.unities?.map((unity: { name: string }) => unity?.name)?.includes(diag?.unity)
    }

    const forceReload = () => setAux(!aux);

    const getQuestions = async () => {

        setLoading(true)

        if (!evaluationId) {
            setLoading(false)
            return
        }

        const evaluation = await evaluationService.getEvaluationById(evaluationId);




        let registreReportResponse = (company.companyContext.registry_reports?.find((rp: any) => {
            return rp.diagnostic.proposal_number === proposal.proposal_number
        }))


        const sortedHubs = evaluation.hubs.sort((a: IHub, b: IHub) => a.createdAt < b.createdAt ? -1 : 1).map((hub: IHub) => {
            return {
                ...hub,
                questions: hub.questions.sort((a: IQuestion, b: IQuestion) => a.sequenceIndex > b.sequenceIndex ? 1 : -1)
            } as IHub
        })

        if (registreReportResponse) {
            setView(true)
            let reportAnswersRes = (registreReportResponse.registry_result.map((hub: any) => {
                return hub.map((r: any) => {
                    return {
                        name: r.name,
                        answers: r.answers,
                        type: r.type,
                        number: r.number,
                        id: r.id
                    }
                })
            }))

            setReportAnswers(reportAnswersRes);
        } else {

            if (permissionsUnity())
                setView(false)
            else
                setView(true)

            if (usersPermissionAdmin(user.userDetails?.role))
                setView(false)

            setReportAnswers(sortedHubs.map((hub: IHub,) =>
                hub.questions.map((question: IQuestion) => {
                    return {
                        name: question.name,
                        answers: [
                            {
                                result: ''
                            }
                        ],
                        type: question.type,
                        number: question.sequenceIndex,
                        id: question.id
                    }
                })
            ))
        }

        setEvaluationName(evaluation.name)

        setSections(sortedHubs)

        setSectionsToRender([sortedHubs[0].id])

        setLoading(false)
    }

    const getMandatoryQuestions = () => {
        sections.map((section: IHub) => section.questions
            .filter((question: any) => question.isWanted)
            .map(q => {
                setMandatoryQuestions([...mandatoryQuestions, q.id])
            }))
    }

    const handleAnswer = (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string, regex?:(value:string)=>string) => {
        const sectionIndex = sections.indexOf(sections.find((section: IHub) => section.id === sectionId) as IHub)

        let newReportAnswers = reportAnswers
        newReportAnswers[sectionIndex][index] = {
            ...newReportAnswers[sectionIndex][index],
            answers: [{
                result: regex ? regex(event.target.value) : event.target.value
            }]
        }


        setReportAnswers(newReportAnswers)

        forceReload()
    }

    const getSectionsToRender = () => {
        const selectedAnswers = reportAnswers.map((section: any[]) => section.map((questions: any) => questions.answers[0].result).filter((question: string) => question != ''))

        const ramificationIds: string[] = []

        sections.forEach((section: IHub, sectionIndex: number) =>
            section.questions.forEach(question =>
                question.answers.filter((answer) =>
                    selectedAnswers[sectionIndex].includes(answer.name)
                ).forEach((_answer) => ramificationIds.push(_answer.ramification))
            )
        )

        setSectionsToRender([
            sections[0]?.id,
            // ...ramificationIds.map(id => sections.find(section => section.id === id) as IHub)
            ...sections.filter((section) => ramificationIds.includes(section.id)).map((section) => section.id)
        ])
        const element:any = document.activeElement
        if(element?.selectionEnd && localStorage.getItem("stateInput") !== 'null') element.selectionEnd = element.selectionStart = Number(localStorage.getItem("stateInput"));
    }

    const handleAnswerCheckBox = (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string) => {
        const sectionIndex = sections.indexOf(sections.find((section: IHub) => section.id === sectionId) as IHub)

        if (event.target.checked) {

            const newReportAnswers = reportAnswers
            newReportAnswers[sectionIndex][index].answers = (!newReportAnswers[sectionIndex][index].answers[0]?.result) ?
                [{ result: event.target.value }]
                :
                [...newReportAnswers[sectionIndex][index].answers, { result: event.target.value }]


            setReportAnswers(newReportAnswers)
            forceReload()

        } else {
            const newReportAnswers = reportAnswers
            let newAnswers = newReportAnswers[sectionIndex][index]?.answers.filter((answer: any) => answer.result != event.target.value)

            newAnswers = (newAnswers.length > 0) ? newAnswers : [{ result: '' }]

            newReportAnswers[sectionIndex][index].answers = newAnswers

            setReportAnswers(newReportAnswers)
            forceReload()
        }
        return reportAnswers[sectionIndex][index]
    }

    const thisQuestionToBeRendered = (section: IHub, sectionIndex: number, question: IQuestion, questionIndex: number, isAnswered?: boolean, res?: any) => {

        switch (question.type) {
            case "Dissertativa":
                return <TextInputComponent name={question.name} disabled sectionId={section.id} key={question.id} handleAnswer={handleAnswer} enunciated={question.description} questionNumber={(questionIndex) + 1} isWanted={question.isWanted} textValue={isAnswered ? res : reportAnswers[sectionIndex][questionIndex]?.answers[0]?.result || ""} />
            case "Sim/Nao":
                return <RadioInputComponent disabled sectionId={section.id} key={question.id} answerSelect={isAnswered ? res : reportAnswers[sectionIndex][questionIndex]?.answers[0]?.result} handleAnswer={handleAnswer} answers={question.answers} enunciated={question.description} questionNumber={(questionIndex + 1)} questionId={question.id} isWanted={question.isWanted} />
            case "Alternativa":
                return <RadioInputComponent disabled sectionId={section.id} key={question.id} answerSelect={isAnswered ? res : reportAnswers[sectionIndex][questionIndex]?.answers[0]?.result} handleAnswer={handleAnswer} answers={question.answers} enunciated={question.description} questionNumber={(questionIndex + 1)} questionId={question.id} isWanted={question.isWanted} />
            case "Múltipla Escolha":
                return <CheckboxInputComponent disabled sectionId={section.id} key={question.id} answerSelect={isAnswered ? res : reportAnswers[sectionIndex][questionIndex]?.answers} handleAnswer={handleAnswerCheckBox} answers={question.answers} enunciated={question.description} questionNumber={(questionIndex + 1)} isWanted={question.isWanted} />
            case "Date":
                return <DateInputComponent disabled sectionId={section.id} key={question.id} textValue={isAnswered ? res : reportAnswers[sectionIndex][questionIndex]?.answers[0]?.result || ""} handleAnswer={handleAnswer} enunciated={question.description} questionNumber={(questionIndex + 1)} isWanted={question.isWanted} />
            default:
                break
        }
    }

    const renderQuestions = () => {
        return (
            sections.map((section: IHub, sectionIndex: number) => {
                let breakpoint = true
                let isAnswered: any
                let answer: any
                if (sectionsToRender.includes(section.id)) {
                    return (
                        <>
                            <div>
                                <h4 className="section-title">
                                    {section.name}
                                </h4>
                                {
                                    section?.questions?.map((question: IQuestion, index: number) => {
                                        if (view) {
                                            isAnswered = reportAnswers.map((element: any, elementIndex: number) => element.filter((a: any, elementIndex2: number) => {
                                                if (reportAnswers[elementIndex][elementIndex2].answers[0].result !== '' && question.id === reportAnswers[elementIndex][elementIndex2].id) {
                                                    answer = reportAnswers[elementIndex][elementIndex2].answers[0].result
                                                    return true
                                                } else {
                                                    return false
                                                }
                                            }));
                                        }
                                        if (breakpoint) {
                                            if (isAnswered?.length > 1) {
                                                return thisQuestionToBeRendered(section, sectionIndex, question, index, true, answer)
                                            }
                                            else if (section?.questions?.filter(q => q.answers.find(a => a.ramification)).filter(fq => fq.id == question.id).length == 0) {
                                                return thisQuestionToBeRendered(section, sectionIndex, question, index)
                                            } else if (question?.answers.find(a => a.name == reportAnswers[sectionIndex][index]?.answers[0]?.result)?.ramification == null && reportAnswers[sectionIndex][index]?.answers[0]?.result?.length > 0) {
                                                breakpoint = true
                                                return thisQuestionToBeRendered(section, sectionIndex, question, index)
                                            }
                                            else {
                                                breakpoint = false
                                                return thisQuestionToBeRendered(section, sectionIndex, question, index)
                                            }
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </div>
                        </>
                    )
                }
            }
            )
        )
    }

    const populateCompanyContext = async (companyId: string) => {
        try {
            const res = await CompanyService.getCompany(companyId);
            const res2 = await evaluationService.getEvaluationByTechProductName(res?.__diagnostics__?.map((diag: any) => {
                return diag.specific_title.replace(/,/g, '-----')
            }).toString().replace(/,/g, '--').replace(/-----/g, ','));

            dispatch(storeCompany({
                companyContext: {
                    ...res,
                    evaluations: res2?.data,
                    diagnostics: res.__diagnostics__.sort((diag: any) => {
                        return diag?.id === companyId ? -1 : 1
                    })
                }
            }));

        } catch (error) {
            console.log(error);
        }
    }

    const saveReport = async (e: FormEvent) => {
        e.preventDefault();

        setLoading(true);

        const mandatoryFilter = reportAnswers?.filter((report: any) => mandatoryQuestions?.find((id: string) => id === report.id)).filter((report: any) => report.answers[0].result === "").length
        if (mandatoryFilter === 0) {

            const reportToSave = {
                name: evaluationName,
                registry_result: reportAnswers,
                diagnostic_name: evaluationName,
                company_name: company.companyContext.name,
                consultant_name: user.userDetails?.name,
                diagnostic: {
                    id: proposal.id
                },
                company: {
                    id: company.companyContext.id
                },
                consultant: {
                    id: user.userDetails?.id
                }
            }

            if (usersPermissionAdmin(user.userDetails?.role)) {
                setAlertMessage('Simulação realiazada com perfil admin!')
                setAlertConfirm(true)
                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)
                    navigate(-1)
                }, 2000)

                return
            }

            try {
                let res = await create(reportToSave);
                if (res.data) {
                    populateCompanyContext(company.companyContext.id)
                    setAlertMessage('Registro salvo com sucesso!')
                    setAlertConfirm(true)
                    setAlertShow(true)
                    setTimeout(() => {
                        setAlertShow(false)
                        navigate(-1)
                    }, 2000)

                } else {
                    console.log(res);
                }
            } catch (error) {
                setAlertMessage('Erro ao salvar registro de conclusão!')
                setAlertConfirm(false)
                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)
                }, 2000)

                console.error(error);
            }

        } else {
            console.log(`faltam ${mandatoryFilter}`)
        }

        setLoading(false);

    }

    const formCompleted = useRef<HTMLFormElement>(null)

    useEffect(() => { getQuestions() }, [evaluationId, proposal])
    useEffect(() => { getMandatoryQuestions() }, [sections])
    useEffect(() => { getSectionsToRender() }, [reportAnswers, aux])

    return (
        <>
            <div className="evaluation-card">
                {
                    isLoading ?
                        <div className="loading-container">
                            <Squares />
                        </div>
                        :
                        evaluationId ?
                            <>
                                <div className="evaluation-card-header">
                                    <div className="evaluation-title-container">
                                        <h3 className="evaluation-title">
                                            {evaluationName} {(viewOnly || view) && <>- Visualização</>}
                                        </h3>
                                        <hr />
                                    </div>
                                </div>
                                <form className="evaluation-main-container" ref={formCompleted} onSubmit={(e) => saveReport(e)}>
                                    <div className="question-container">
                                        {renderQuestions()}
                                    </div>
                                    {
                                        (!view) &&
                                        <div className="evaluation-actions-container">
                                            <button className="evaluation-button" type="submit" disabled>Finalizar avaliação</button>
                                        </div>
                                    }
                                </form>
                            </>
                            :
                            <div className="evaluation-card-header">
                                <div className="evaluation-title-container">
                                    <h3 className="evaluation-title">
                                        Selecione uma proposta
                                    </h3>
                                    <hr />
                                </div>
                            </div>
                }
            </div>
            <AlertMessage
                visible={alertShow}
                message={alertMessage}
                bgColor={'#fff'}
                confirm={alertConfirm} />
        </>
    );
};

export default ReportRegistryEvaluationPage;