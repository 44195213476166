import React, { useEffect, useState } from 'react';

import HeaderHelmetComponent from '../Layout/HeaderHelmet';
import ChartComponent from '../utils/Chart';
import AvaliationModalComponent from './Modals/AvaliationModal';
import ConfirmationModalComponent from './Modals/ConfirmationModal';
import DiagnosticModalComponent from './Modals/DiagnosticModal';
import "react-activity/dist/library.css";
import { Squares } from "react-activity";

import evaluationService from "../../services/evaluation"
import { applyCnpjMask } from '../../utils/masks';
import { DiagnosticEvaluationGeneratePDF } from './DiagnosticEvaluationGeneratePDF';
import { useAppSelector } from '../../store';

import imgLogo from './assets/logo_red_new.png'
import downloadIcon from './assets/download_icon.svg'
import { getAllTechDiagnostics, getDiagnosticsByProposalId } from '../../utils/utils';

interface IProposalComponentAlert {
  arrayAlert: any,
  isReviewed: boolean
  proposalId:string
}

const ProposalsComponent = (props: IProposalComponentAlert) => {
  const [hubs, setHubs] = useState<any[]>([]);
  const [quizData, setQuizData] = useState<[]>([]);
  const [description, setDescription] = useState("");
  const [currentModal, setCurrentModal] = useState<any>();
  const [buttonDownloadVisible, setButtonDownloadVisible] = useState(false);
  const [imagesBase64, setImagesBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatsToBase64, setChartsToBase64] = useState<HTMLCollectionOf<HTMLCanvasElement> | null>(null);

  const { company } = useAppSelector(state => state.company);

  const allDiagnostics = getAllTechDiagnostics(company.companyContext);
  const diagnostic = getDiagnosticsByProposalId(allDiagnostics,props?.proposalId);

  const indexToChar = ["1","2","3","4","5","6","7","8","9","10"]

  const toMonthName = (monthNumber: any) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('pt-BR', {
      month: 'long',
    });
  }

  const selectedDateTime = diagnostic?.updatedAt || "-"

  var splitarray = new Array();
  splitarray = selectedDateTime.split("-");

  const stateLocation = company.companyContext?.uf

  const setProposalDateAndUf = (stateLocation: string, splitarray: string[]) => {
    return stateLocation + ", " + splitarray[2]?.substring(0, 2) + " de " + toMonthName(splitarray[1]) + " de " + splitarray[0]
  }

  const companyAgrees = false;

  const modals = {
    none: <></>,
    confirmation: <ConfirmationModalComponent negativeAction={() => setCurrentModal(modals.none)} positiveAction={() => setCurrentModal(modals.avaliation)} />,
    diagnostic: <DiagnosticModalComponent closeModal={setCurrentModal} companyAgrees={companyAgrees} setAlert={props.arrayAlert} onSend={(description: string) => {
      setDescription(description)
    }} />,
    avaliation: <AvaliationModalComponent isReviewed={props.isReviewed} closeModal={setCurrentModal} companyAgrees={companyAgrees} setAlert={props.arrayAlert} description={description} setDowloadVisibleButton={setButtonDownloadVisible} />
  }

  let journeyStepNumber: number = 2

  if (diagnostic?.jorney_step[0]?.currentStep) {
    journeyStepNumber = diagnostic?.jorney_step[0]?.currentStep || 4
  } else {
    journeyStepNumber = 4
  }

  let dataToReturn: any = []

  const returnCriteriaResultsToPopulateChart = (criteria: any, criteriaIndex: any, question: any, questionIndex: number, hubIndex: number) => {

    const answerSelected: any = quizData.find((answer: any) => answer.data.questionName === question.name)

    if (answerSelected) {
      if (criteriaIndex == 0 && dataToReturn.length < hubs.length) {
        dataToReturn = [...dataToReturn, []]
      }
      if (questionIndex == 0) {
        dataToReturn[hubIndex] = [...dataToReturn[hubIndex], {
          [`${criteriaIndex + 1}.${indexToChar[questionIndex]}`]: answerSelected.data.answerWeight
        }]
      } else {
        dataToReturn[hubIndex][criteriaIndex] = { ...dataToReturn[hubIndex][criteriaIndex], [`${criteriaIndex + 1}.${indexToChar[questionIndex]}`]: answerSelected.data.answerWeight }
      }
    }
  }

  const currentStep = diagnostic?.jorney_step[0]?.currentStep?.toString() || 4
  const nextStep = diagnostic?.jorney_step[0]?.nextStep == null ? "" : diagnostic?.jorney_step[0]?.nextStep

  const getStepText = (currentStep: string, nextStep: string) => {
    if (currentStep == "3" && nextStep == "") return "PROCESSO PRODUTIVO EM FASE DE OTIMIZAÇÃO"
    else if (currentStep == "4" && nextStep == "") return "PROCESSO PRODUTIVO EM FASE INICIAL DE OTIMIZAÇÃO"
    else if (currentStep == "6" && nextStep == "") return "PROCESSO PRODUTIVO EM FASE DE TRANSIÇÃO PARA A INDÚSTRIA 4.0"
    else if (currentStep == "5" && nextStep == "6") return "PROCESSO PRODUTIVO EM FASE INICIAL DE DIGITALIZAÇÃO"
    else return "PROCESSO PRODUTIVO EM FASE DE TRANSIÇÃO PARA A INDÚSTRIA 4.0"
  }
  const printRef = React.useRef(null);

  const getStepImage = (currentStep: string, nextStep: string) => {
    if (currentStep == "3" && nextStep == "") return "/assets/images/reports/jorney_step_3.png"
    else if (currentStep == "4" && nextStep == "") return "/assets/images/reports/jorney_step_4.png"
    else if (currentStep == "6" && nextStep == "") return "/assets/images/reports/jorney_step_6.png"
    else if (currentStep == "5" && nextStep == "6") return "/assets/images/reports/jorney_step_5_and_6.png"
    else return "/assets/images/reports/jorney_step_6_and_7.png"
  }

  const populateHub = async () => {
    setHubs(company.companyContext?.evaluations?.find((evaluation:any)=>evaluation?.name==="Avaliação Diagnóstica")?.hubs)
  }

  useEffect(() => {
    setCurrentModal(modals.avaliation)
  }, [description])

  useEffect(() => {
    setCurrentModal(modals.diagnostic)
  }, [companyAgrees])

  useEffect(() => {

  }, [buttonDownloadVisible])

  useEffect(() => {
    setCurrentModal(modals.none)
  }, [])

  useEffect(() => {
    populateHub();

    setQuizData(props.isReviewed ? diagnostic?.reviewed_diagnostic_quiz : diagnostic?.diagnostic_quiz);
  }, [diagnostic, props.isReviewed]);

  useEffect(() => {
    setChartsToBase64(document.getElementsByTagName('canvas'));
  }, []);
  
  return (
    <>
      <HeaderHelmetComponent nestedPage={`Proposta ${diagnostic?.proposal_number}`} />

      <div className="proposals-page-container">
        <div className='proposals-page-header-container'>
            <h2 className='title-page'>
              AVALIAÇÃO DIAGNÓSTICA
            </h2>
        </div>
        <div className='proposals-card-container'>
          <div className="options-container">
            {!isLoading ?
            <button onClick={() => DiagnosticEvaluationGeneratePDF({ diagnosticContext: diagnostic, hubs, quizData, companyContext: company.companyContext, currentStep, nextStep, imagesBase64: chatsToBase64 })} className='option-button' type="button">
              <img src={downloadIcon} alt="Download" />
            </button> : null
            }
          </div>
          <div className="main">
            {!isLoading ?
              <div className="relatory-container">
                  <div className="relatory" ref={printRef}>
                    <div className="logo-container">
                      <img src={imgLogo} />
                    </div>

                    <span className="division-line"></span>

                    <span className="date-text">{setProposalDateAndUf(stateLocation, splitarray)}</span>

                    <img className="senai-logo" src="/assets/images/logo-old.png" />

                    <span className="title">AVALIAÇÃO DIAGNÓSTICA SENAI SP</span>

                    <span className="enterprise-info">{company.companyContext?.name}</span>

                    <span className="enterprise-info">CNPJ: {applyCnpjMask(company.companyContext?.CNPJ)}</span>

                    <span className="stage-title">Etapa da Jornada SENAI de Transformação Digital que a Empresa se encontra nesse momento:</span>

                    <span className="process-title">{getStepText(currentStep, nextStep)}</span>

                    <img className="elevator-image" src={`${getStepImage(currentStep, nextStep)}`} />

                    <div className='action-plans'>
                      <span className="stage-title">Plano de Ações Recomendadas</span>
                      {diagnostic?.plans?.map((plan: any) => {
                        return <>
                          <span className="stage-title" style={{fontWeight: "normal"}}><code>•</code> {plan.name}</span>
                        </>
                      })}
                    </div>



                    <span className="stage-title">DESEMPENHO ATUAL DA EMPRESA POR EIXO AVALIADO</span>
                    {
                      hubs && hubs.map((hub: any, hubIndex: number) => {
                        return (
                          <div className="axle-container">
                            <span className="axle-title">{hubIndex + 1} - {hub.name}</span>
                            <div className="macro-container">
                              {hub.criterion.map((criteria: any, criteriaIndex: number) => {
                                return quizData && quizData.find((quiz: any) => quiz.criteriaId == criteria.id) && (
                                  <>
                                    <span className="macro-title">MACRO INDICADOR</span>

                                    <span>{criteriaIndex + 1}. {criteria.name}</span>

                                    {criteria.questions.filter((question: any) => question.type != 'Tabela').map((question: any, questionIndex: number) => {
                                      return quizData && quizData.find((quiz: any) => quiz.data.questionName == question.name) && (
                                        <>
                                          <div className="question-container">
                                            <span className="question-title">{criteriaIndex + 1}.{questionIndex + 1}. {question.name}</span>

                                            <span className="question-enuncied">
                                              {question.description.match(/De acordo com a tabela|declarados na tabela acima/) ? ''  : question.description} 
                                            </span>

                                            <span className="question-resp">
                                              {question.answers.map((answer: any, answerIndex: number) => {
                                                return quizData && quizData.find((quiz: any) => quiz.data.answerName == answer.name) && (
                                                  answer.description
                                                )
                                              })}
                                            </span>
                                          </div>
                                          {
                                            returnCriteriaResultsToPopulateChart(criteria, criteriaIndex, question, questionIndex, hubIndex)
                                          }
                                        </>
                                      )
                                    })}
                                    <div className='chart-container' id="graphic">
                                      <ChartComponent title='' data={dataToReturn[hubIndex][criteriaIndex]} setImagesBase64={setImagesBase64} imagesBase64={imagesBase64} />
                                      <div className="subtitle-container">
                                        <h4>Legenda:</h4>
                                        {criteria.questions.filter((question: any) => question.type != 'Tabela').map((question: any, questionIndex: number) => {
                                          return quizData && quizData.find((quiz: any) => quiz.data.questionName == question.name) && (
                                            <>
                                              <div className="subtitle">
                                                <span>{criteriaIndex + 1}.{questionIndex + 1}. {question.name}</span>
                                              </div>
                                            </>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                              )}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
              </div>
              : 
              <div className='loading-container'>
                  <span className='loading-text' >Carregando o documento</span>
                  <Squares size={60} color='#9a1915'/>
              </div>
            }
          </div>
        </div>
        {currentModal}
      </div>
    </>
  );
};

export default ProposalsComponent;