import { useEffect } from "react";

type SemaphoreProps = {
  efficiency: { [key: string]: number };
};

export default function TrafficLight({ efficiency }: SemaphoreProps) {

  const efficiencyGradeTotal = Object.values(efficiency)[0];

  const color =
    efficiencyGradeTotal >= 0
      ? "green"
      : efficiencyGradeTotal >= -10  && efficiencyGradeTotal <= 0
        ? "yellow"
        : "red";

  useEffect(() => {

  },[Object.values(efficiency)[0]])

  return (
    <div className="semaphore-container">
      <div id="image-container">
        <img
          className="image-traffic-light"
          src={`/assets/images/semaphore-${color}.svg`}
          alt="semaphore"
        />
      </div>
      <span style={{fontSize : "clamp(0.2rem, .8vw, 1.2rem)", width: "20%", textAlign: "center"}}>
        Nota: {efficiencyGradeTotal}
      </span>
    </div>
  );
}
