import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import evaluationService from "../../../../services/evaluation";
import AlertMessage from "../../../utils/Alert";
import CreateEvaluationModal from "../../modals/CreateEvaluationModal";
import CreateQuestionModal from "../../modals/CreateQuestion/CreateQuestionModal";
import EvaluationTableQuestionLine from "./EvaluationTableHubLine/EvaluationTableCriteriaLine/EvaluationTableQuestionLine/EvaluationTableQuestionLine";
import EvaluationTableHubLine from "./EvaluationTableHubLine/EvaluationTableHubLine";

interface IEvaluationTableEvaluationLine {
    reload: () => void,
    evaluation: any,
    isOdd:boolean,
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>

    },
    currentMenu: string,
    currentPlatform: string,
    setCurrentMenu: Dispatch<SetStateAction<string>>
}

const EvaluationTableEvaluationLine = ({ reload, evaluation, alert, currentMenu, setCurrentMenu, currentPlatform,isOdd }: IEvaluationTableEvaluationLine) => {
    const [drop, setDrop] = useState(false)
    const [visibilityOptions, setVisibilityOptions] = useState(false)
    const [dependencies, setDependencies] = useState<string[]>([])
    const { journeyStep } = useParams()
    const navigate = useNavigate()

    const deleteEvaluation = () => {

        evaluationService.deleteEvaluation(evaluation.id).then((res: any) => {
            try {
                if (res.data.statusCode === 200) {
                    alert.setMessage("Avaliação deletada com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    setVisibilityOptions(false)
                    reload()
                } else {
                    alert.setMessage("Ocorreu um erro ao deletar a avaliação!")
                    alert.setShow(true)
                    setTimeout(() => {
                        alert.setShow(false)
                    }, 3175)
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao deletar a avaliação!")
                alert.setShow(true)
                alert.setConfirm(true)
                setTimeout(() => {
                    alert.setShow(false)
                    alert.setConfirm(false)
                }, 3175)
            }

        })

    }


    const registerHub = (isHub: boolean) => {
        setAction("REGISTER")
        if (isHub) 
            setCurrentModal(modals.registerHub)
        else 
            setCurrentModal(modals.registerSession)
        
    }

    const reloadHub = () => {
        reload()
        setVisibilityOptions(false)
    }

    const setVisible = () => {
        setVisibilityOptions(!visibilityOptions)

    }

    const [action, setAction] = useState("")
    const [value, setValue] = useState("")

    const [currentModal, setCurrentModal] = useState<any>();
    
    const modals = {
        none: <></>,
        registerHub: <CreateEvaluationModal alert={alert} reload={reloadHub} action="REGISTER" type={"HUB"} name={value || ""} id={evaluation.id} close={() => setCurrentModal(modals.none)} />,
        registerSession: <CreateEvaluationModal alert={alert} reload={reloadHub} action="REGISTER" type={"SESSION"} name={value || ""} id={evaluation.id} close={() => setCurrentModal(modals.none)} />,
        edit: <CreateEvaluationModal alert={alert} reload={reloadHub} action="EDIT" type={"EVALUATION"} id={evaluation.id} name={evaluation.name} evaluationTechProducts={evaluation.tech_products} close={() => setCurrentModal(modals.none)} />,
        registerQuestion: <CreateQuestionModal journeyStep={evaluation.journey_step} alert={alert} relation="evaluation" reload={reload} action="REGISTER" questions={evaluation.questions} id={evaluation.id} close={() => setCurrentModal(modals.none)} />
    }

    useEffect(() => {
        if (drop == true)
            setVisibilityOptions(false)

    }, [drop])

    return (
        <>
            <tr onClick={() => setDrop(!drop)} className={drop || visibilityOptions ? "evaluation-table-line dropped" : "evaluation-table-line"}>
                <td>Avaliação</td>
                <td>
                    <div className="evaluation-table-name-container">
                        <div>
                            {evaluation.hubs.length > 0 || evaluation.questions.length > 0 ?
                                <div className={drop ? "evaluation-table-name-image-container dropped" : "evaluation-table-name-image-container"}>
                                    <img src="/assets/icons/right-arrow-gray.png" alt="" />
                                </div>
                                : null
                            }

                            <div className="evaluation-table-name">{evaluation.name}</div>
                        </div>

                        <div className="evaluation-table-options-container">
                            <img onClick={() => {
                                setCurrentMenu(evaluation.id)
                                setVisible()

                            }} className="evaluation-table-options-more" src="/assets/icons/more-options-gray.png" alt="" />
                        </div>
                    </div>
                </td>
                {visibilityOptions && currentMenu == evaluation.id ?
                    <div className="evaluation-table-menu-container">
                        {/* {evaluation.questions.length == 0 && (journeyStep == "1" || journeyStep == "4") && <div onClick={() => registerHub()} className="evaluation-table-menu-item"> Adicionar eixo </div>} */}
                        {/* {journeyStep && parseInt(journeyStep) > 1 ? <div onClick={() => registerHub(false)} className="evaluation-table-menu-item"> Adicionar seção </div> : evaluation.questions.length == 0 ? <div onClick={() => registerHub(true)} className="evaluation-table-menu-item"> Adicionar eixo </div> : null} */}
                        {journeyStep && parseInt(journeyStep) === 1 && currentPlatform === "MOBILE" ? <div onClick={() => registerHub(true)} className="evaluation-table-menu-item"> Adicionar eixo </div> :<div onClick={() => registerHub(false)} className="evaluation-table-menu-item"> Adicionar seção </div> }
                        {evaluation.hubs.length == 0 && <div onClick={() => setCurrentModal(modals.registerQuestion)} className="evaluation-table-menu-item"> Adicionar pergunta </div>}
                        <div className="evaluation-table-menu-item" onClick={() => setCurrentModal(modals.edit)}> Editar </div>
                        {evaluation.platform === 'WEB' && <div className="evaluation-table-menu-item" onClick={() => navigate(`/admin/reports-registry/${evaluation.id}`)}> Visualizar </div>}
                        <div onClick={() => deleteEvaluation()} className="evaluation-table-menu-item"> Excluir </div>
                    </div>
                    : null}
            </tr>

            {drop === true ?
                evaluation.hubs?.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : -1).map((hub: any) => {
       
                    return (
                        <EvaluationTableHubLine journeyStep={evaluation.journey_step} currentPlatform={currentPlatform} sessions={evaluation.hubs} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} alert={alert} reload={reload} hub={hub} />
                    )
                })

                : null}

            {drop === true && evaluation.questions.length != 0 ?
                evaluation.questions?.sort((a: any, b: any) => (a.sequenceIndex > b.sequenceIndex) ? 1 : -1)
                .map((question: any, index : number, arr : any) => {
                    return (
                        <EvaluationTableQuestionLine journeyStep={evaluation.journey_step} upNeighbor={arr[index + 1]} downNeighbor={arr[index - 1]} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} alert={alert} questions={evaluation.questions} relation={"evaluation"} id={evaluation.id} question={question} reload={reload} questionId={question.id} sequence={question.sequenceIndex} key={question.id} answers={question.answers} title={question.name} dependencies={dependencies} setDependencies={setDependencies} />
                    )
                })
                : null}
            {currentModal}

        </>
    )
}

export default EvaluationTableEvaluationLine