import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AlertMessage from '../../../utils/Alert';
import ModalContainer from '../../../utils/ModalContainer';
import FormModalContainer from '../Form/FormModal';

import CompanyService from '../../../../services/companies';
import { storeCompany } from '../../../../store/slices/company';
import { useAppSelector } from '../../../../store';
import { api } from '../../../../services';

type Contact = {
    id?: string;
    name: string;
    email: string;
    phone: string;
};

type ModalContactProps = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    contact: Contact;
    contactPosition: number;
};

type FormContactInputs = {
    name: string;
    email: string;
    phone: string;
};

const ModalContact = ({ setIsModalOpen, isModalOpen, contact, contactPosition }: ModalContactProps) => {
    const [formContactInputs, setFormContactInputs] = useState<FormContactInputs>({
        name: contact.name,
        email: contact.email,
        phone: contact.phone
    });

    const [alertShow, setAlertShow] = useState(false);

    const dispatch = useDispatch();
    const { company } = useAppSelector(state => state.company);
    
    function validation() {
        const { name, email, phone } = formContactInputs;
        if (name.length === 0) {
            return false;
        }
        if (email.length === 0) {
            return false;
        }
        if (phone.length === 0) {
            return false;
        }

        return true;
    }

    async function onSubmitContactsData(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!validation()) {
            setAlertShow(true);
            setTimeout(() => {
                setAlertShow(false);
            }, 3175);
            return;
        }

        //Sending data do api
        await api
            .put('/Contacts/' + contact.id, formContactInputs)
            .then(async (response: any) => {
                if (response?.data?.hasError) {

                    setAlertShow(true);
                    setTimeout(() => {
                        setAlertShow(false);
                    }, 3175);
                } else {
                    await CompanyService.getCompanyById(company.id).then((res) => {
                        dispatch(storeCompany(res));
                    })
                    setIsModalOpen(false);
                }
            });
    }

    useEffect(() => {
        setFormContactInputs({ ...contact });
    }, [contact]);
    
    return (
        <>
            <ModalContainer setIsOpen={setIsModalOpen} isOpen={isModalOpen}>
                <FormModalContainer
                    onSubmit={(e) => onSubmitContactsData(e)}
                    setIsModalOpen={setIsModalOpen}
                    title={`Editar dados do ${contactPosition}º contato:`}
                    buttonSubmitText="Atualizar contato"
                >
                    <input
                        onChange={(e) =>
                            setFormContactInputs({
                                ...formContactInputs,
                                name: e.target.value,
                            })
                        }
                        value={formContactInputs.name}
                        type="text"
                        placeholder="Contato"
                    />
                    <input
                        onChange={(e) =>
                            setFormContactInputs({
                                ...formContactInputs,
                                email: e.target.value,
                            })
                        }
                        value={formContactInputs.email}
                        type="email"
                        placeholder="E-mail"
                    />
                    <input
                        onChange={(e) =>
                            setFormContactInputs({
                                ...formContactInputs,
                                phone: e.target.value,
                            })
                        }
                        value={formContactInputs.phone}
                        type="tel"
                        placeholder="Telefone"
                    />
                </FormModalContainer>
            </ModalContainer>
            <AlertMessage visible={alertShow} bgColor={'#fff '} message={'Verifique os dados e tente novamente.'} />
        </>
    );
};

export default ModalContact;