import { api } from "."


const list = async (journeyStep: string | undefined) => {
    try {
        let keyword = journeyStep?`&keyword=${journeyStep}`:''
        return await api
            .get(`/TechProduct?page=1&keySearch=journeyStep${keyword}`).then((response) => {
                return response.data.data.result
            })
    } catch (error) {
        return error
    }
}

const techProductsService = {
    list
}

export default techProductsService