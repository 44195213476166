import getImageToBase64 from "../../utils/ImageToBase64";


const PATH_URL_IMAGE_SEMAPHORE = "/assets/images/reports/energy-efficiency";

const PATH_URL_IMAGE_LOGO_SENAI = "/assets/images/reports/logo_senai.png";
const PATH_URL_IMAGE_LOGO_JTD_RED = "/assets/images/reports/logo_jtd_red.png";


export async function getLogoSenai() {
	return {
		image: await getImageToBase64(PATH_URL_IMAGE_LOGO_SENAI)
	}
}

export async function getLogoJtdRed() {
	return {
		image: await getImageToBase64(PATH_URL_IMAGE_LOGO_JTD_RED)
	}
}

export async function getImageSemaphore(color: string) {
	let imageCase = "";
	switch (color) {
		case "green":
			imageCase = "green"
			break
		case "red":
			imageCase = "red"
			break
		case "yellow":
			imageCase = "yellow"
			break
		default:
			imageCase = "off"
	}

	return {
		image: await getImageToBase64(`${PATH_URL_IMAGE_SEMAPHORE}/semaphore-${imageCase}.png`)
	}
}

