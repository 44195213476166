import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import AlertMessage from "../../../utils/Alert";

import diagnosticService from "../../../../services/diagnostic";
import { useAppSelector } from "../../../../store";
import { storeDiagnostic } from "../../../../store/slices/diagnostic";
import { storeCompany } from "../../../../store/slices/company";

const SatisfactionReport = () => {
  const [companyAgree, setCompanyAgree] = useState(true)
  const [description, setDescription] = useState("")
  const [buttonVisibility, setButtonVisibility] = useState(false)
  const [avaliation, setAvaliation] = useState({
    timeResolution: 1,
    punctuality: 1,
    technicalSolutions: 1,
    specialistCompetency: 1,
    profissionalism: 1,
    price: 1,
    general: 1
  });
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const { user } = useAppSelector(state => state.user);
  const { diagnostic } = useAppSelector(state => state.diagnostic);
  const { company } = useAppSelector(state => state.company);

  const reopenProposal = async () => {
    const updateDiagnostic = {
      "status": 4
    };

    setButtonVisibility(false);

    try {
      const { statusCode } = await diagnosticService.updateDiagnostic(updateDiagnostic, diagnostic.id);

      if (statusCode == 200) {
        company.diagnostics[0].status = 4;

        const res = await diagnosticService.getDiagnosticById(diagnostic.id);

        dispatch(storeDiagnostic(res));

        dispatch(storeCompany(company));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAlertMessage("Proposta reaberta com sucesso!");

      setAlertShow(true);

      setAlertConfirm(true);

      setTimeout(() => {
        setAlertConfirm(false);

        setAlertShow(false);
      }, 3175);
    };
  };

  const getEvaluation = async () => {
    try {
      const { companyAgrees, description, evaluation } = await diagnosticService.getSatisfactionRatingById(params.satisfactionReportId || "");

      setCompanyAgree(companyAgrees);
      setDescription(description);
      setAvaliation(evaluation);
    } catch (error) {
      console.log(error);
    }
  };

  const renderStars = (fullStars: number, key: keyof typeof avaliation) => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
      stars[i] = <span className="avaliation-page-star" key={i}>{(i < fullStars) ? <>&#9733;</> : <>&#9734;</>}</span>;
    }

    return stars;
  }

  useEffect(() => {
    getEvaluation();

    setButtonVisibility(diagnostic?.status === "3");
  }, [diagnostic, params]);

  return (
    <>
      <div className="avaliation-page">
        <div className="avaliation-page-main-container">
          <div className="avaliation-page-container">
            <div className="avaliation-page-proposal-name" >
              <p>
                Proposta {diagnostic?.proposal_number}
              </p>
            </div>

            <div className="avaliation-page-header">
              <p >Como você avalia o nosso atendimento em termos de:</p>
            </div>

            <div className="avaliation-page-body">
              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Tempo de resolução </span>
                </div>

                <div className="avaliation-page-stars-container">
                  {renderStars(avaliation.timeResolution, "timeResolution")}
                </div>
              </div>

              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Pontualidade </span>
                </div>

                <div className="avaliation-page-stars-container">
                  {renderStars(avaliation.punctuality, "punctuality")}
                </div>
              </div>

              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Soluções  técnicas propostas </span>
                </div>

                <div className="avaliation-page-stars-container">
                  {renderStars(avaliation.technicalSolutions, "technicalSolutions")}
                </div>
              </div>

              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Conhecimento e competência do especialista </span>
                </div>

                <div className="avaliation-page-stars-container">
                  {renderStars(avaliation.specialistCompetency, "specialistCompetency")}
                </div>
              </div>

              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Cortesia e profissionalismo </span>
                </div>

                <div className="avaliation-page-stars-container">
                  {renderStars(avaliation.profissionalism, "profissionalism")}
                </div>
              </div>

              <div className="avaliation-container">
                <div className="avaliation-page-text-container">
                  <span> Preço </span>
                </div>

                <div className="avaliation-page-stars-container last">
                  {renderStars(avaliation.price, "price")}
                </div>
              </div>
            </div>

            <div className="all-avaliation-page-container">
              <div className="all-avaliation-page-text-container">
                <p>Como você avalia o nosso atendimento em geral?</p>
              </div>

              <div className="avaliation-page-stars-container">
                {renderStars(avaliation.general, "general")}
              </div>
            </div>
          </div>

          <div className="avaliation-page-reopen">
            <button onClick={() => window.confirm('Deseja reabrir a avaliação desta empresa?') ? reopenProposal() : null} className={buttonVisibility === true && user.userDetails?.role == 1 ? "avaliation-page-reopen-button" : "none"}>
              <p>Reabrir Proposta </p>
            </button>
          </div>
        </div>

        <div className="avaliation-page-description-container">
          <div className="avaliation-page-description">
            <p>{companyAgree ? "Sugestões" : "Desacordo"}</p>

            <textarea value={description} disabled />
          </div>
        </div>
      </div>

      <AlertMessage confirm={alertConfirm} visible={alertShow} bgColor={"#F4F6F6"} message={alertMessage} />
    </>
  );
};

export default SatisfactionReport;