import React from "react";

interface IRadioInputComponent {
    questionNumber: number,
    enunciated: string,
    handleAnswer: (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string) => void,
    sectionId: string
    answerSelect: string
    answers: {
        name: string
    }[],
    questionId: string,
    isWanted: boolean,
    disabled: boolean
}


const RadioInputComponent = ({ questionNumber, enunciated, answers, questionId, isWanted, handleAnswer, answerSelect, sectionId, disabled }: IRadioInputComponent) => {
    answers?.sort(orderBySequenceIndex);
    function orderBySequenceIndex(a: any, b: any) {
        return a?.sequenceIndex - b?.sequenceIndex;
    }
    return (
        <div className="registry-report-body-question-checkbox">
            <p>{questionNumber}. {enunciated} <span style={{ color: "#9a1915" }}>{isWanted ? "*" : ""}</span></p>
            {
                answers.map((answer: any) => {
                    return (
                        <div className="registry-report-body-checkbox-container">
                            <input disabled={disabled} checked={answerSelect == answer.name} onChange={(event) => handleAnswer(event, questionNumber - 1, sectionId)} name={`radio${questionId}`} value={answer.name} type="radio" required={isWanted ? true : false} />
                            <label>{answer.description}</label>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default RadioInputComponent
