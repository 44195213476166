import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../store";
import { storeDiagnostic } from "../../../store/slices/diagnostic";

interface ICollapsibleItemComponent {
    name: string
    diagnostic: any,
    navigateTo: string
    navigateId?: string
    reviewed: boolean
}

const CollapsibleItemComponent: React.FC<ICollapsibleItemComponent> = ({ name, diagnostic, navigateId, navigateTo, reviewed }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useAppSelector(state => state.user);
    const { company } = useAppSelector(state => state.company);

    const setNavigate = () => {
        dispatch(storeDiagnostic(diagnostic));

        navigateId ?
            navigate(user.companyDetails ?
                `/enterprise/proposals${navigateTo}${reviewed ? "/reviewed" : ""}`
                :
                `/admin/enterprise/${company.companyContext.CNPJ}/${navigateTo}/${navigateId}`)
            :
            navigate(user.companyDetails ?
                `/enterprise/proposals${navigateTo}${reviewed ? "/reviewed" : ""}`
                :
                `/admin/enterprise/${company.companyContext.CNPJ}/${navigateTo}${reviewed ? "/reviewed" : ""}`)
                ;
    }

    return (
        <>
            <div className="collapsible-item-container" onClick={() => setNavigate()}>
                <span>{name}</span>
            </div>
        </>
    );
}

export default CollapsibleItemComponent;