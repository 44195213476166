import React, { useRef } from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Title,
} from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

interface IChartProps {
    title?: string;
    evaluation?: string;
    data?: any;
    setImagesBase64: any;
    imagesBase64: any;
}

const ChartComponent: React.FC<IChartProps> = ({ title, evaluation, data, setImagesBase64, imagesBase64 }: IChartProps) => {
    let dataValues: any = [];

    Object.keys(data).map((item: any) => dataValues.push(data[item]));

    ChartJS.register(
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend,
        LinearScale,
        BarElement,
        CategoryScale,
        Title
    );

    const chartData = {
        labels: Object.keys(data),
        datasets: [
            {
                label: '',
                data: dataValues,
                backgroundColor: '#9A1915',
                borderColor: '#9A1915',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: {
                    stepSize: 0.5,
                    min: 0,
                    max: 3,
                    callback: function (value: any, index: any, ticks: any) {
                        return ''
                    }
                },
            }
        },
        title: {
            display: false
        },
        legend: {
            display: false
        }
    };

    const ref = useRef<ChartJS>(null)

    setImagesBase64(ref.current?.toBase64Image())

    return (
        <>
            <div>
                <div className="chart">
                    {Object.keys(data).length > 2 ? (
                        <Chart type="radar" data={chartData} options={options} ref={ref} />
                    ) : (
                        <Chart type="bar" data={chartData} options={options} ref={ref} />
                    )}
                </div>
                {!!evaluation ? (
                    <div className="chart-evaluation">
                        <span>Avaliação:</span>
                        <p>{evaluation}/3</p>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default ChartComponent;
