import React, { useEffect, useState } from 'react';
import { Squares } from "react-activity";

import HeaderHelmetComponent from '../Layout/HeaderHelmet';
import AvaliationModalComponent from './Modals/AvaliationModal';
import ConfirmationModalComponent from './Modals/ConfirmationModal';
import DiagnosticModalComponent from './Modals/DiagnosticModal';

import { applyCnpjMask } from '../../utils/masks';
import { useAppSelector } from '../../store';

import { getAllTechDiagnostics, getDiagnosticsByProposalId } from '../../utils/utils';
import CivilConstructionChart from '../utils/Chart/CivilConstructionChart';
import { CivilConstructionGeneratePDF } from './CivilConstructionGeneratePDF';

import imgLogo from './assets/logo_red_new.png';
import downloadIcon from './assets/download_icon.svg';
import "react-activity/dist/library.css";

interface IProposalComponentAlert {
  proposalId: string
}

const CivilConstructionComponentPDF = (props: IProposalComponentAlert) => {
  const [hubs, setHubs] = useState<any[]>([]);
  const [quizData, setQuizData] = useState<[]>([]);
  const [description, setDescription] = useState("");
  const [currentModal, setCurrentModal] = useState<any>();
  const [buttonDownloadVisible, setButtonDownloadVisible] = useState(false);
  const [imagesBase64, setImagesBase64] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatsToBase64, setChartsToBase64] = useState<HTMLCollectionOf<HTMLCanvasElement> | null>(null);

  const { company } = useAppSelector(state => state.company);

  const allDiagnostics = getAllTechDiagnostics(company.companyContext);
  const diagnostic = getDiagnosticsByProposalId(allDiagnostics, props?.proposalId);

  const indexToChar = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

  const toMonthName = (monthNumber: any) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('pt-BR', {
      month: 'long',
    });
  }

  const selectedDateTime = diagnostic?.updatedAt || "-"

  var splitarray = new Array();
  splitarray = selectedDateTime.split("-");

  const stateLocation = company.companyContext?.uf

  const setProposalDateAndUf = (stateLocation: string, splitarray: string[]) => {
    return stateLocation + ", " + splitarray[2]?.substring(0, 2) + " de " + toMonthName(splitarray[1]) + " de " + splitarray[0]
  }

  const companyAgrees = false;

  const modals = {
    none: <></>,
    confirmation: <ConfirmationModalComponent negativeAction={() => setCurrentModal(modals.none)} positiveAction={() => setCurrentModal(modals.avaliation)} />,
    diagnostic: <DiagnosticModalComponent closeModal={setCurrentModal} companyAgrees={companyAgrees} setAlert={""} onSend={(description: string) => {
      setDescription(description)
    }} />,
    avaliation: <AvaliationModalComponent isReviewed={false} closeModal={setCurrentModal} companyAgrees={companyAgrees} setAlert={""} description={description} setDowloadVisibleButton={setButtonDownloadVisible} />
  }

  const getStep = (hub: any) => {

    if (hub.length > 0) {

      const hub1 = hub[0].result * 100 / 3;
      const hub2 = hub[1].result * 100 / 3;
      const hub3 = hub[2].result * 100 / 3;

      if (hub1 < 60) {
        return { text: "PROCESSO PRODUTIVO EM FASE DE ESTRATÉGIA EMPRESARIAL", imageUrl: "/assets/images/reports/civil-construction/jorney_step_2.png" }
      } else if (hub1 >= 60 && hub2 < 60) {
        return { text: "PROCESSO PRODUTIVO EM FASE DE ESTRATÉGIA EMPRESARIAL", imageUrl: "/assets/images/reports/civil-construction/jorney_step_2.png" }
      } else if (hub1 >= 60 && hub2 >= 60 && hub3 < 80) {
        return { text: "PROCESSO PRODUTIVO EM FASE DE OTIMIZAÇÃO DE PROCESSOS", imageUrl: "/assets/images/reports/civil-construction/jorney_step_3.png" }
      } else {
        return { text: "PROCESSO PRODUTIVO EM FASE DE MAPEAMENTO", imageUrl: "/assets/images/reports/civil-construction/jorney_step_4.png" }
      }
    }
  }
  const printRef = React.useRef(null);

  const populateHub = async () => {
    setHubs(diagnostic.grade.constructionResult.hubs)
  }

  const convertValues = (obj: any[]) => {
    let value = {}
    try {
      obj.forEach((values) => {
        value = { ...value, [values.name]: values.answers[0].result }
      })
      return value
    } catch (error) {
      return value
    }

  }

  const convertValuesMain = (obj: any[]) => {
    let value = {}
    try {
      obj.forEach((values) => {
        value = { ...value, [values.name]: values.result * 100 / 3 }
      })
      return value
    } catch (error) {
      return value
    }

  }

  useEffect(() => {
    setCurrentModal(modals.avaliation)
  }, [description])

  useEffect(() => {
    setCurrentModal(modals.diagnostic)
  }, [companyAgrees])

  useEffect(() => {

  }, [buttonDownloadVisible])

  useEffect(() => {
    setCurrentModal(modals.none)
  }, [])

  useEffect(() => {
    populateHub();

    setQuizData(diagnostic?.construction_quiz);
  }, [diagnostic]);

  useEffect(() => {
    setChartsToBase64(document.getElementsByTagName('canvas'));
  }, []);

  return (
    <>
      <HeaderHelmetComponent nestedPage={`Proposta ${diagnostic?.proposal_number}`} />

      <div className="proposals-page-container">
        <div className='proposals-page-header-container'>
          <h2 className='title-page'>
            AVALIAÇÃO DIAGNÓSTICA - CONSTRUÇÃO CIVIL
          </h2>
        </div>
        <div className='proposals-card-container'>
          <div className="options-container">
            {!false ?
              <button onClick={() => CivilConstructionGeneratePDF({ diagnostic, hubs, chatsToBase64, stateLocation, companyContext: company.companyContext })} className='option-button' type="button">
                <img src={downloadIcon} alt="Download" />
              </button> : null
            }
          </div>
          <div className="main">
            {!isLoading ?
              <div className="relatory-container">
                <div className="relatory" ref={printRef}>
                  <div className="logo-container">
                    <img src={imgLogo} />
                  </div>

                  <span className="division-line"></span>

                  <span className="date-text">{setProposalDateAndUf(stateLocation, splitarray)}</span>

                  <img className="senai-logo" src="/assets/images/logo-old.png" />

                  <span className="title">AVALIAÇÃO DIAGNÓSTICA - CONSTRUÇÃO CIVIL</span>

                  <span className="enterprise-info">{company.companyContext?.name}</span>

                  <span className="enterprise-info">CNPJ: {applyCnpjMask(company.companyContext?.CNPJ)}</span>

                  <span className="stage-title">Etapa da Jornada SENAI de Transformação Digital que a Empresa se encontra nesse momento:</span>

                  <span className="process-title">{getStep(hubs)?.text}</span>

                  <img className="elevator-image" src={getStep(hubs)?.imageUrl} />

                  <div className='action-plans'>
                    <span className="stage-title">Plano de Ações Recomendadas</span>
                    {diagnostic?.plans?.map((plan: any) => {
                      return <>
                        <span className="stage-title" style={{ fontWeight: "normal" }}><code>•</code> {plan.name}</span>
                      </>
                    })}
                  </div>


                  <h1 className="stage-title">DESEMPENHO GERAL DA EMPRESA</h1>

                  <div className="axle-container">
                    <div className="radar-chart-container">

                      <div className="container-chart-title" style={{ marginLeft: '-3rem' }}>
                      </div>

                      <div className='chart-container' id="graphic" style={{ padding: "16px" }}>

                        <CivilConstructionChart
                          setImagesBase64={setImagesBase64} imagesBase64={imagesBase64}
                          evaluation={diagnostic.grade.constructionResult.total}
                          data={convertValuesMain(diagnostic.grade.constructionResult.hubs)}
                        />
                        <div className="subtitle-container">
                          <h4 style={{ margin: "12px 0px" }}>Legenda:</h4>
                          {hubs.map((hub: any, i: number) => (
                            <div className="subtitle">
                              <span>{i + 1} - {hub.name} : <span style={{ color: 'green' }}> {(hub.result * 100 / 3).toFixed(1)}% </span></span>
                            </div>
                          )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="stage-title">DESEMPENHO ATUAL DA EMPRESA POR EIXO AVALIADO</span>
                  <div className="axle-container">
                    <div className="macro-container">
                      <div className="radar-chart-container">
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "2rem" }}>
                          {
                            hubs && hubs.map((hub: any, hubIndex: number) => {
                              return (
                                <div className='chart-container' id="graphic" style={{ padding: "16px" }}>
                                  <div className="container-chart-title" style={{ fontWeight: "bolder", marginBottom: "16px" }}>{hubIndex + 1} - {hub.name}</div>
                                  <CivilConstructionChart
                                    indexHub={hubIndex}
                                    setImagesBase64={setImagesBase64} imagesBase64={imagesBase64}
                                    evaluation={hub.result.toFixed(2)}
                                    data={convertValues(hub.questions)}
                                  />
                                  <div className="subtitle-container">
                                    <h4 style={{ margin: "12px 0px" }}>Legenda:</h4>
                                    {hub.questions.map((question: any, questionIndex: number) => (
                                      <div className="subtitle">
                                        <span >{hubIndex + 1}.{questionIndex + 1}. {question.name} : <span style={{ color: 'green' }}> {question.answers[0].result.toFixed(2)}</span></span>
                                      </div>
                                    )
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className='loading-container'>
                <span className='loading-text' >Carregando o documento</span>
                <Squares size={60} color='#9a1915' />
              </div>
            }
          </div>
        </div>
        {currentModal}
      </div>
    </>
  );
};

export default CivilConstructionComponentPDF;