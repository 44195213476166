import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

import ManagementSideBar from "./AccessTypes/Management";
import EnterpriseSideBar from "./AccessTypes/Enterprise";
import PopUpComponent from "./PageIndicatorPopUp";

import { useAppSelector } from "../../../store";
import { signOut } from "../../../store/slices/user";

type SideBarComponentProps = {
    children: ReactNode;
}

const SideBarComponent = ({ children }: SideBarComponentProps) => {
    const [currentModal, setCurrentModal] = useState(<> </>)
    const [accessType, setAccessType] = useState("");
    const [visible, setVisible] = useState(true);
    const [onlyViewAlertVisibility, setOnlyViewAlertVisibility] = useState(true)

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAppSelector(state => state.user);

    const logout = () => {
        localStorage.removeItem("token");

        navigate("/");

        dispatch(signOut());
    };

    const getAccessType = () => {
        if (user.companyDetails?.id && user.companyDetails?.id.length >= 1) {
            setAccessType("company");
        } else if (user.userDetails && user.userDetails?.role >= 0) {
            setAccessType("management");
        } else if (location.pathname == "/enterprise-token" || location.pathname == "/password-recovery") {
            setVisible(false);
        } else {
            logout();
        }
    }

    useEffect(() => {
        user.userDetails?.id && getAccessType();
    }, [user]);

    return (
        <>
            {visible ?
                <div className="sidebar-container">
                    <div className="sidebar">
                        <div className="sidebar-structure">
                            <div className="sidebar-logo-container">
                                <img src="/assets/images/logo.png" alt="logo-senai" className="senai-logo" />
                                <div style={{ color: "#FFF", textAlign: 'center', marginBottom: "14px" }}>
                                    Sistema de Gestão da Jornada Digital
                                </div>
                                <div style={{ color: "#FFF", fontSize: "10px", textAlign: "initial"}}>
                                    <span>{user.userDetails?.name?.toUpperCase() || user.companyDetails?.name.toUpperCase()}</span>
                                    <p style={{fontSize: "10px"}}>Uni.: {user.userDetails?.unities.map((unity: any) => unity.name).join(", ")}</p>
                                </div>
                            </div>
                            <div className="sidebar-main">
                                <ul>
                                    {accessType === "management" ?
                                        <ManagementSideBar currentModal={currentModal} setCurrentModal={setCurrentModal} />
                                        :
                                        <EnterpriseSideBar currentModal={currentModal} setCurrentModal={setCurrentModal} />
                                    }
                                </ul>
                            </div>
                            <div className="sidebar-footer" onClick={() => logout()}>
                                <img src="/assets/icons/log-out.png" alt="logout-icon" />
                                <PopUpComponent>Sair</PopUpComponent>
                            </div>

                        </div>
                    </div>

                    <div className="navbar-container">
                        <main className="main-container">
                            {children}

                            {currentModal}

                            <div className="mobile_alert">
                                <span className="icon">!</span>
                                <span className="message">A nossa aplicação ainda não possui suporte a dispositivos moveis.</span>
                            </div>

                    
                                TODO: descomentar o aviso
                                <div className={onlyViewAlertVisibility ? "only_view_alert" : "only_view_alert_none"}>
                                    <span className="message">
                                        {/* Está versão do sistema está disponivel apenas para vizualização. acesse a 
                                        <a href="https://jornadadigital127-prod-front.azurewebsites.net/">nova versão</a> para realizar novos diagnósticos */}
                                        A plataforma está disponivel apenas para visualização por conta da atualização para a nova versão.</span>
                                    <span onClick={() => setOnlyViewAlertVisibility(false)}  className="icon">X</span>
                                </div>
            
                        </main>

                    </div>
                </div>
                :
                <main className="main-container">
                    {children}
                    <div className="mobile_alert">
                        <span className="icon">!</span>
                        <span className="message">A nossa aplicação ainda não possui suporte a dispositivos moveis.</span>
                    </div>
                </main>
            }
        </>
    );
};

export default SideBarComponent;