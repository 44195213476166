
import { api } from ".";
import { SortData } from "../components/Companies";

interface ICompanyFilters {
  cities: string[],
  unities: string[],
  consultants: string[]
  tech_products: string[]
  stepsJorney: string[]
  companies?: string
  hasDiagnostic: boolean
  has_registry_report: boolean
}

interface ICompaniesCount {
  unities: string[]
  techProducts: string[]
  cities: string[]
}

const getCompaniesCount = async (
  obj: ICompaniesCount
) => {
  var url = `/Companies/count?unities=${obj?.unities.join()}&tech_products=${obj?.techProducts.join()}&cities=${obj?.cities.join()}`;
  return await api.get(url);
};

const getCompanyById = async (companyId: string) => {
  try {
    return await api
      .get(`/Companies/${companyId}/diagnostics`)
      .then(async (res) => res.data);
  } catch (err) {
    return err;
  }
};


const sendSatisfactionRating = async (
  satisfactionRatingDetails: any,
  diagnosticId: string,
  diagnostic: any
) => {
  try {
    return await api
      .post(`/SatisfactionRating`, satisfactionRatingDetails)
      .then(async (res) => {

        await api
          .put(`/Diagnostics/${diagnosticId}`, diagnostic)
          .then(() => {
            return res;
          })
          .catch(() => {
            return res;
          });
        return res;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

const getCompaniesByFilters = async (pagination: any, obj: ICompanyFilters) => {
  console.log(obj);
  
  const page = `&page=${pagination.page}&limit=${pagination.limit}`;
  const url = `/Companies/new
    ?cities=${obj.cities.join()}
    &unities=${obj.unities.join()}
    &techProducts=${obj.tech_products.join()}
    &stepsJorney=${obj.stepsJorney.join()}
    &companies=${obj.companies}
    &consultants=${obj.consultants.join()}
    ${obj.hasDiagnostic ? `&diagnostic=${obj.hasDiagnostic}` : ""}
    ${obj.has_registry_report ? `&registry_report=${obj.has_registry_report}` : ""}
    ${page}`;
  return await api.get(url);
}

const getFilters = async (obj: any) => {
  return await api.get(`/Companies/filters?unities=${obj?.unities.join()}`);
};

const getOrderedCompanies = async (sort: SortData, pagination: any, obj: ICompanyFilters) => {
  try {
    const page = `&page=${pagination.page}&limit=${pagination.limit}`;
    let order = '';
    order = 'order=' + sort.key?.toLowerCase() + '&sort=' + sort.type + "&";
    if (sort.type === 'NONE') order = '';
    const url = `/Companies/new?${order}cities=${obj.cities.join()}&unities=${obj.unities.join()}&stepsJorney=${obj.stepsJorney.join()}&techProducts=${obj.tech_products.join()}&companies=${obj.companies}&consultants=${obj.consultants.join()}${obj.hasDiagnostic ? `&diagnostic=${obj.hasDiagnostic}` : ""}${obj.has_registry_report ? `&registry_report=${obj.has_registry_report}` : ""}${page}`;
    return await api.get(url)
  } catch (error) {
    console.error('error picking sorted companies', error)
    throw error
  }
}

const doCompanies = {
  getCompaniesCount,
  getCompaniesByFilters,
  getOrderedCompanies,
  getFilters,
  sendSatisfactionRating,
  getCompanyById
}

export default doCompanies;
