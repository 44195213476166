async function getImageToBase64(imageUrl: string) {
	const response = await fetch(imageUrl);
	const blob = await response.blob();

	const reader = new FileReader();

	return new Promise((resolve, reject) => {
		reader.onload = function (e) {
			const base64Image = e?.target?.result;

			resolve(base64Image);
		};

		reader.readAsDataURL(blob);
	});
}

export default getImageToBase64;
