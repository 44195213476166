import { api } from ".";


const moveQuestion = async (questionId: string, targetId : string) => {
    try {
        return await api.patch(
            `/Questions/move`, {
                question : questionId,
                target : targetId
            }
        ).then((res: any) => {
            console.log(res.data)
            return res.data
        }).catch((err: any) => console.error(err))
    } catch (err) {
        console.error(err)
    }
}

const QuestionService = {
    moveQuestion
}

export default QuestionService