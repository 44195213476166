import React, { useState } from "react";
import MaskedInput from 'react-input-mask';
interface ITextInputComponent {
    questionNumber: number,
    enunciated: string,
    name: string,
    isWanted: boolean,
    textValue: any,
    handleAnswer: (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string,regex?:(value:string)=>string) => void,
    sectionId: string
    disabled: boolean
}

const getMask = (name:string): {hasMask:boolean,mask:string,holder:string,regex:(value:string)=>string,bb?:boolean,cc?:boolean,onBlur:(value:string)=>string}=>{    
    switch(true){
        case name.toLocaleLowerCase().includes('r$'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                cc:true,
                regex:(value:string):string=>{
                    return value.replace(/[^\d]/g,'')
                },
                onBlur:(value:string):string=>{
                    if(value.length>2){
                        const splitValue = value.replace(/(.*)(\S{2})/g,'$1.$2').split('.')
                        let values
                        let finalValues = ''
                        let index2 = 0
                        for (let index = splitValue[0].length -1  ; index >= 0 ; index--) {
                            values =splitValue[0]
                            let index3 = index+1
                            if(index!==0 && index3 % 3 === 0 && values[index3]) finalValues = finalValues + '.' + values[index2]
                            else finalValues = finalValues + values[index2]
                            index2++
                        } 
                        const finalValue = `${finalValues},${splitValue[1] || ''}`
                        return `R$ ${finalValue}`
                    }else if(value){
                        return `R$ ${value}`
                    }
                    return ''
                }
            }
        case name.toLocaleLowerCase().includes('cnpj'):
            return  {
                hasMask:true,
                mask:'99.999.999/9999-99',
                holder:'',
                regex:(value:string):string=>{
                    return value
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('%'):            
            return  {
                hasMask:false,
                mask: '',
                holder:'', 
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                bb:true,
                onBlur:(value:string):string=>{
                    if(value){
                        return `${value} %`
                    }   
                    return ''
                }
            }
        case name.toLocaleLowerCase().includes('ganho de oee'):            
            return  {
                hasMask:false,
                mask: '',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                bb:true,
                onBlur:(value:string):string=>{
                    if(value){
                        return `${value} %`
                    }   
                    return ''
                }
            }
        case name.toLocaleLowerCase().includes('(horas)'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                bb:true,
                onBlur:(value:string):string=>{
                    if(value){
                        return `${value} hora(s)`
                    }   
                    return '' 
                }
            }
        case name.toLocaleLowerCase().includes('(cfp)'):
            return  {
                hasMask:true,
                mask:'999',
                holder:'',
                regex:(value:string):string=>{
                    return value
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('nif'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d]/g,'')
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('(meses)'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d]/g,'')
                },   
                bb:true,
                onBlur:(value:string):string=>{
                    if(value){
                        return `${value} mês(es)`
                    }   
                    return ''
                }
            }
        case name.toLocaleLowerCase().includes('quantidade de a3 executados'):
                return  {
                    hasMask:false,
                    mask:'',
                    holder:'',
                    regex:(value:string):string=>{
                        return value.replace(/[^\d]/g,'')
                    },   
                    bb:false,
                    onBlur:(value:string):string=>{
                        return value
                   
                    }
                }
        case name.toLocaleLowerCase().includes('(em anos)'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                bb:true,
                onBlur:(value:string):string=>{
                    if(value){
                        return `${value} ano(s)`
                    }   
                    return ''
                }
            }
        case name.toLocaleLowerCase().includes('(payback)'):
                return  {
                    hasMask:false,
                    mask:'',
                    holder:'',
                    regex:(value:string):string=>{
                        return value.replace(/[^\d.,]/g,'')
                    },
                    bb:true,
                    onBlur:(value:string):string=>{
                        if(value){
                            return `${value} ano(s)`
                        }   
                        return ''
                    }
                }
        case name.toLocaleLowerCase().includes('(kwh/prod)'):
                return  {
                    hasMask:false,
                    mask:'',
                    holder:'',
                    regex:(value:string):string=>{
                        return value.replace(/[^\d.,]/g,'')
                    },
                    onBlur:(value:string):string=>{
                        return value
                    }
                }
        case name.toLocaleLowerCase().includes('número de empregados'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('(em produtos/homem/hora)'):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('sgset'):
            return {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d\\/]/g,'')
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        case name.toLocaleLowerCase().includes('na execução do plano de'):
                return  {
                    hasMask:false,
                    mask:'',
                    holder:'',
                    regex:(value:string):string=>{
                        return value
                    },
                    onBlur:(value:string):string=>{
                        return value
                    }
                }
        case name.toLocaleLowerCase().includes('ano '):
            return  {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value.replace(/[^\d.,]/g,'')
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
        default:
            return {
                hasMask:false,
                mask:'',
                holder:'',
                regex:(value:string):string=>{
                    return value 
                },
                onBlur:(value:string):string=>{
                    return value
                }
            }
    } 
}
const TextInputComponent = ({ questionNumber, enunciated, isWanted, handleAnswer, textValue, sectionId, disabled,name }: ITextInputComponent) => {
    const [value,setValue] = useState(textValue) 
    return (
        <div className="registry-report-body-question-dissertative">

            <p>{questionNumber}. {enunciated} <span style={{ color: "#9a1915" }}>{isWanted ? "*" : ""}</span></p>
                {getMask(name).hasMask?
                  <MaskedInput
                    mask={getMask(name).mask}
                    maskPlaceholder={getMask(name).holder} 
                    onChange={(event) =>{
                         handleAnswer(event, questionNumber - 1, sectionId)
                         setValue(getMask(name).regex(event.target.value) || '')
                        }}
                    onKeyDown={()=>{
                        localStorage.setItem("stateInput",'null'); 
                    }}
                    value={value}
                    onBlur={()=> setValue(getMask(name).onBlur(textValue))}
                    disabled={disabled}
                    required={isWanted ? true : false } 
                >
                  <input type="text" />
                </MaskedInput>:
                <input
                    onChange={(event) =>{  
                        handleAnswer(event, questionNumber - 1, sectionId, getMask(name).regex)
                        if(!getMask(name).bb) setValue(getMask(name).onBlur(getMask(name).regex(event.target.value)) || '')
                        else{setValue(getMask(name).regex(event.target.value))}
                    }}
                    value={value}
                    onBlur={()=> setValue(getMask(name).onBlur(getMask(name).regex(textValue)) || '')}
                    onFocus={(event)=> {
                        setValue(textValue)
                    }}
                    onKeyDown={(event)=>{
                        if(event.key === 'Backspace'){
                            localStorage.setItem("stateInput",`${event.currentTarget.selectionStart?event.currentTarget.selectionStart - 1:0}`); 
                        }
                        else if(event.key === 'ArrowLeft'||event.key === 'ArrowUp'||event.key === 'ArrowDown'||event.key === 'ArrowRight'){}
                        else{ 
                            if(getMask(name)?.cc){
                                let valueToPlus
                                let defaultValue
                                defaultValue = value.replace(/[^\d/]/g,'').length + 1  
                                
                                if(value.replace(/[^\d/]/g,'').length === 0 ) valueToPlus = 4
                                else if(defaultValue % 3 ===0 ) valueToPlus = 3
                                else valueToPlus = 1 

                                localStorage.setItem("stateInput",`${typeof event.currentTarget.selectionStart==='number'?event.currentTarget.selectionStart+valueToPlus:0}`);
                            }else{
                                localStorage.setItem("stateInput",`${typeof event.currentTarget.selectionStart==='number'?event.currentTarget.selectionStart+1:0}`);
                            }
                        }
                    }}
                    disabled={disabled}
                    required={isWanted ? true : false }
                    type="text"
                   />
                }
        </div>
    )
}

export default TextInputComponent