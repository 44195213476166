import getImageToBase64 from "../../utils/ImageToBase64";


const TITLE_STEP_JORNEY_2 = "PROCESSO PRODUTIVO EM FASE DE ESTRATÉGIA EMPRESARIAL";
const TITLE_STEP_JORNEY_3 = "PROCESSO PRODUTIVO EM FASE DE OTIMIZAÇÃO";
const TITLE_STEP_JORNEY_4 = "PROCESSO PRODUTIVO EM FASE INICIAL DE OTIMIZAÇÃO";
const TITLE_STEP_JORNEY_5_AND_6 = "PROCESSO PRODUTIVO EM FASE INICIAL DE DIGITALIZAÇÃO";
const TITLE_STEP_JORNEY_6 = "PROCESSO PRODUTIVO EM FASE DE TRANSIÇÃO PARA A INDÚSTRIA 4.0";
const TITLE_STEP_JORNEY_6_AND_7 = "PROCESSO PRODUTIVO EM FASE DE TRANSIÇÃO PARA A INDÚSTRIA 4.0";

const PATH_URL_IMAGE_STEP_JORNEY_2 = "/assets/images/reports/jorney_step_2.png";
const PATH_URL_IMAGE_STEP_JORNEY_3 = "/assets/images/reports/jorney_step_3.png";
const PATH_URL_IMAGE_STEP_JORNEY_4 = "/assets/images/reports/jorney_step_4.png";
const PATH_URL_IMAGE_STEP_JORNEY_5_AND_6 = "/assets/images/reports/jorney_step_5_and_6.png";
const PATH_URL_IMAGE_STEP_JORNEY_6 = "/assets/images/reports/jorney_step_6.png";
const PATH_URL_IMAGE_STEP_JORNEY_6_AND_7 = "/assets/images/reports/jorney_step_6_and_7.png";

const PATH_URL_IMAGE_STEP_JORNEY_2_CIVIL_CONSTRUCTION = "/assets/images/reports/civil-construction/jorney_step_2.png";
const PATH_URL_IMAGE_STEP_JORNEY_3_CIVIL_CONSTRUCTION = "/assets/images/reports/civil-construction/jorney_step_3.png";
const PATH_URL_IMAGE_STEP_JORNEY_4_CIVIL_CONSTRUCTION = "/assets/images/reports/civil-construction/jorney_step_4.png";

const PATH_URL_IMAGE_LOGO_SENAI = "/assets/images/reports/logo_senai.png";
const PATH_URL_IMAGE_LOGO_JTD_RED = "/assets/images/reports/logo_jtd_red.png";


export async function getLogoSenai() {
	return {
		image: await getImageToBase64(PATH_URL_IMAGE_LOGO_SENAI)
	}
}

export async function getLogoJtdRed() {
	return {
		image: await getImageToBase64(PATH_URL_IMAGE_LOGO_JTD_RED)
	}
}

export async function getStepJorney2() {
	return {
		text: TITLE_STEP_JORNEY_2,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_2)
	}
}

export async function getStepJorney2CivilConstruction() {
	return {
		text: TITLE_STEP_JORNEY_2,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_2_CIVIL_CONSTRUCTION)
	}
}

export async function getStepJorney3() {
	return {
		text: TITLE_STEP_JORNEY_3,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_3)
	}
}

export async function getStepJorney3CivilConstruction() {
	return {
		text: TITLE_STEP_JORNEY_3,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_3_CIVIL_CONSTRUCTION)
	}
}

export async function getStepJorney4() {
	return {
		text: TITLE_STEP_JORNEY_4,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_4)
	}
}

export async function getStepJorney4CivilConstruction() {
	return {
		text: TITLE_STEP_JORNEY_4,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_4_CIVIL_CONSTRUCTION)
	}
}

export async function getStepJorney5And6() {
	return {
		text: TITLE_STEP_JORNEY_5_AND_6,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_5_AND_6)
	}
}

export async function getStepJorney6() {
	return {
		text: TITLE_STEP_JORNEY_6,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_6)
	}
}

export async function getStepJorney6And7() {
	return {
		text: TITLE_STEP_JORNEY_6_AND_7,
		image: await getImageToBase64(PATH_URL_IMAGE_STEP_JORNEY_6_AND_7)
	}
}
