import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { IUserDetails } from '../../interfaces/user';

const initialState = {
    user: {} as IUserDetails,
    isLoggedIn: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signInFromToken: (state, action: PayloadAction<string>) => {
            const decodedToken = jwtDecode(action.payload) as IUserDetails;

            if (decodedToken) {
                state.user = {
                    ...state.user,
                    ...decodedToken
                };

                state.isLoggedIn = true;
            }
        },
        signOut: (state) => {
            state = initialState;
        },
    },
});

export const { signInFromToken, signOut } = userSlice.actions;

export default userSlice.reducer;