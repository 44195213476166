import { SetStateAction, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import CollapsibleMenuComponent from '../CollapsibleMenu';
import CollapsibleMenuItemComponent from '../CollapsibleMenuItem';
import NameIndicatorComponent from '../PageIndicatorPopUp';
import CollapsibleItemComponent from '../CollapsibleItem';

import { useAppSelector } from '../../../../store';

interface IEnterpriseSideBarProps {
    currentModal: any;
    setCurrentModal: SetStateAction<any>;
};

const EnterpriseSideBar: React.FC<IEnterpriseSideBarProps> = () => {
    const [hasHover, setHasHover] = useState({
        enterprise: false,
        journey: false,
        proposals: false,
        management: false,
        project: false,
    });

    const location = useLocation();

    const { company } = useAppSelector(state => state.company);

    const enterpriseDiagnostics = company.diagnostics;

    return (
        <>
            {location.pathname === '/enterprise/profile' ? (
                <li className="menu-active">
                    <img src={`/assets/icons/company-red.png`} alt="dash-icon" />
                    <NameIndicatorComponent>Minha Empresa</NameIndicatorComponent>
                </li>
            ) : (
                <Link
                    to={'/enterprise/profile'}
                    onClick={() => {
                        setHasHover({ ...hasHover, enterprise: false });
                    }}
                >
                    <li
                        onMouseOver={() => setHasHover({ ...hasHover, enterprise: false })}
                        onMouseLeave={() => setHasHover({ ...hasHover, enterprise: false })}
                    >
                        <img
                            src={`/assets/icons/company-${hasHover.enterprise ? 'red' : 'white'}.png`}
                            alt="dash-icon"
                        />
                        <NameIndicatorComponent>Minha Empresa</NameIndicatorComponent>
                    </li>
                </Link>
            )}

            {location.pathname === '/enterprise/journey' ? (
                <li className="menu-active">
                    <img src={`/assets/icons/journey-red.png`} alt="journey-icon" />
                    <NameIndicatorComponent>Jornada</NameIndicatorComponent>
                </li>
            ) : (
                <Link
                    to={'/enterprise/journey'}
                    onClick={() => {
                        setHasHover({ ...hasHover, journey: false });
                    }}
                >
                    <li
                        onMouseOver={() => setHasHover({ ...hasHover, journey: false })}
                        onMouseLeave={() => setHasHover({ ...hasHover, journey: false })}
                    >
                        <img
                            src={`/assets/icons/journey-${hasHover.journey ? 'red' : 'white'}.png`}
                            alt="journey-icon"
                        />
                        <NameIndicatorComponent>Jornada</NameIndicatorComponent>
                    </li>
                </Link>
            )}

            {location.pathname === '/enterprise/proposals' || location.pathname === '/enterprise/proposals/energyEfficiency' || location.pathname === '/enterprise/proposals/energyEfficiency/reviewed' || location.pathname === '/enterprise/proposals/reviewed' ? (
                <>
                    <div className="menu">

                        <li className="menu-active">
                            <img src={`/assets/icons/contract-red.png`} alt="company-icon" />
                            <NameIndicatorComponent>Propostas</NameIndicatorComponent>
                        </li>
                        {
                            <CollapsibleMenuComponent>
                                <CollapsibleMenuItemComponent visible={true} name={'Avaliação Diagnóstica'} action={() => { }}>
                                    {enterpriseDiagnostics?.map((diagnostic: any) => {
                                        return diagnostic && (
                                            ['2', '3', '4', '5'].includes(diagnostic.status) ? (
                                                <>
                                                    <CollapsibleItemComponent
                                                        name={diagnostic.proposal_number}
                                                        reviewed={false}
                                                        diagnostic={diagnostic}
                                                        navigateTo={''}
                                                    />
                                                </>
                                            ) : ['6', '7'].includes(diagnostic.status) && (
                                                <>
                                                    <CollapsibleItemComponent
                                                        name={diagnostic.proposal_number}
                                                        reviewed={false}
                                                        diagnostic={diagnostic}
                                                        navigateTo={''}
                                                    />
                                                    <CollapsibleItemComponent
                                                        name={`${diagnostic.proposal_number} - Revisada`}
                                                        reviewed={true}
                                                        diagnostic={diagnostic}
                                                        navigateTo={''}
                                                    />
                                                </>
                                            )
                                        );
                                    })}
                                </CollapsibleMenuItemComponent>
                                <CollapsibleMenuItemComponent visible={true} name={'Eficiência Energética'} action={() => { }}>
                                    {enterpriseDiagnostics?.map((diagnostic: any) => {
                                        return diagnostic && (
                                            ['2', '3', '4', '5'].includes(diagnostic.status) ? (
                                                <>
                                                    <CollapsibleItemComponent
                                                        name={diagnostic.proposal_number}
                                                        reviewed={false}
                                                        diagnostic={diagnostic}
                                                        navigateTo={'/energyEfficiency'}
                                                    />
                                                </>
                                            ) : ['6', '7'].includes(diagnostic.status) && (
                                                <>
                                                    <CollapsibleItemComponent
                                                        name={diagnostic.proposal_number}
                                                        reviewed={false}
                                                        diagnostic={diagnostic}
                                                        navigateTo={'/energyEfficiency'}
                                                    />
                                                    <CollapsibleItemComponent
                                                        name={`${diagnostic.proposal_number} - Revisada`}
                                                        reviewed={true}
                                                        diagnostic={diagnostic}
                                                        navigateTo={'/energyEfficiency'}
                                                    />
                                                </>
                                            )
                                        );
                                    })}
                                </CollapsibleMenuItemComponent>
                            </CollapsibleMenuComponent>
                        }
                    </div>

                </>
            ) : (
                <Link
                    to={'/enterprise/proposals'}
                    onClick={() => {
                        setHasHover({ ...hasHover, proposals: false });
                    }}
                >
                    <li
                        onMouseOver={() => setHasHover({ ...hasHover, proposals: false })}
                        onMouseLeave={() => setHasHover({ ...hasHover, proposals: false })}
                    >
                        <img
                            src={`/assets/icons/contract-${hasHover.proposals ? 'red' : 'white'}.png`}
                            alt="company-icon"
                        />
                        <NameIndicatorComponent>Propostas</NameIndicatorComponent>
                    </li>
                </Link>
            )}
        </>
    );
};

export default EnterpriseSideBar;