import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import evaluationService from "../../../../../../services/evaluation";
import CreateEvaluationModal from "../../../../modals/CreateEvaluationModal";
import CreateQuestionModal from "../../../../modals/CreateQuestion/CreateQuestionModal";
import EvaluationTableQuestionLine from "./EvaluationTableQuestionLine/EvaluationTableQuestionLine";

interface IEvaluationTableCriteriaLine {
    reload: () => void
    criteriaId: string
    title: string
    questions: any
    alert: {
        setConfirm: Dispatch<SetStateAction<boolean>>
        setMessage: Dispatch<SetStateAction<string>>
        setShow: Dispatch<SetStateAction<boolean>>

    }
    sequence?: number
    currentMenu: string
    setCurrentMenu: Dispatch<SetStateAction<string>>
    journeyStep ?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' 

}

const EvaluationTableCriteriaLine = ({ journeyStep, reload, criteriaId, title, questions, alert, sequence, currentMenu, setCurrentMenu }: IEvaluationTableCriteriaLine) => {
    const [drop, setDrop] = useState(false)
    const [dependencies, setDependencies] = useState<string[]>([])
    const [visibilityOptions, setVisibilityOptions] = useState(false)

    useEffect(() => {
        if (drop == true)
            setVisibilityOptions(false)
    }, [drop])

    const deleteCriterion = () => {

        evaluationService.deleteCriterion(criteriaId).then((res: any) => {
            try {
                if (res.data.statusCode === 200) {
                    alert.setMessage("Critério deletado com sucesso!")
                    alert.setShow(true)
                    alert.setConfirm(true)
                    setTimeout(() => {
                        alert.setShow(false)
                        alert.setConfirm(false)
                    }, 3175)
                    setVisibilityOptions(false)
                    reload()
                } else {
                    alert.setMessage("Ocorreu um erro ao deletar o critério!")
                    alert.setShow(true)
                    setTimeout(() => {
                        alert.setShow(false)
                    }, 3175)
                }
            } catch (error) {
                alert.setMessage("Ocorreu um erro ao deletar o critério!")
                alert.setShow(true)
                alert.setConfirm(true)
                setTimeout(() => {
                    alert.setShow(false)
                    alert.setConfirm(false)
                }, 3175)
            }

        })

    }

    const reloadQuestion = () => {
        reload()
        setVisibilityOptions(false)
    }


    const [currentModal, setCurrentModal] = useState<any>();
    // id={criteriaId}
    const modals = {
        none: <></>,
        register: <CreateQuestionModal journeyStep={journeyStep} alert={alert} relation="criteria" questions={questions} id={criteriaId} reload={reloadQuestion} action="REGISTER" close={() => setCurrentModal(modals.none)} />,
        edit: <CreateEvaluationModal alert={alert} reload={reload} action="EDIT" type={"CRITERIA"} name={title} id={criteriaId} close={() => setCurrentModal(modals.none)} />
    }

    return (
        <>
            <tr className={drop == true ? "evaluation-table-line dropped" : "evaluation-table-line"} >
                <td>Critério</td>
                <td>
                    <div className="evaluation-table-name-container criteria">
                        <div>
                            {questions.length > 0 ?
                                <div onClick={() => setDrop(!drop)} className={drop === true ? "evaluation-table-name-image-container dropped" : "evaluation-table-name-image-container"}>
                                    <img src="/assets/icons/right-arrow-gray.png" alt="" />
                                </div>
                                : null}
                            <div className="evaluation-table-name">{title}</div>
                        </div>

                        <div className="evaluation-table-options-container">
                            <img onClick={() => {
                                setVisibilityOptions(!visibilityOptions)
                                setCurrentMenu(criteriaId)
                            }} className="evaluation-table-options-more" src="/assets/icons/more-options-gray.png" alt="" />
                        </div>
                    </div>


                </td>
                {visibilityOptions == true && currentMenu == criteriaId ?
                    <div onClick={() => setVisibilityOptions(!visibilityOptions)} className="evaluation-table-menu-container">
                        <div onClick={() => setCurrentModal(modals.register)} className="evaluation-table-menu-item"> Adicionar questão </div>
                        <div className="evaluation-table-menu-item" onClick={() => setCurrentModal(modals.edit)}> Editar </div>
                        <div onClick={() => deleteCriterion()} className="evaluation-table-menu-item"> Excluir </div>
                    </div>
                    : null}
            </tr>


            {drop === true ?
                questions?.sort((a: any, b: any) => (a.sequenceIndex > b.sequenceIndex) ? 1 : -1).map((question: any, index : number) => {
                    return (
                        <EvaluationTableQuestionLine  journeyStep={journeyStep} upNeighbor={questions[index + 1] || null} downNeighbor={questions[index - 1] || null} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} alert={alert} questions={questions} relation={"criteria"} id={criteriaId} question={question} reload={reload} questionId={question.id} sequence={question.sequenceIndex} key={question.id} answers={question.answers} title={question.name} dependencies={dependencies} setDependencies={setDependencies} />
                    )
                })

                : null}
            {currentModal}
        </>


    )
}

export default EvaluationTableCriteriaLine