import React, { ChangeEvent, useState, useEffect } from "react";
import { useAppSelector } from "../../store";
import ReportRegistryEvaluationPage from "../Companies/ReportsRegistry/Evaluation";
import ReportRegistrySubMenu from "./ReportRegistrySubMenu";

export interface IRegistryResult {

    id: string
    name: string
    type: string
    number: number
    answers:
    {
        result: string
    }[]

}

export interface IRegistryReport {
    id: String,
    registry_result: [
        IRegistryResult[]
    ],
    name: String,
    consultant_name: String,
    diagnostics_name: String,
    company_name: String,
    createdAt: String,
    updatedAt: String,
    consultant: {
        id: String,
        name: String,
        nif: String,
        ddd: String,
        phone: String,
        ramal: String | undefined,
        job: String | undefined,
        firstLogin: Boolean,
        token: String,
        role: Number,
        isActive: Number,
        createdAt: String,
        updatedAt: String,
        unities:
        {
            id: String,
            name: String,
            description: String,
            createdAt: String,
            updatedAt: String
        }[],
        modules: [],
        specialties:
        {
            id: String,
            name: String,
            area: String,
            createdAt: String,
            updatedAt: String
        }[]
    }
}
const CompletionRecord = ({ }) => {

    const { company } = useAppSelector(state => state.company);
    const [registryReport, setRegistryReport] = useState<IRegistryReport>()
    const [proposal, setProposal] = useState<any>()
    const [evaluationId, setEvaluationId] = useState<string | undefined>(undefined)

    const handleSelectProposal = (evaluationId: string, dignosticId: number) => {
        setEvaluationId(evaluationId)
        setProposal(diag.find((diag: any) => diag.id === dignosticId));
    }
    const diag = company.companyContext.__diagnostics__.filter((diag: any) => {
        let [, date] = diag?.proposal_number.split('/')
        if (parseInt(date) > 2020) return true
    })

    useEffect(() => {
        const res = company?.companyContext?.evaluations?.filter((evaluation: any) => (
            evaluation?.name !== 'Avaliação Diagnóstica' && evaluation?.name !== 'Pré-Diagnóstico de Eficiência Energética'
        ))
        setRegistryReport(res)
    }, []);

    return (
        <>
            <div className="completion-record-container">
                <div className="title-page-container">
                    <h2 className="title-page">
                        Registros de Conclusão
                    </h2>
                </div>
                <div className="proposals-container">
                    <div className="proposals-select-container">
                        <ReportRegistrySubMenu diagnostic={diag} evaluation={registryReport} onChange={handleSelectProposal} />
                    </div>
                    <div className="evaluation-container">
                        <ReportRegistryEvaluationPage evaluationId={evaluationId} proposal={proposal} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompletionRecord