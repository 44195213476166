import React, { useContext, useEffect, useState } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AlertMessage from "../utils/Alert";
import "react-activity/dist/library.css";
import { Squares } from "react-activity";

import Semaphore from "../Companies/Semaphore/Semaphore";

import { applyCnpjMask } from "../../utils/masks";
import HeaderHelmetComponent from "../Layout/HeaderHelmet";

import { EnergyEfficiencyGeneratePDF } from "./EnergyEfficiencyGeneratePDF";
import { useAppSelector } from "../../store";
import downloadIcon from '../Proposals/assets/download_icon.svg'
import { getAllTechDiagnostics, getDiagnosticsByProposalId } from "../../utils/utils";

interface IProposalComponentAlert {
  arrayAlert: any;
  isReviewed: boolean;
  proposalId: string
}

const EnergyEfficiencyComponent = (props: IProposalComponentAlert) => {
  const [hubs, setHubs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>()
  const [quizData, setQuizData] = useState<[]>([]);
  const [alertShow, setAlertShow] = useState(false);
  const [buttonDownloadVisible, setButtonDownloadVisible] = useState(true);
  const { company } = useAppSelector(state => state.company);

  const [currentModal, setCurrentModal] = useState<any>();

  const allDiagnostics = getAllTechDiagnostics(company.companyContext);
  const diagnostic = getDiagnosticsByProposalId(allDiagnostics, props?.proposalId);

  const [existSatisficationRating, setExistSatisficationRating] = useState(props.isReviewed == true ? diagnostic?.reviewed_satisfaction_rating ? true : false : diagnostic?.satisfaction_rating ? true : false)

  function toMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("pt-BR", {
      month: "long",
    });
  }

  const selectedDateTime = diagnostic?.updatedAt || "";

  var splitarray = new Array();
  splitarray = selectedDateTime?.split("-") || "";

  const stateLocation = company.companyContext?.uf;

  const setProposalDateAndUf = (
    stateLocation: string,
    splitarray: string[]
  ) => {
    return (
      stateLocation +
      ", " +
      splitarray[2]?.substring(0, 2) +
      " de " +
      toMonthName(splitarray[1]) +
      " de " +
      splitarray[0]
    );
  };


  const printRef = React.useRef(null);
  const handleDownloadPdf = async () => {
    setAlertShow(true);
    setTimeout(() => {
      setAlertShow(false);
    }, 6000);

    const element = printRef.current;
    //@ts-ignore
    const canvas = await html2canvas(element);
    var imgData = canvas.toDataURL("image/png");
    var imgWidth = 210;
    var pageHeight = 293;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("p", "mm");
    var position = 0;

    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save(
      `${diagnostic?.proposal_number + " " + company.companyContext?.name}.pdf`
    );
  };

  useEffect(() => {
    setHubs(company.companyContext?.evaluations?.find((evaluation: any) => evaluation?.name === "Pré-Diagnóstico de Eficiência Energética")?.hubs)
    let { efficiency_quiz } = diagnostic;
    let { reviewed_efficiency_quiz } = diagnostic;
    // let { totalAxisMedia } = diagnostic?.grade[0].diagnosticResult;
    setQuizData(props.isReviewed ? reviewed_efficiency_quiz : efficiency_quiz);

  }, [company, props.isReviewed, diagnostic]);

  const efficiency = props.isReviewed
    ? diagnostic?.reviewed_grade?.find((items: any) => typeof items?.efficiency_grade?.MOTORES == typeof 0)
    : diagnostic?.grade?.find((items: any) => typeof items?.efficiency_grade?.MOTORES == typeof 0)

  return (
    <>
      <HeaderHelmetComponent nestedPage={`Proposta ${diagnostic?.proposal_number}`} />
      <div className="proposals-page-container">
        <div className='proposals-page-header-container'>
          <h2 className='title-page'>
            PRÉ-DIAGNÓSTICO DE EFICIÊNCIA
          </h2>
        </div>
        <div className='proposals-card-container'>
          <div className="options-container">
            {
              !isLoading ?

                existSatisficationRating || buttonDownloadVisible ?
                  <button onClick={() => EnergyEfficiencyGeneratePDF({ isReviewed: props.isReviewed, hubs, companyContext: company.companyContext, diagnosticContext: diagnostic, quizData, efficiency: efficiency?.efficiency_grade })} className='option-button' type="button">
                    <img src={downloadIcon} alt="Download" />
                  </button> : null : null
            }
          </div>
          <div className="main">
            {!isLoading ? <div className="relatory-container">
              <div className="relatory">
                <div className="relatory" ref={printRef}>
                  <div className="logo-container">
                    <img src="/assets/images/logo_red_new.png" />
                  </div>
                  <div className="division-line"></div>
                  <span className="date-text">
                    {setProposalDateAndUf(stateLocation, splitarray)}
                  </span>
                  <img className="senai-logo" src="/assets/images/logo-old.png" />
                  <span className="title">
                    PRÉ-DIAGNÓSTICO DE EFICIÊNCIA
                  </span>
                  <span className="enterprise-info">
                    {company.companyContext?.name}
                  </span>
                  <span className="enterprise-info">CNPJ: {applyCnpjMask(company.companyContext?.CNPJ)}</span>
                  <span className="stage-title">
                    Nível de maturidade de eficiência energética que a empresa se
                    encontra neste momento:
                  </span>
                  <Semaphore efficiency={efficiency?.efficiency_grade} />
                  {hubs?.map((hub: any, hubIndex: number) => {
                    return (
                      <div className="axle-container">
                        <div className="macro-container">
                          {hub.criterion.map(
                            (criteria: any, criteriaIndex: number) => {
                              return quizData &&
                                quizData.find(
                                  (quiz: any) => quiz.criteriaId == criteria.id
                                ) ? (
                                <>
                                  <div className="title-and-image-observation">
                                    {false && (
                                      <img
                                        src="/assets/icons/image-icon.png"
                                        alt=" ícone de imagem"
                                      />
                                    )}
                                    <span className="title-section">
                                      {criteria.name}
                                    </span>
                                  </div>

                                  <div className="question-container-efficiency">
                                    <div className="question-content-container">
                                      {criteria.questions.map((question: any, questionIndex: number) => {
                                        return quizData && quizData.find((quiz: any) => quiz.data.questionId == question.id) ? (
                                          <>
                                            <div className="question-container-efficiency">
                                              <span className="question-enuncied-efficiency">{`${questionIndex + 1}. `}{" "}
                                                {question?.description}</span>
                                              <span className="question-resp-efficiency">{question.answers.map((answer: any, answerIndex: number) => {
                                                return quizData && quizData.find((quiz: any) => quiz.data.answerId == answer.id) ? (
                                                  answer.description
                                                ) : null
                                              })}</span>
                                            </div>
                                          </>
                                        ) : null
                                      })}
                                    </div>
                                  </div>
                                </>
                              ) : null;
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> :
              <div className='loading-container'>
                <span className='loading-text'>Carregando o documento</span>
                <Squares size={60} color='#9a1915' />
              </div>
            }
          </div>
        </div>
        <AlertMessage
          confirm
          visible={alertShow}
          bgColor={"#fff "}
          message={"fazendo seu download, por favor aguarde"}
        />
        {currentModal}
      </div>
    </>
  );
};

export default EnergyEfficiencyComponent;
