import React, { useEffect, useState } from "react";

import TransformationStageCard from "./TransformationStageCards";
import { Squares } from "react-activity"

interface IIncomingJourneyStep {
    journeyStep: string,
    results: string,
    total: string,
    name?: string
}

const JOURNEY_STEP_NAME:any = {
    1: 'diagnostic',
    2: 'strategy',
    3: 'processOptimization',
    4: 'mapping',
    5: 'automation',
    6: 'digitalization',
    7: 'integration',
    8: 'smartIndustry',
}

const totalsInitialState: any = {
    diagnostic: {
        journeyStep: '1',
        name: 'Diagnóstico',
        total: '0',
        result: '0'
    },
    strategy: {
        journeyStep: '2',
        name: 'Estratégia',
        total: '0',
        result: '0'
    },
    processOptimization: {
        journeyStep: '3',
        name: 'Otimização de Processos',
        total: '0',
        result: '0'
    },
    mapping: {
        journeyStep: '4',
        name: 'Mapeamento',
        total: '0',
        result: '0'
    },
    automation: {
        journeyStep: '5',
        name: 'Automação',
        total: '0',
        result: '0'
    },
    digitalization: {
        journeyStep: '6',
        name: 'Digitalização',
        total: '0',
        result: '0'
    },
    integration: {
        journeyStep: '7',
        name: 'Integração',
        total: '0',
        result: '0'
    },
    smartIndustry: {
        journeyStep: '8',
        name: 'Indústria Inteligente',
        total: '0',
        result: '0'
    },
}

const DigitalTransformationElevatorSection: React.FC<any> = ({ totais, companiesTotais, loading = false }) => {
    const [totals, setTotals] = useState(totalsInitialState);

    const sumTotals = () => {
        return Object.getOwnPropertyNames(totals)
        .reduce((accumulator, propertyName) => accumulator + parseInt(totals[propertyName].total), 0 );
    }

    const newStepTotal = (newStepTotal: IIncomingJourneyStep) => {
        const {journeyStep, name, total, results} = newStepTotal;
    
        return {
            journeyStep,
            name,
            total,
            result: results
        };
    }
    
    const reloadTotals = () => {
        const newTotals:any = {...totalsInitialState};

        for(const journeyStepTotal of totais) {
            newTotals[JOURNEY_STEP_NAME[journeyStepTotal?.journeyStep]] = newStepTotal({
                name: newTotals[JOURNEY_STEP_NAME[journeyStepTotal?.journeyStep]].name,
                ...journeyStepTotal
            })
        }

        setTotals(newTotals);
    }

    useEffect(() => {
        totais.length > 0 && reloadTotals();
    }, [totais]);

    return (
        <>
            <div className="transformation-card-container">
                <div className="transformation-card-header">
                    <div className="transformation-card-header-left">
                        <img src="/assets/images/logo-black.png" alt="logo-senai" />
                    </div>
                </div>

                <div className="transformation-card-sides">
                    <div className="transformation-card-side-left">
                        <div className="transformation-card-side-left-second-floor">
                            <p>2° Andar</p>
                        </div>
                        <div className="transformation-card-side-left-first-floor">
                            <p>1° Andar</p>
                        </div>
                        <div className="transformation-card-side-left-solo-floor">
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.diagnostic.journeyStep}
                                name={totals.diagnostic.name}
                                toDo={totals.diagnostic.total}
                                finished={totals.diagnostic.result} />
                            <div className="transformation-card-side-left-solo-floor-line"></div>
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.strategy.journeyStep}
                                name={totals.strategy.name}
                                toDo={totals.strategy.total}
                                finished={totals.strategy.result} />
                        </div>
                    </div>

                    <div className="transformation-card-side-center">
                        <div className="transformation-card-side-center-second-floor">
                            <div className="transformation-card-side-center-solo-floor-top"></div>
                            <div className="transformation-card-side-center-solo-floor-center">
                                <div className="transformation-card-side-center-solo-floor-center-left"></div>
                                <div className="transformation-card-side-center-solo-floor-center-center">
                                    <div className="transformation-card-elevator">
                                        <div className="transformation-card-internal-top"></div>
                                        <div className="transformation-card-internal">
                                            <div className="transformation-elevator-door" />
                                        </div>
                                    </div>
                                </div>
                                <div className="transformation-card-side-center-solo-floor-center-right">
                                    <div className="transformation-card-side-center-solo-floor-center-left-line"></div>
                                </div>
                            </div>
                            <div className="transformation-card-side-center-solo-floor-bottom">
                                <div className="transformation-card-side-center-solo-floor-top-line"></div>
                            </div>
                        </div>

                        <div className="transformation-card-side-center-first-floor">

                            <div className="transformation-card-side-center-solo-floor-top">
                                <div className="transformation-card-side-center-solo-floor-top-line"></div>
                            </div>
                            <div className="transformation-card-side-center-solo-floor-center">
                                <div className="transformation-card-side-center-solo-floor-center-left"></div>
                                <div className="transformation-card-side-center-solo-floor-center-center">

                                    <div className="transformation-card-elevator">
                                        <div className="transformation-card-internal-top"></div>
                                        <div className="transformation-card-internal">
                                            <div className="transformation-elevator-door" />
                                        </div>
                                    </div>

                                </div>
                                <div className="transformation-card-side-center-solo-floor-center-right">
                                    <div className="transformation-card-side-center-solo-floor-center-left-line"></div>
                                </div>
                            </div>
                            <div className="transformation-card-side-center-solo-floor-bottom">
                                <div className="transformation-card-side-center-solo-floor-top-line"></div>
                            </div>

                        </div>
                        <div className="transformation-card-side-center-solo-floor">


                            <div className="transformation-card-side-center-solo-floor-top">
                                <div className="transformation-card-side-center-solo-floor-top-line"></div>
                            </div>
                            <div className="transformation-card-side-center-solo-floor-center">
                                <div className="transformation-card-side-center-solo-floor-center-left">
                                    <div className="transformation-card-side-center-solo-floor-center-left-line"></div>
                                </div>
                                <div className="transformation-card-side-center-solo-floor-center-center">

                                    <div className="transformation-card-elevator">
                                        <div className="transformation-card-internal-top"></div>
                                        <div className="transformation-card-internal">
                                            <div className="transformation-elevator-door" />
                                        </div>
                                    </div>

                                </div>
                                <div className="transformation-card-side-center-solo-floor-center-right"></div>
                            </div>
                            <div className="transformation-card-side-center-solo-floor-bottom"></div>

                        </div>
                    </div>

                    <div className="transformation-card-side-right">
                        <div className="transformation-card-side-right-second-floor">
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.mapping.journeyStep}
                                name={totals.mapping.name}
                                toDo={totals.mapping.total}
                                finished={totals.mapping.result} />
                            <div className="transformation-card-side-right-second-floor-line"></div>
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.automation.journeyStep}
                                name={totals.automation.name}
                                toDo={totals.automation.total}
                                finished={totals.automation.result} />
                            <div className="transformation-card-side-right-second-floor-line"></div>
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.digitalization.journeyStep}
                                name={totals.digitalization.name}
                                toDo={totals.digitalization.total}
                                finished={totals.digitalization.result} />
                            <div className="transformation-card-side-right-second-floor-line"></div>
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.integration.journeyStep}
                                name={totals.integration.name}
                                toDo={totals.integration.total}
                                finished={totals.integration.result} />
                            <div className="transformation-card-side-right-second-floor-line"></div>
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.smartIndustry.journeyStep}
                                name={totals.smartIndustry.name}
                                toDo={totals.smartIndustry.total}
                                finished={totals.smartIndustry.result} />
                        </div>

                        <div className="transformation-card-side-right-first-floor">
                            <TransformationStageCard
                                loading={loading}
                                stage={totals.processOptimization.journeyStep}
                                name={totals.processOptimization.name}
                                toDo={totals.processOptimization.total}
                                finished={totals.processOptimization.result} />

                        </div>

                        <div className="transformation-card-side-right-solo-floor" >
                            <p>Térreo</p>
                            <div className="transformation-card-side-right-solo-floor-container-establishments">
                                <div className="transformation-card-side-right-solo-floor-establishments">
                                    <span>{loading ? <Squares /> : companiesTotais?.totalCompanies}</span>
                                    <p>Total de Empresas</p>
                                </div>
                                <div className="transformation-card-side-right-solo-floor-establishments" style={{ marginRight: "8px" }}>
                                    <span>{loading ? <Squares /> : sumTotals()}</span>
                                    <p>Total de Propostas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DigitalTransformationElevatorSection;