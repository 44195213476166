import { createSlice } from '@reduxjs/toolkit'

export interface ICompanyFilter {
    unities: string[]
    cities: string[]
    consultants: string[]
    tech_products: string[]
    companies: string
    hasDiagnostic: boolean,
    has_registry_report: boolean,
    stepsJorney: string[]
}

const initialState = {
    companyFilter: {
        unities: [],
        cities: [],
        consultants: [],
        tech_products: [],
        stepsJorney: [],
        companies: "",
        hasDiagnostic: false,
        has_registry_report: false,
    } as ICompanyFilter
}

export const companyFilterSlice = createSlice({
    name: "companyFilter",
    initialState,
    reducers: {
        storeCompanyFilter: (state, action) => {
            state.companyFilter = {
                ...state.companyFilter,
                ...action.payload
            };
        },
        removeCompanyFilter: (state) => {
            state.companyFilter = initialState.companyFilter;
        },
    }
})

export const { storeCompanyFilter, removeCompanyFilter } = companyFilterSlice.actions

export default companyFilterSlice.reducer