import { SetStateAction, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import CollapsibleMenuComponent from '../CollapsibleMenu';
import CollapsibleMenuItemComponent from '../CollapsibleMenuItem';
import NameIndicatorComponent from '../PageIndicatorPopUp';
import CollapsibleItemComponent from '../CollapsibleItem';

import { useAppSelector } from "../../../../store";
import diagnostic from '../../../../store/slices/diagnostic';

interface IManagementSideBarProps {
    currentModal: SetStateAction<any>;
    setCurrentModal: SetStateAction<any>;
}

const ManagementSideBar: React.FC<IManagementSideBarProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let [enterpriseDiagnostics, setEnterpriseDiagnostics]: any = useState();
    const { user } = useAppSelector(state => state.user);
    const { company } = useAppSelector(state => state.company);

    const getEnterpriseDiagnostics = async () => {
        if (!company) return;
        setEnterpriseDiagnostics(company.diagnostics)
    };

    const [hasHover, setHasHover] = useState({
        dash: false,
        journey: false,
        company: false,
        user: false,
        evaluation: false,
        project: false,
    });

    useEffect(() => {
        setEnterpriseDiagnostics(null)
        getEnterpriseDiagnostics();
    }, [company]);

    return (
        <>
            {
                location.pathname.includes('/admin/dashboard') ||
                    location.pathname === '/admin/dashboard/grade/diagnostics' ||
                    location.pathname === '/admin/dashboard/grade/efficiency'
                    ? (
                        <>
                            <Link
                                to={'/admin/dashboard'}
                                onClick={() => {
                                    setHasHover({ ...hasHover, dash: false });
                                }}
                            >
                                <li
                                    onMouseOver={() => setHasHover({ ...hasHover, dash: false })}
                                    onMouseLeave={() => setHasHover({ ...hasHover, dash: false })}
                                    className="menu-active"
                                >
                                    <img
                                        src={`/assets/icons/dash-${hasHover.dash ? 'red' : 'red'}.png`}
                                        alt="dash-icon"
                                    />
                                    <NameIndicatorComponent>Dashboard</NameIndicatorComponent>

                                </li>
                            </Link>
                            <li className="menu-active" style={{ marginTop: "-8px", padding: "2px" }}>
                                <CollapsibleMenuComponent>
                                    {/* <NameIndicatorComponent>Resultados</NameIndicatorComponent> */}
                                    <CollapsibleMenuItemComponent visible={true} name={"Diagnosticos"} action={() => {
                                        navigate(`/admin/dashboard/grade/diagnostics`)
                                    }} />
                                    <CollapsibleMenuItemComponent visible={true} name={"Eficiência Energética"} action={() => { navigate(`/admin/dashboard/grade/efficiency`) }} />
                                    <CollapsibleMenuItemComponent visible={true} name={"Registros de Conclusão"} action={() => { navigate(`/admin/dashboard/grade/registry-report`) }} />
                                </CollapsibleMenuComponent>
                            </li>
                        </>
                    ) : (
                        <Link
                            to={'/admin/dashboard'}
                            onClick={() => {
                                setHasHover({ ...hasHover, dash: false });
                            }}
                        >
                            <li
                                onMouseOver={() => setHasHover({ ...hasHover, dash: false })}
                                onMouseLeave={() => setHasHover({ ...hasHover, dash: false })}
                            >
                                <img
                                    src={`/assets/icons/dash-${hasHover.dash ? 'red' : 'white'}.png`}
                                    alt="dash-icon"
                                />
                                <NameIndicatorComponent>Dashboard</NameIndicatorComponent>

                            </li>
                        </Link>
                    )
            }

            {
                location.pathname === '/admin/journey' ? (
                    <>
                        <li className="menu-active">
                            <img src={`/assets/icons/journey-red.png`} alt="journey-icon" />
                            <NameIndicatorComponent>Jornada</NameIndicatorComponent>
                        </li>

                    </>
                ) : (
                    <Link
                        to={'/admin/journey'}
                        onClick={() => {
                            setHasHover({ ...hasHover, journey: false });
                        }}
                    >
                        <li
                            onMouseOver={() => setHasHover({ ...hasHover, journey: false })}
                            onMouseLeave={() => setHasHover({ ...hasHover, journey: false })}
                        >
                            <img
                                src={`/assets/icons/journey-${hasHover.journey ? 'red' : 'white'}.png`}
                                alt="journey-icon"
                            />
                            <NameIndicatorComponent>Jornada</NameIndicatorComponent>
                        </li>
                    </Link>

                )
            }

            {
                location.pathname === '/admin/enterprises' ? (
                    <>
                        <li className="menu-active"><img src={`/assets/icons/company-red.png`} alt="company-icon" /><NameIndicatorComponent>Empresas</NameIndicatorComponent></li>
                    </>
                ) : location.pathname === `/admin/enterprise/${company.companyContext?.CNPJ}` ||
                    location.pathname === `/admin/enterprise/${company.companyContext?.CNPJ}/civilContructions` ||
                    location.pathname === `/admin/enterprise/${company.companyContext?.CNPJ}/diagnosticEvaluation` ||
                    location.pathname === `/admin/enterprise/${company.companyContext?.CNPJ}/energyEfficiency/reviewed` ||
                    location.pathname === `/admin/enterprise/${company.companyContext?.CNPJ}/energyEfficiency` ||
                    location.pathname.includes(`/admin/enterprise/${company.companyContext?.CNPJ}/satisfactionReport`) ||
                    location.pathname.includes(`/admin/enterprise/${company.companyContext?.CNPJ}/reports-registry`) ||
                    location.pathname.includes(`/admin/enterprise/${company.companyContext?.CNPJ}/completionRecord`) ||
                    location.pathname.includes(`/admin/enterprise/${company.companyContext?.CNPJ}/reports-registry/evaluation`) ? (
                    <div className='menu'>
                        <Link to="/admin/enterprises"><li className="menu-active"><img src={`/assets/icons/company-red.png`} alt="company-icon" /><NameIndicatorComponent>Empresas</NameIndicatorComponent></li></Link>

                        <CollapsibleMenuComponent>
                            <CollapsibleMenuItemComponent visible={true} name={company.companyContext?.name} action={() => { navigate(`/admin/enterprise/${company.companyContext?.CNPJ}`) }} />
                            {
                                company.companyContext?.diagnostics?.filter((diagnostic: any) =>
                                    ['1', '2', '3', '4', '5', '6', '7'].includes(diagnostic?.status)
                                ).map((diagnostic: any) =>
                                    <>
                                        {diagnostic.diagnostic_quiz &&
                                            <CollapsibleItemComponent name={`Avaliação Diagnóstica - ${diagnostic?.proposal_number}`} reviewed={false} navigateTo={"diagnosticEvaluation"} diagnostic={diagnostic} />
                                        }


                                        {diagnostic.reviewed_diagnostic_quiz &&
                                            <CollapsibleItemComponent name={`Avaliação Diagnóstica - Revisada`} reviewed navigateTo={"diagnosticEvaluation"} diagnostic={diagnostic} />
                                        }

                                        {diagnostic.efficiency_quiz &&
                                            <CollapsibleItemComponent name={`Eficiência Energética - ${diagnostic?.proposal_number}`} reviewed={false} navigateTo={"energyEfficiency"} diagnostic={diagnostic} />
                                        }

                                        {diagnostic.reviewed_efficiency_quiz &&
                                            <CollapsibleItemComponent name={`Eficiência Energética - Revisada`} reviewed navigateTo={"energyEfficiency"} diagnostic={diagnostic} />
                                        }

                                        {diagnostic.construction_quiz &&
                                            <CollapsibleItemComponent name={`Construção Civil - ${diagnostic?.proposal_number}`} reviewed={false} navigateTo={"civilContructions"} diagnostic={diagnostic} />
                                        }

                                    </>
                                )}
                            {
                                (user.userDetails?.role == 1 || user.userDetails?.role == 2 || user.userDetails?.role == 0) &&
                                <CollapsibleItemComponent name={`Registros de Conclusão`} reviewed={false} navigateTo={"completionRecord"} diagnostic={diagnostic} />
                            }
                        </CollapsibleMenuComponent>
                    </div>
                ) : (
                    <Link
                        to={'/admin/enterprises'}
                        onClick={() => {
                            setHasHover({ ...hasHover, company: false });
                        }}
                    >
                        <li
                            onMouseOver={() => setHasHover({ ...hasHover, company: false })}
                            onMouseLeave={() => setHasHover({ ...hasHover, company: false })}
                        >
                            <img
                                src={`/assets/icons/company-${hasHover.company ? 'red' : 'white'}.png`}
                                alt="company-icon"
                            />
                            <NameIndicatorComponent>Empresas</NameIndicatorComponent>
                        </li>
                    </Link>
                )
            }

            {
                location.pathname === '/admin/users' ? (
                    <>
                        <li className="menu-active">
                            <img src={`/assets/icons/user-red.png`} alt="user-icon" />
                            <NameIndicatorComponent>Usuários</NameIndicatorComponent>
                        </li>
                    </>
                ) : user.userDetails?.role != 2 && (
                    <Link
                        to={'/admin/users'}
                        onClick={() => {
                            setHasHover({ ...hasHover, user: false });
                        }}
                    >
                        <li
                            onMouseOver={() => setHasHover({ ...hasHover, user: false })}
                            onMouseLeave={() => setHasHover({ ...hasHover, user: false })}
                        >
                            <img src={`/assets/icons/user-${hasHover.user ? 'red' : 'white'}.png`} alt="user-icon" />
                            <NameIndicatorComponent>Usuários</NameIndicatorComponent>
                        </li>
                    </Link>
                )
            }

            {
                user.userDetails?.role == 0 &&
                <>
                    {
                        location.pathname.includes("/evaluations") ? (
                            <>
                                <li className="menu-active">
                                    <img src={`/assets/icons/evaluation-red.png`} alt="user-icon" />
                                    <NameIndicatorComponent>Avaliações</NameIndicatorComponent>
                                </li>
                            </>
                        ) : (
                            <Link
                                to={'/admin/evaluations'}
                                onClick={() => {
                                    setHasHover({ ...hasHover, evaluation: false });
                                }}
                            >
                                <li
                                    onMouseOver={() => setHasHover({ ...hasHover, evaluation: false })}
                                    onMouseLeave={() => setHasHover({ ...hasHover, evaluation: false })}
                                >
                                    <img
                                        src={`/assets/icons/evaluation-${hasHover.evaluation ? 'red' : 'white'}.png`}
                                        alt="evaluation-icon"
                                    />
                                    <NameIndicatorComponent>Avaliações</NameIndicatorComponent>
                                </li>
                            </Link>
                        )
                    }
                    {
                        location.pathname.includes("/specialties") ? (
                            <>
                                <li className="menu-active">
                                    <img src={`/assets/icons/specialties-red.png`} alt="tech-product-icon" />
                                    <NameIndicatorComponent>Especialidades</NameIndicatorComponent>
                                </li>
                            </>
                        ) : (
                            <Link
                                to={'/admin/specialties'}
                                onClick={() => {
                                    setHasHover({ ...hasHover, evaluation: false });
                                }}
                            >
                                <li
                                    onMouseOver={() => setHasHover({ ...hasHover, evaluation: false })}
                                    onMouseLeave={() => setHasHover({ ...hasHover, evaluation: false })}
                                >
                                    <img src={`/assets/icons/specialties-white.png`} alt="tech-product-icon" />
                                    <NameIndicatorComponent>Especialidades</NameIndicatorComponent>
                                </li>
                            </Link>
                        )
                    }
                </>
            }
        </>
    );
};

export default ManagementSideBar;