import React from "react";
import { useState } from 'react';
interface ICheckboxInputComponent {
    questionNumber: number,
    enunciated: string,
    answers: {
        name: string
        description: string
        createdAt : string
        sequenceIndex : number
    }[],
    answerSelect: [{ result: string }]
    isWanted: boolean
    handleAnswer: (event: React.ChangeEvent<HTMLInputElement>, index: number, sectionId: string) => void
    sectionId: string
    disabled: boolean
}

const CheckboxInputComponent = ({ questionNumber, enunciated, answers, isWanted, handleAnswer, answerSelect, sectionId, disabled }: ICheckboxInputComponent) => {
    const [required,setRequired] = useState(isWanted)
    const onClick = (event:any) => {
       const data:any = handleAnswer(event, questionNumber - 1, sectionId)
       if(isWanted) setRequired(data?.answers[0]?.result === '' ? true : false)
    } 
    const checked = (answer:any):boolean => {
        try {
            if(answerSelect?.find((answerS) => answerS.result == answer.name) ){
                return true
            }
            return false
        } catch (error) {
            if(answerSelect == answer.name ){
                return true
            }
            return false
        }
    }
    return (
        <div className="registry-report-body-question-checkbox">
            <p>{questionNumber}. {enunciated} <span style={{ color: "#9a1915" }}>{isWanted ? "*" : ""}</span></p>

            {
            answers.sort((a, b) => a.sequenceIndex > b.sequenceIndex ? 1 : -1).map((answer: any) => {
                    return (
                        <div className="registry-report-body-checkbox-container" key={answer.id}>
                            <input disabled={disabled} checked={checked(answer)} onChange={onClick} id="teste" value={answer.name} type="checkbox" required={required} />
                            <label> {answer.description}</label>
                        </div>
                    )
                })
            }
        </div>
    )
}


export default CheckboxInputComponent
