import { DOTS, usePagination } from "./usePagination";
import classnames from 'classnames';
import { ReactElement } from "react";

interface PaginationProps {
    total: number
    currentPage: number,
    limit: number,
    onPageChange: Function,
    siblingCount?: number
}

const Pagination = (props: PaginationProps): ReactElement<any, any> | null => {
    const {
        onPageChange,
        total,
        siblingCount = 1,
        currentPage,
        limit
    } = props;

    const paginationRange = usePagination({
        currentPage,
        total,
        siblingCount,
        limit
    });

    if (currentPage === 0 || paginationRange ) {
        if( paginationRange!.length < 2) return null;
        
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };
    let lastPage = null

    if(paginationRange){
        lastPage = paginationRange[paginationRange.length - 1];
    }
    
    return (
        <ul
            className={'pagination-container'}
        >
            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <div className="arrow left" />
            </li>
            {paginationRange?.map(pageNumber => {

                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li
                        className={classnames('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <div className="arrow right" />
            </li>
        </ul>
    );
};

export default Pagination;