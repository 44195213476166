import { BrowserRouter, Route, Routes } from "react-router-dom";

import DashboardPage from "../pages/admin/Dashboard";
import EnterprisesPage from "../pages/admin/Enterprises";
import JourneyPage from "../pages/admin/Journey";
import UsersPage from "../pages/admin/Users";
import ProposalsPage from "../pages/enterprise/Proposals";
import LoginPage from "../pages/Login";
import NotFoundPage from "../pages/NotFound";
import PasswordRecovery from "../pages/passwordRecovery";
import TechProductPage from "../pages/admin/TechProduct";
import SpecialtiesPage from "../pages/admin/Specialties";

import CompanyDetails from "../components/Companies/CompanyDetails";
import LayoutWrapper from "../components/Layout";
import CompanyToken from "../components/CompanyToken";
import SatisfactionReport from "../components/Proposals/reports/SatisfactionReport";
import EvaluationTable from "../components/Evaluations/EvaluationTable";
import ReportRegistryEvaluationPage from "../components/Companies/ReportsRegistry/Evaluation";

import { useAppSelector } from "../store";
import CompletionRecord from "../components/CompletionRecord";
import CivilConstruction from "../pages/enterprise/CivilConstruction";
import DiagnosticsGradePage from "../pages/admin/DiagnosticsGradePage";
import EfficiencyDiagnosticsGradePage from "../pages/admin/EfficiencyDiagnosticsGradePage";
import RegistryReportPage from "../pages/admin/RegistryReportPage";


const Router = () => {
    const { user, isLoggedIn } = useAppSelector((state) => state.user);

    return (
        <BrowserRouter>
            <LayoutWrapper>
                <Routes>
                    <Route path="enterprise-token" element={<CompanyToken />} />
                    <Route index element={<LoginPage />} />
                    <Route
                        path="password-recovery"
                        element={<PasswordRecovery />}
                    />
                    {isLoggedIn && user.userDetails?.role >= 0 && (
                        <Route path="admin">
                            <Route
                                path="dashboard"
                                element={<DashboardPage />}
                            />
                            <Route
                                path="dashboard/grade/diagnostics"
                                element={<DiagnosticsGradePage />}
                            />
                            <Route
                                path="dashboard/grade/efficiency"
                                element={<EfficiencyDiagnosticsGradePage />}
                            />
                            <Route
                                path="dashboard/grade/registry-report"
                                element={<RegistryReportPage />}
                            />
                            <Route path="journey" element={<JourneyPage />} />

                            <Route
                                path="enterprises"
                                element={<EnterprisesPage />}
                            />
                            <Route
                                path="enterprise/:enterpriseId"
                                element={<CompanyDetails />}
                            />
                            <Route
                                path="enterprise/:enterpriseId/completionRecord"
                                element={<CompletionRecord />}
                            />
                            <Route
                                path="enterprise/:enterpriseId/reports-registry/:evaluationId"
                                element={<ReportRegistryEvaluationPage />}
                            />
                            <Route
                                path="enterprise/:enterpriseId/diagnosticEvaluation"
                                element={
                                    <ProposalsPage
                                        isReviewed={false}
                                        arrayAlert={""}
                                    />
                                }
                            />
                            <Route
                                path="enterprise/:enterpriseId/diagnosticEvaluation/reviewed"
                                element={
                                    <ProposalsPage
                                        isReviewed={true}
                                        arrayAlert={""}
                                    />
                                }
                            />
                            <Route
                                path="enterprise/:enterpriseId/energyEfficiency"
                                element={
                                    <ProposalsPage
                                        isReviewed={false}
                                        arrayAlert={""}
                                        isEnergyEfficiency
                                    />
                                }
                            />
                            <Route
                                path="enterprise/:enterpriseId/civilContructions"
                                element={
                                    <CivilConstruction />
                                }
                            />
                            <Route
                                path="enterprise/:enterpriseId/satisfactionReport/:satisfactionReportId"
                                element={<SatisfactionReport />}
                            />
                            <Route path="users" element={<UsersPage />} />
                            <Route
                                path=":journeyStep/evaluations"
                                element={<EvaluationTable />}
                            />
                            <Route
                                path="evaluations"
                                element={<EvaluationTable />}
                            />
                            <Route
                                path="tech-products"
                                element={<TechProductPage />}
                            />
                            <Route
                                path="specialties"
                                element={<SpecialtiesPage />}
                            />
                            <Route
                                path="reports-registry/:evaluationId"
                                element={
                                    <ReportRegistryEvaluationPage
                                        viewOnly={true}
                                    />
                                }
                            />
                        </Route>
                    )}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </LayoutWrapper>
        </BrowserRouter>
    );
};

export default Router;
