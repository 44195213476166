import { createSlice } from '@reduxjs/toolkit';
import { IDiagnostic } from '../../interfaces/diagnostic';

const initialState = {
    diagnostic: {} as IDiagnostic
};

export const diagnosticSlice = createSlice({
    name: 'diagnostic',
    initialState,
    reducers: {
        storeDiagnostic: (state, action) => {
                state.diagnostic = {
                    ...state.diagnostic,
                    ...action.payload
                }
        },
        removeDiagnostic: (state) => {
            state = initialState;
        },
    },
});

export const { storeDiagnostic, removeDiagnostic } = diagnosticSlice.actions;

export default diagnosticSlice.reducer;