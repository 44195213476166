import React, { useEffect, useState } from "react";

interface IAlertProps {
    visible?: boolean,
    bgColor?: string,
    message: string
    confirm?: boolean
}

const AlertMessage: React.FC<IAlertProps> = ({ visible, bgColor, message, confirm }) => {
    const [completed, setCompleted] = useState(100)

    useEffect(() => {
        if (completed > 0 && visible) {
            setTimeout(() => {
                setCompleted(completed - 5)
            }, 125)
            clearTimeout(undefined)
        }
        else if (!visible) {
            setCompleted(100)
        }
    }, [visible, completed])


    return (
        <>
            <div className={`alert-popup${(visible) ? ' visible' : ''}`} style={{ backgroundColor: bgColor }}>
                <div className="progress-container">
                    <div className={`progress-bar${confirm ? " confirm" : ""}`} style={{ width: `${completed}%` }}></div>
                </div>
                {confirm ? null : <img src={`/assets/icons/warning-red.png`} alt="warning-red" className="alert-icon" />}
                <span className={`message${confirm ? ' confirm' : ''}`}>
                    {message || '<error_no_message>'}
                </span>
            </div>
        </>
    )
}

export default AlertMessage