import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PasswordFieldComponent from './passwordField'
import jwt from "jwt-decode"
import authService from "../../services/auth"
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { API_URL } from '../../constants'
import { api } from '../../services'

interface IUserDetails {
    id: string
}

const PasswordRecoveryComponent = () => {

    const [passwordValue, setPasswordValue] = useState<any>()
    const [passwordValueConfirmation, setPasswordValueConfirmation] = useState<any>()
    const [passwordStrenght, setPasswordStrenght] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)

    let tokenUser = ""
    const companyLogin = localStorage.getItem("companyLogin")

    const navigate = useNavigate()

    const passwordValidation = (passwordValue: string, passwordValueConfirmation: string) => {
        if (passwordValue == passwordValueConfirmation && (passwordValue && passwordValueConfirmation != null) && passwordStrenght == "senha forte") {
            return "defineButtonTrue"
        } else {
            return "defineButtonFalse"
        }
    }

    const [searchParams] = useSearchParams()
    let companyIdDecoded: any
    let id = searchParams.get("id") || ""
    const userDetails: IUserDetails = jwt(id)
    let type = searchParams.get("type")

    const registerNewPasswordCompany = async () => {
        if (isLoading) return

        setIsLoading(true)



        const newPassword = {
            'password': passwordValue
        }

        await authService.authUser(companyLogin || "", "").then(res => {

            if (res) {
                tokenUser = res.data
            } else {
                return
            }
        })

        await api.put(`/Companies/${userDetails.id}`, newPassword).then((res: any) => {
            setIsLoading(false)
            localStorage.removeItem('companyId')
            localStorage.removeItem('companyLogin')
            navigate("/")
        }).catch((err:any) => {
            setIsLoading(false)
        })
    }

    const registerNewPasswordUser = async () => {
        if (isLoading) return

        setIsLoading(true)

        const newPassword = {
            'password': passwordValue
        }

        await authService.authUser(companyLogin || "", "").then(res => {

            if (res) {
                tokenUser = res.data
            } else {
                return
            }
        })

        await api.put(`/Users/${userDetails.id}/change-password`, newPassword).then((data:any) => {
            setIsLoading(false)
            if (data) {
                navigate("/")
            }

        }).catch((err:any) => {
            setIsLoading(false)
        })
    }



    useEffect(() => {

    }, [])

    return (
        <>
            <div className='container'>
                <div className='imageContainer'>
                    <img className='logo' src='/assets/images/logo.png' />
                </div>
                <div className='main'>
                    <div className='form'>
                        <span className='formTitle'>Redefenir sua senha</span>
                        <span className='formSubTitle'>Preencha os campos abaixo</span>
                        <PasswordFieldComponent
                            title="Digite sua nova senha"
                            passwordValue={passwordValue}
                            setPasswordValue={setPasswordValue}
                            isNecessaryPasswordLevel={true}
                            passwordStrenght={passwordStrenght}
                            setPasswordStrenght={setPasswordStrenght}
                        />
                        <PasswordFieldComponent
                            title="Confirme sua nova senha"
                            passwordValue={passwordValueConfirmation}
                            setPasswordValue={setPasswordValueConfirmation}
                            isNecessaryPasswordLevel={false}

                        />
                        <button disabled={isLoading} onClick={() => passwordValidation(passwordValue, passwordValueConfirmation) == "defineButtonTrue" ? type === "user" ? registerNewPasswordUser() : registerNewPasswordCompany() : null} className={passwordValidation(passwordValue, passwordValueConfirmation)}>
                            {isLoading ? <Spinner color="#FFF" size={21} speed={1} animating={true} /> : <span>Redefinir</span>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordRecoveryComponent